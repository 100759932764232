import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import "./driver.css";

export const PageDriverTour = (screen_helps: any[]) => {
  const getStepsForScreen = () => {
    const currentScreen = localStorage.getItem("current_screen");

    const screenHelp = screen_helps.find(
      (screen) => screen.screen_name === currentScreen
    );

    return (
      screenHelp?.steps.map((step: any) => ({
        element: step.element_id,
        popover: {
          title: step.title,
          description: step.desc,
          side: step.side || "left",
          align: step.align || "center",
        },
      })) || []
    );
  };

  const driverObj = driver({
    showProgress: true,
    showButtons: ["next", "previous", "close"],
    nextBtnText: "Next",
    prevBtnText: "Back",
    animate: true,
    popoverClass: "driverjs-theme",
    steps: getStepsForScreen() as any,
  });

  return {
    start: () => driverObj.drive(),
  };
};
