import { Switch, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import "./Helper.module.scss";
import { fetchFAQ } from "./services/FAQ.service";

interface FAQ {
  question: string;
  answer: string;
  benefits: string;
}

const { Panel } = Collapse;

const userGuideList = [
  {
    title: "Getting Started",
    desc: "Learn the basic of PromptX",
    link: "https://avalant-documents.gitbook.io/avalant/getting-started/quickstart",
  },
  {
    title: "Advanced Features",
    desc: "Master advanced capabilities",
    link: "",
  },
  { title: "Best Practices", desc: "Tips for optimal usage", link: "" },
];

const HelperScreen: React.FC = () => {
  const [isTourDriverEnabled, setIsTourDriverEnabled] = useState(true);
  const [faqList, setFaqList] = useState<FAQ[]>([]);

  useEffect(() => {
    onFetch();
    const savedPreference = localStorage.getItem("tourDriverEnabled");
    setIsTourDriverEnabled(savedPreference !== "false");
  }, []);

  const onFetch = async () => {
    try {
      const res = await fetchFAQ();
      if (!res) return;
      setFaqList(res as FAQ[]);
    } catch (err) {
      console.error(err);
    }
  };

  const handleToggleTourDriver = (checked: boolean) => {
    setIsTourDriverEnabled(checked);
    localStorage.setItem("tourDriverEnabled", checked.toString());
  };

  return (
    <div className="dashboard-container scrollbar">
      <div className="bg-white border-b border-gray-200">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <h1 className="text-xl font-semibold text-gray-900">Help</h1>
          </div>
        </div>
      </div>

      <div className="dashboard-content space-y-4 scrollbar">
        {/* Settings */}
        <div className="bg-white rounded-md shadow-md p-8">
          <div className="flex flex-col gap-4">
            <span className="text-lg font-bold text-[#1861B4]">Settings</span>
            <div className="flex flex-row gap-2">
              <Switch
                checked={isTourDriverEnabled}
                onChange={handleToggleTourDriver}
              />
              <span>Enable Inbuilt Helper</span>
            </div>
          </div>
        </div>

        {/* User Guides */}
        <div className="bg-white rounded-md shadow-md p-8">
          <div className="flex flex-col gap-4">
            <span className="text-lg font-bold text-[#1861B4]">
              User Guides
            </span>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {userGuideList.map((guide, index) => (
                <a
                  className="flex flex-col gap-2 bg-gray-100 p-4 rounded-md no-underline"
                  key={index}
                  href={guide.link}
                  target="_blank"
                >
                  <span className="text-md font-bold text-blue-500">
                    {guide.title}
                  </span>
                  <span className="text-sm text-gray-500">{guide.desc}</span>
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* FAQs */}
        <div className="bg-white rounded-md shadow-md p-8">
          <div className="flex flex-col gap-4">
            <span className="text-lg font-bold text-[#1861B4]">
              Frequently Asked Questions
            </span>
            {faqList.map((faq, index) => (
              <Collapse accordion className="gap-4">
                <Panel header={faq.question} key={index}>
                  <p className="text-gray-500">{faq.answer}</p>
                  <div className="mt-2">
                    <strong>Benefits:</strong>
                    <ul
                      className="list-disc ml-4 text-gray-500"
                      dangerouslySetInnerHTML={{ __html: faq.benefits }}
                    ></ul>
                  </div>
                </Panel>
              </Collapse>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelperScreen;
