import { Input, Modal, notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useForgotVerifyForm } from "../form/forgotVerifyForm";
import PhoneInput from "react-phone-input-2";
import { changePassword, forgotPassword } from "src/page/kn/util.service";
import { Email } from "@mui/icons-material";
import { ChangePasswordResponse } from "src/page/setting-page/screens/ChangePassword";
import OTPModal from "src/page/setting-page/components/OTPModal";

interface ForgotVerifyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ForgotVerifyModal: React.FC<ForgotVerifyModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { control, handleSubmit, reset } = useForgotVerifyForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerify, setIsVerify] = useState<"email" | "phone number">(
    "phone number"
  );
  const [resChangePassword, setResChangePassword] =
    useState<ChangePasswordResponse>({
      id: "",
      email: "",
      otp: "",
      refID: "",
      isIAM2: false,
    });
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);

  useEffect(() => {
    reset();
  }, [isVerify, isOpen]);

  const onVerify = useCallback(() => {
    setIsVerify((currentVerify) =>
      currentVerify === "phone number" ? "email" : "phone number"
    );
  }, []);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const req = {
      email: data.email,
      phoneNumber: data.phoneNumber,
      phoneCountry: data.phoneCountry,
    };

    try {
      const responseData = await forgotPassword(req);
      onClose();

      if (isVerify === "phone number") {
        Modal.success({
          centered: true,
          title: "Reset Completed",
          content: "Link to reset password already sent to your email.",
          okText: "Completed",
        });
      } else {
        setResChangePassword(responseData as ChangePasswordResponse);
        setIsOTPModalOpen(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    try {
      const response = (await changePassword(true)) as ChangePasswordResponse;
      if (!response?.refID) return;

      setResChangePassword(response);
      setIsOTPModalOpen(true);
    } catch (error) {
      notification.error({
        message: "Failed to initiate password change. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        title="Verify Account"
        open={isOpen}
        onCancel={onClose}
        okText="Next"
        onOk={handleSubmit(onSubmit)}
        okButtonProps={{
          loading: isLoading,
        }}
        centered
      >
        <div className="w-full flex flex-col gap-6 py-4">
          <div className="flex flex-col gap-2">
            {isVerify === "email" ? (
              <>
                <span className="font-medium">
                  Phone number <span className="text-red-500">*</span>
                </span>
                <div className="flex flex-row gap-2">
                  <Controller
                    name="phoneCountry"
                    control={control}
                    render={({ field }) => (
                      <div className="w-12 h-12 flex">
                        <PhoneInput
                          country={"th"}
                          value={field.value}
                          onChange={(phone) => field.onChange(phone)}
                          inputStyle={{ display: "none" }}
                          buttonStyle={{
                            width: "100%",
                            height: "100%",
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                          }}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input
                          size="large"
                          {...field}
                          placeholder=""
                          value={field.value || ""}
                        />
                        {error && (
                          <span className="text-red-500 text-xs">
                            {error.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                <span className="font-medium">
                  Email <span className="text-red-500">*</span>
                </span>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        size="large"
                        {...field}
                        placeholder="Enter your email address"
                        status={error ? "error" : ""}
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value || ""}
                      />
                      {error && (
                        <span className="text-red-500 text-xs">
                          {error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </>
            )}
            <p
              className="text-sm text-blue-400 hover:text-blue-500 cursor-pointer"
              onClick={onVerify}
            >
              Or click here to verify with {isVerify}.
            </p>
          </div>
        </div>
      </Modal>

      {resChangePassword && (
        <OTPModal
          isOpen={isOTPModalOpen}
          isLoading={isLoading}
          onClose={() => setIsOTPModalOpen(false)}
          resChangePassword={resChangePassword}
          handleChangePassword={handleChangePassword}
        />
      )}
    </>
  );
};

export default ForgotVerifyModal;
