import { Button, Menu } from "antd";
import { saveAs } from "file-saver";
import "highlight.js/styles/atom-one-dark.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
// import { FaRegEdit } from "react-icons/fa";
import { FaCheck, FaDownload, FaEdit, FaTimes } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkMath from "remark-math";
import styles from "./ChatBubble.module.scss";
import CopyButton from "./CopyButton";
// @ts-ignore
import html2pdf from "html2pdf.js";
// @ts-ignore
import JSZip from "jszip";
import MessageNofify from "src/components/zero/zero-story/notification/MessageNotify";
import { GenerateContext } from "src/page/context/Generate.context";
import { DEFAULT_IMG_PROFILE, ME } from "../constants/chatbubble.constant";
import { GENERATE_STEP } from "../constants/genstep.constant";
import { getUpdateResultByStepTypeEndpoint } from "../endpoints/generate.endpoint";
import { useGenerate } from "../Generate";
import SyntaxLighter from "../syntaxLighter/SyntaxLighter";
import ResultEditor from "./resultEditor/ResultEditor";
import { getTokenParam, getAuthToken } from "src/services/specifications.service";

type ChatMessageProps = {
  key: any;
  agent?: any;
  message?: any;
  setMessage?: any;
  profileImage?: any;
  chatColor?: any;
  processing?: any;
  step: number;
};

type ProcessMessageProps = {
  type: "TOPIC" | "HTML";
  content: string;
};

const HighlightedCodeBlock = ({ children, className }: any) => {
  const [copied, setCopied] = useState(false);
  const language = className?.replace("lang-", "") || "plaintext";
  // const highlighted = hljs.highlight(children || '', { language }).value;
  const codeId = `code-${Math.random().toString(36).substring(2, 9)}`;

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        whiteSpace: "normal",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: ".75rem",
          fontFamily: "inherit",
          lineHeight: "1rem",
          padding: ".5rem 1rem",
          backgroundColor: "#2f2f2f",
          color: "#ccccdd",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <div>{language}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CopyToClipboard text={children} onCopy={() => setCopied(true)}>
            <span data-state="closed">
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#ccccdd",
                }}
              >
                {copied ? "Copied" : "Copy"}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="icon-sm"
                >
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d={
                      copied
                        ? "M9 3a1 1 0 0 1 1.528-.848l7 5a1 1 0 0 1 0 1.696l-7 5A1 1 0 0 1 9 13V3z"
                        : "M7 5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-2v2a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h2zm2 2h5a3 3 0 0 1 3 3v5h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1zM5 9a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1z"
                    }
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </span>
          </CopyToClipboard>
        </div>
      </div>
      <pre
        style={{
          margin: 0,
          padding: 0,
          lineHeight: "1.5",
          border: "unset",
          wordBreak: "unset",
          wordWrap: "unset",
        }}
      >
        {/* <code
          id={codeId}
          className={`hljs ${language}`}
          style={{
            margin: 0,
            padding: '16px',
            wordBreak: 'normal',
            whiteSpace: 'unset',
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
          }}
          // {highlighted}
          dangerouslySetInnerHTML={createMarkup(highlighted)}
        /> */}

        <SyntaxHighlighter
          language={language}
          style={materialDark}
          customStyle={{
            margin: "0",
            padding: "16px",
            whiteSpace: "pre-wrap",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        >
          {children}
        </SyntaxHighlighter>
      </pre>
    </div>
  );
};

const ChatMessage = ({
  agent = "",
  message = "",
  profileImage = "",
  chatColor,
  processing,
  setMessage,
  step,
}: ChatMessageProps) => {
  const CONTENT_TOPIC = "TOPIC";
  const CONTENT_HTML = "HTML";

  const { loading, error, generateData } = useGenerate();

  //NOTITY STATE
  let [triggerNotify, setTriggerNotify] = useState<boolean>(false);
  let [titleNotify, setTitleNotify] = useState<string>("");
  let [descriptionNotify, setDescriptionNotify] = useState<string>("");
  let [isSuccessNotify, setIsSuccessNotify] = useState<boolean>(false);

  const [copied, setCopied] = useState(false);
  const [copiedCode, setCopiedCode] = useState(false);
  const [processedMessage, setProcessedMessage] = useState(message);
  const [newProcessedMessage, setNewProcessMessage] = useState("");
  const [processedMessageList, setProcessedMessageList] = useState<
    ProcessMessageProps[]
  >([]);
  const targetRef = useRef<any>(null);
  const [showButtons, setShowButtons] = useState(false);
  const {
    // messageData,
    // projectName,
    // setProjectName,
    uuidApp,
    // setUuidApp,
    // isEditedMessage,
    setIsEditedMessage,
  } = useContext(GenerateContext);
  const [isEditResult, setIsEditResult] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopiedCode(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [copiedCode]);

  const handleHTMLContent = (htmlString: any) => {
    // Use a regular expression to find all occurrences of '</html>'
    return htmlString.replace(/<\/html>\s*\n*```/g, "</html>\n```\n");
  };

  useEffect(() => {
    let content = "";
    if (step !== GENERATE_STEP.ORIGINAL_PROMPT) {
      content = handleHTMLContent(message);
    } else {
      content = message;
    }
    // console.log("content code => ", content);
    setProcessedMessage(content);
    if (step === GENERATE_STEP.HTML) {
      let processMessageList = getProcessMessageWithContentType(content);
      // console.log("processMessageList to check => ", processMessageList);
      setProcessedMessageList(processMessageList);
    }
  }, [message]);

  function getProcessMessageWithContentType(
    content: string
  ): ProcessMessageProps[] {
    //แยก content ด้วย ```html และทำการ join ด้วย ``` เตรียมสำหรับการ split ลำดับต่อไป
    let prepareForSplitContent = content.split("```html").join("```");

    //split content ด้วย ```
    let contentList = prepareForSplitContent.split("```");

    //นำ item ทั้งหมดมาเช็ค หากมี <!DOCTYPE html> แสดงว่าเป็นประเภท html
    let result: ProcessMessageProps[] = contentList.map((content: any) => {
      if (content.includes("<!DOCTYPE html>")) {
        return {
          type: CONTENT_HTML,
          content,
        };
      } else {
        return {
          type: CONTENT_TOPIC,
          content,
        };
      }
    });
    return result;
  }

  async function exportToPDF() {
    const element = targetRef.current;
    const options = {
      margin: 1,
      filename: `${agent}_message.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(element).set(options).save();
  }

  const exportToWord = () => {
    const content = targetRef.current.innerHTML;
    // Wrap the content in the Word-compatible HTML structure
    const header = `
    <html xmlns:o='urn:schemas-microsoft-com:office:office' 
          xmlns:w='urn:schemas-microsoft-com:office:word' 
          xmlns='http://www.w3.org/TR/REC-html40'>
    <head><meta charset='utf-8'><title>Export To Word</title></head><body>`;

    const footer = `</body></html>`;

    // Combine the header, content, and footer to create the full document
    const fullContent = header + content + footer;

    // Create a Blob with the correct MIME type for Word
    const blob = new Blob([fullContent], { type: "application/msword" });
    // const blob = new Blob([content], { type: 'application/msword' });
    saveAs(blob, `${agent}_message.doc`);
  };

  async function exportToZip() {
    const zip = new JSZip();
    const element = targetRef.current;
    console.log("agent => ", agent);
    if (agent.toLowerCase().includes("frontend")) {
      let html = processedMessage;
      // console.log("html => ", html)

      // Split the htmlText by '- Screen Name:'
      const sections = html.split("- Screen Name:").filter(Boolean);

      sections.forEach((section: any) => {
        const lines = section.trim().split("\n").filter(Boolean);

        if (lines.length > 0) {
          const screenName = lines[0].trim(); // Extract the screen name
          let htmlCode = section.split("- HTML Code:")[1]; // Extract HTML code part

          // Remove the ```html and ``` markers
          if (htmlCode) {
            htmlCode = htmlCode
              .replace(/```html/g, "")
              .replace(/```/g, "")
              .trim();

            if (screenName && htmlCode.startsWith("<!DOCTYPE html>")) {
              // Generate the file name from the screen name
              const fileName =
                screenName.trim().toLowerCase().replace(/\s+/g, "_") + ".html";

              zip.file(fileName, htmlCode);
            }
          }
        }
      });
      let zipFileName = agent.trim().toLowerCase().replace(/\s+/g, "_");
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `${zipFileName}.zip`);
      });
    } else {
      const pdfOptions = {
        margin: 1,
        filename: `${agent}_message.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      let pdfFileName = agent.trim().toLowerCase().replace(/\s+/g, "_");
      const pdfBlob = await html2pdf()
        .from(element)
        .set(pdfOptions)
        .outputPdf("blob");
      zip.file(`${pdfFileName}_message.pdf`, pdfBlob);

      const content = targetRef.current.innerHTML;
      const header = `
            <html xmlns:o='urn:schemas-microsoft-com:office:office' 
                xmlns:w='urn:schemas-microsoft-com:office:word' 
                xmlns='http://www.w3.org/TR/REC-html40'>
            <head><meta charset='utf-8'><title>Export To Word</title></head><body>`;
      const footer = `</body></html>`;
      const fullContent = header + content + footer;
      const wordBlob = new Blob([fullContent], { type: "application/msword" });
      let docFileName = agent.trim().toLowerCase().replace(/\s+/g, "_");
      zip.file(`${docFileName}_message.doc`, wordBlob);

      let zipFileName2 = agent.trim().toLowerCase().replace(/\s+/g, "_");
      zip.generateAsync({ type: "blob" }).then((zipBlob: any) => {
        saveAs(zipBlob, `${zipFileName2}_documents.zip`);
      });
    }
  }

  const copyToClipboard = () => {
    const htmlContent = targetRef.current.innerHTML;

    // สร้าง Blob สำหรับ HTML
    const blobHtml = new Blob([htmlContent], { type: "text/html" });

    // แปลง HTML เป็นข้อความธรรมดา
    const plainText = targetRef.current.innerText;
    const blobText = new Blob([plainText], { type: "text/plain" });

    // สร้าง ClipboardItem ที่มีทั้ง text/html และ text/plain
    const data = [
      new ClipboardItem({
        "text/html": blobHtml,
        "text/plain": blobText,
      }),
    ];

    // ใช้ Clipboard API คัดลอกทั้งสองรูปแบบ
    navigator.clipboard
      .write(data)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("การคัดลอก HTML ไปยังคลิปบอร์ดล้มเหลว:", err);
      });
  };

  const handleMenuClick = (e: any) => {
    // if (e.key === 'pdf') {
    //     // exportToPDF();
    exportToZip();

    // } else if (e.key === 'word') {
    //     exportToWord();
    // }
  };

  const handleEditClick = () => {
    setIsEditResult((prev) => !prev);
    console.log("handleEditClick");
  };

  const handleChangeResult = (value: string) => {
    // console.log("handleChangeResult value => ", value);
    setNewProcessMessage(value);
  };

  const handleSubmitNewResult = async () => {
    let endpoint = getUpdateResultByStepTypeEndpoint(step, uuidApp);
    await generateData(endpoint, {
      new_message: newProcessedMessage,
      token: getAuthToken() || '',
      wf_token: localStorage.getItem("wf_token") || '',
    });

    if (!error) {
      setProcessedMessage(newProcessedMessage);
      //   setMessage(newProcessedMessage)
      setNewProcessMessage("");
      setIsEditResult(false);
      setIsEditedMessage(true);
      handleNotifyUpdateResult(true, step);
      return;
    }
    handleNotifyUpdateResult(false, step);
  };

  const handleCancelNewResult = () => {
    setNewProcessMessage("");
    setIsEditResult(false);
  };

  //NOTIFY HANDLER
  const handleNotifyUpdateResult = (isSuccess: boolean, step: number) => {
    let title = isSuccess
      ? "Updated result success!"
      : "Updated result failed!";
    let description = isSuccess ? "result has updated" : "result has failed";

    switch (step) {
      case GENERATE_STEP.USER_STORIES:
        description = `USER STORIES ` + description;
        break;

      case GENERATE_STEP.FUNCTIONAL:
        description = `FUNCTIONAL ` + description;
        break;

      case GENERATE_STEP.SCREEN_SPECIFICATIONS:
        description = `SCREEN SPECIFICATIONS ` + description;
        break;

      case GENERATE_STEP.HTML:
        description = `HTML ` + description;
        break;

      default:
        description = "updated result";
        break;
    }

    //ตั้งค่าข้อมูลของ Notification
    setTriggerNotify(!triggerNotify);
    setTitleNotify(title);
    setDescriptionNotify(description);
    setIsSuccessNotify(isSuccess);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.ItemGroup key={"fileType"} title={"File Type"}>
        <Menu.Item key="pdf">PDF</Menu.Item>
        <Menu.Item key="word">DOC</Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  // const [arrScreenName, setArrScreenName] = useState([])

  // useEffect(() => {
  //   if(arrScreenName.length > 6 ){
  //     console.log("arrScreenName : ", arrScreenName)
  //   }
  // },[arrScreenName])

  // const setUpScrenNameArr = (message_:string) => {
  //   let a = message_.split("Screen Name: ")
  //   let arrScreenName : any = []
  //   // console.log("=========> ", a)
  //   for (const scr of a) {
  //     let screenName = getText(scr)
  //     if(screenName !== ''){
  //       arrScreenName.push(screenName)
  //       arrScreenName.push("")
  //     }
  //   }
  //   setArrScreenName(arrScreenName)
  // }

  const getText = (str: string) => {
    const regex = /^.*?(?=\n\n)/;
    const match = str.match(regex);
    return match ? match[0].trim() : '';
  };

  // const onclickEditHtmlToRegen = (index: number) => {
  //   console.log("index : ", index)
  //   console.log("arrScreenName index : ", arrScreenName[index])
  //   let screen_name : string = arrScreenName[index]
  //   console.log("screen_name : ", screen_name)
  //   let file_name = screen_name.replace(" ", "_") + '.txt'
  //   console.log("file_name : ", file_name)
  //   setFileName(file_name)
  // }

  return (
    <div className={styles.chatContainer}>
      {/* MESSAGE NOTIFY */}
      <MessageNofify
        trigger={triggerNotify}
        title={titleNotify}
        description={descriptionNotify}
        isSuccess={isSuccessNotify}
      />
      <div
        className={styles.chatBubble}
        // style={{ backgroundColor: chatColor }}
      >
        <div style={{ marginLeft: "10px", position: "relative" }}>
          {/* Add style for agent me */}
          <div
            className={
              agent === ME ? styles.chatBubbleMe : styles.chatBubbleOther
            }
          >
            <img
              src={profileImage || DEFAULT_IMG_PROFILE}
              alt="Profile"
              className={styles.profileImage}
            />
            <div>
              <div className={styles.messageHeader}>
                <strong>{agent}</strong>
              </div>

              <div
                className={styles.messageContent}
                onMouseEnter={() => setShowButtons(true)} // แสดงปุ่มเมื่อ mouse เข้า
                onMouseLeave={() => setShowButtons(false)} // ซ่อนปุ่มเมื่อ mouse ออก
              >
                {/* แสดงข้อความภายใน chat bubble */}
                <div
                  className={styles.messageBody}
                  ref={targetRef}
                  style={{ maxWidth: "800px" }}
                >
                  {/* ทำการเช็คหากอยู่ใน step HTML จะใช้ตัว process list ในการนำมาแสดงผล */}
                  {isEditResult ? (
                    <ResultEditor
                      processMessage={processedMessage}
                      onChange={handleChangeResult}
                    />
                  ) : step === GENERATE_STEP.HTML ? (
                    processedMessageList.map(
                      (item: ProcessMessageProps, index: number) => {
                        if (item.type === CONTENT_HTML) {
                          return (
                            <div
                              style={{
                                position: "relative",
                                padding: "12px 0 30px 0",
                              }}
                            >
                              <SyntaxLighter codeString={item.content} />
                            </div>
                          );
                        } else {
                          return (
                            <ReactMarkdown
                              children={item.content}
                              remarkPlugins={[remarkMath]}
                            />
                          );
                        }
                      }
                    )
                  ) : (
                    <ReactMarkdown
                      children={processedMessage}
                      remarkPlugins={[remarkMath]}
                    />
                  )}
                </div>

                {/* ปุ่มแก้ไข,ดาวน์โหลดและคัดลอก */}
                <div
                  style={agent === ME ? { display: "none" } : {}}
                  className={`${
                    showButtons ? styles.visible : styles.buttonsContainer
                  }`}
                >
                  {/* ถ้าอยู่ใน edit mode จะแสดงปุ่ม confirm และ cancel */}
                  {isEditResult ? (
                    <>
                      <Button
                        onClick={handleCancelNewResult}
                        disabled={loading}
                      >
                        <FaTimes size={18} color="#E53030" />
                      </Button>
                      <Button
                        onClick={handleSubmitNewResult}
                        disabled={loading}
                      >
                        <FaCheck size={18} color="#14AE5C" />
                      </Button>
                    </>
                  ) : (
                    <>
                      {/* ถ้าไม่อยู่ใน edit mode จะแสดงปุ่มแก้ไข,ดาวน์โหลดและคัดลอก */}
                      <Button onClick={handleEditClick}>
                        <FaEdit size={18} />
                      </Button>
                      <CopyButton onClick={copyToClipboard} copied={copied} />
                      <Button onClick={handleMenuClick}>
                        <FaDownload size={18} />
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
