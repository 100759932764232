import { CameraOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Input, notification, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useProfileForm } from "../forms/ProfileForm";
import {
  getUserCenterData,
  updateUserCenterData,
  uploadProfileImage,
} from "src/page/kn/util.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ProfileSetting: React.FC = () => {
  const { control, handleSubmit, setValue } = useProfileForm();
  const [profile, setProfile] = useState<any>({});
  const [avatar, setAvatar] = useState("");
  const [avatarFile, setAvatarFile] = useState<File>();
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    onUserProfile();
  }, [profile]);

  const fetchUserProfile = async () => {
    setLoading(true);
    try {
      const response = await getUserCenterData();
      if (!response) return;
      setProfile(response);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const onUserProfile = () => {
    const data = {
      phoneCountry: profile.phoneCountry,
      phoneNumber: profile.phoneNumber,
      imageProfileURL: profile.imageProfileURL,
    };
    Object.entries(data).forEach(([key, value]) => {
      setValue(key as any, value);
    });
  };

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setAvatarFile(file);
      setAvatar(URL.createObjectURL(file)); // แสดงตัวอย่างรูปก่อนอัปโหลดจริง
    }
  };

  const onSubmit = async (data: any) => {
    setIsSaveLoading(true);
    const updatedProfileData = [];

    try {
      if (avatarFile) await uploadProfileImage(avatarFile);
      if (data.phoneCountry)
        updatedProfileData.push({
          key: "phoneCountry",
          value: data.phoneCountry,
        });
      if (data.phoneNumber)
        updatedProfileData.push({
          key: "phoneNumber",
          value: data.phoneNumber,
        });
      await updateUserCenterData(updatedProfileData);
      await fetchUserProfile();
      notification.success({
        message: "Data updated successfully",
        description: "Your profile has been updated.",
      });
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setIsSaveLoading(false);
    }
  };

  const renderSkeleton = () => {
    return (
      <div className="flex flex-col gap-8">
        <div className="flex items-center gap-6">
          <Skeleton.Avatar active size={96} />
          <div className="flex flex-col gap-2">
            <Skeleton.Input active size="small" style={{ width: 150 }} />
            <Skeleton.Input active size="small" style={{ width: 200 }} />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <Skeleton.Input active size="large" block />
          <Skeleton.Input active size="large" block />
          <Skeleton.Input active size="large" block />
        </div>
        <Skeleton.Button active size="large" style={{ width: 120 }} />
      </div>
    );
  };

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      {loading ? (
        renderSkeleton()
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
          <div className="flex flex-col justify-center sm:flex-row sm:justify-normal items-center gap-6">
            <Badge
              offset={[-10, 84]}
              count={
                <div className="cursor-pointer bg-[#1861B4] hover:bg-blue-500 p-1 rounded-full">
                  {avatar ? (
                    <EditOutlined className="text-white" />
                  ) : (
                    <CameraOutlined className="text-white" />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                    onChange={handleAvatarChange}
                  />
                </div>
              }
            >
              <Controller
                name="imageProfileURL"
                control={control}
                render={({ field }) => (
                  <Avatar
                    size={96}
                    shape="circle"
                    src={avatar != "" ? avatar : field.value}
                    icon={!field.value && <UserOutlined />}
                  />
                )}
              />
            </Badge>

            <div className="flex flex-col items-center sm:items-start gap-2">
              <label className="text-lg font-bold">
                General Account Settings
              </label>
              <label className="text-sm text-gray-500">
                Personal info and options to manage it.
              </label>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-[#121927] text-sm font-medium">UserName</span>
            <Input
              size="large"
              className="w-full"
              value={profile.username}
              disabled
            />
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-[#121927] text-sm font-medium">
              Email Address{" "}
              {profile.isValidEmail === false ? (
                <span className="text-red-500 font-semibold">Not verified</span>
              ) : (
                <span className="text-green-500 font-semibold">Verified</span>
              )}
            </span>
            <Input
              size="large"
              className="w-full"
              value={profile.email}
              disabled
            />
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-[#121927] text-sm font-medium">
              Phone Number{" "}
              {profile.isValidPhone === false ? (
                <span className="text-red-500 font-semibold">Not verified</span>
              ) : (
                <span className="text-green-500 font-semibold">Verified</span>
              )}
            </span>
            <div className="flex flex-row gap-2">
              <Controller
                name="phoneCountry"
                control={control}
                render={({ field }) => (
                  <div className="w-12 h-12 flex">
                    <PhoneInput
                      value={field.value || ""}
                      onChange={(phone) => field.onChange(phone)}
                      inputStyle={{ display: "none" }}
                      buttonStyle={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                      }}
                      disabled={profile.isValidPhone === false}
                    />
                  </div>
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input
                      size="large"
                      className="w-full md:w-1/2"
                      {...field}
                      placeholder="Enter your phone number"
                      disabled={profile.isValidPhone === false}
                      value={field.value || ""}
                    />
                    {error && (
                      <span className="text-red-500 text-xs">
                        {error.message}
                      </span>
                    )}
                  </>
                )}
              />
            </div>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className="bg-[#1861B4] w-full md:w-1/2"
            loading={isSaveLoading}
          >
            Save Changes
          </Button>
        </form>
      )}
    </div>
  );
};
export default ProfileSetting;
