//@ts-ignore
export const BASE_URL_PMTX = `${window["env"]["REACT_APP_ZERO_URL"]}/pmtx-api/v1`;

// Billing
export const BILLING_INVOICE = `${BASE_URL_PMTX}/invoices`;
// Customer
export const USER_STRIPE_CUSTOMERS = `${BASE_URL_PMTX}/customers`;
// Contact
export const CONTACT_SALE = `${BASE_URL_PMTX}/contract-sale`;
// Billing
export const BILLING_HISTORY = `${BASE_URL_PMTX}/billing-history`;

// Subscription
export const CREATE_SUBSCRIPTION_ENDPOINT = `${BASE_URL_PMTX}/create-subscription`;
export const CANCLE_SUBSCRIPTION_ENDPOINT = `${BASE_URL_PMTX}/subscription`;
export const USER_PLAN_SELECTOR_ENDPOINT = `${BASE_URL_PMTX}/create-user-plans-selector`;
export const PAYMENT_METHOD_ENDPOINT = `${BASE_URL_PMTX}/paymentmethods`;

//@ts-ignore
export const BASE_URL_PMTX_TOKEN_USED = `${window["env"]["REACT_APP_ZERO_URL"]}/pmtx-ai-token-api/v1`;
export const USER_AI_TOKEN_USED = `${BASE_URL_PMTX_TOKEN_USED}/token-used`;

//Invite user
export const INVITE_USER_ENDPOINT = `${BASE_URL_PMTX}/invite-user`;
export const GET_INVITED_USER_ENDPOINT = `${BASE_URL_PMTX}/get-invited-user`;

//Angent
export const CREATE_AGENT_ENDPOINT = `${BASE_URL_PMTX}/agent`;
export const GET_AGENT_LIST_ENDPOINT = `${BASE_URL_PMTX}/agent-list`;
export const UPDATE_AGENT_ENDPOINT = `${BASE_URL_PMTX}/agent`;
export const DELETE_AGENT_ENDPOINT = `${BASE_URL_PMTX}/agent`;
export const ACTIVE_AGENT_ENDPOINT = `${BASE_URL_PMTX}/active-agents`;

//Number of apps
export const NUMBER_OF_APPS_ENDPOINT = `${BASE_URL_PMTX}/number-of-apps`;

//Document
export const GET_DOCUMENT_LIST_ENDPOINT = `${BASE_URL_PMTX}/list-documents`;

//workflow
export const GET_WORK_FLOW_USER_ENDPOINT = `${window["env"]["REACT_APP_ZERO_URL"]}/workflow-api/v1/get-user`;

// support ticket 
export const CREATE_TICKET_ENDPOINT = `${BASE_URL_PMTX}/support-ticket`; 
export const GET_CATEGORY_TICKET_ENDPOINT = `${BASE_URL_PMTX}/support-ticket-category`; 
export const GET_SUPPORT_TICKET_ENDPOINT = `${BASE_URL_PMTX}/support-tickets`; 
