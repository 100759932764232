import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";

export interface IAgentValidationForm {
  agentName: string;
  role: string;
  personality: string;
  expertise: Array<{
    flow_id: string;
    expertise_detail: string;
  }>;
}

// Define schema for step 1 (agentName and role)
const schemaStep1 = Yup.object().shape({
  agentName: Yup.string()
    .required("Agent name is required")
    .min(2, "Agent name must be at least 2 characters")
    .max(50, "Agent name must not exceed 50 characters")
    .default(""), // Default to an empty string if not provided
  role: Yup.string()
    .required("Role is required")
    .min(2, "Role must be at least 2 characters")
    .default(""), // Default to an empty string if not provided
  // Keep other fields as optional for this step
  personality: Yup.string().default(""), // Ensure default is not null
  expertise: Yup.array()
    .of(
      Yup.object().shape({
        flow_id: Yup.string().default(""),
        expertise_detail: Yup.string().default(""),
      })
    )
    .default([]), // Ensure default is not null
});

// Define schema for step 2 (personality)
const schemaStep2 = Yup.object().shape({
  agentName: Yup.string().default(""),
  role: Yup.string().default(""),
  personality: Yup.string()
    .required("Personality description is required")
    // .min(5, "Personality description must be at least 5 characters")
    .default(""), // Default to an empty string if not provided
  expertise: Yup.array()
    .of(
      Yup.object().shape({
        flow_id: Yup.string().default(""),
        expertise_detail: Yup.string().default(""),
      })
    )
    .default([]),
});

// Define schema for step 3 (expertise)
const schemaStep3 = Yup.object().shape({
  agentName: Yup.string().default(""),
  role: Yup.string().default(""),
  personality: Yup.string().default(""),
  expertise: Yup.array()
    .of(
      Yup.object().shape({
        flow_id: Yup.string().required().default(""),
        expertise_detail: Yup.string().required().default(""),
      })
    )
    .required("Expertise detail is required")
    .default([{ flow_id: "", expertise_detail: "" }]),
});

export const useAgentValidationForm = (
  currentStep: number,
  agent?: IAgentValidationForm
) => {
  let schema;
  let stepDefaults: Partial<IAgentValidationForm> = {};

  // If we have an agent, use its values as defaults
  const baseDefaults: IAgentValidationForm = agent
    ? {
        agentName: agent.agentName || "",
        role: agent.role || "",
        personality: agent.personality || "",
        expertise: agent.expertise || [{ flow_id: "", expertise_detail: "" }],
      }
    : {
        agentName: "",
        role: "",
        personality: "",
        expertise: [{ flow_id: "", expertise_detail: "" }],
      };

  // Add any step-specific validation requirements
  switch (currentStep) {
    case 0:
      schema = schemaStep1;
      stepDefaults = {
        agentName: baseDefaults.agentName,
        role: baseDefaults.role,
      };
      break;
    case 1:
      schema = schemaStep2;
      stepDefaults = { personality: baseDefaults.personality };
      break;
    case 2:
      schema = schemaStep3;
      stepDefaults = { expertise: baseDefaults.expertise };
      break;
    default:
      schema = schemaStep1;
      stepDefaults = {
        agentName: baseDefaults.agentName,
        role: baseDefaults.role,
      };
      break;
  }

  // Merge the base defaults with step-specific defaults
  const defaultValues = { ...baseDefaults, ...stepDefaults };

  return useForm<IAgentValidationForm>({
    defaultValues: defaultValues as IAgentValidationForm,
    resolver: yupResolver(schema) as Resolver<IAgentValidationForm>,
    mode: "onChange",
  });
};
