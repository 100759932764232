import React, { useState, useRef, useEffect } from "react";
import { Modal, notification, Spin } from "antd";
import { useOTPForm } from "../forms/OTPForm";
import { Controller } from "react-hook-form";
import { changePassword, changePasswordVerify } from "src/page/kn/util.service";
import NewPasswordModal from "./NewPasswordModal";
import { ChangePasswordResponse } from "../screens/ChangePassword";
import { LoadingOutlined } from "@ant-design/icons";

interface OTPModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  handleChangePassword: () => void;
  resChangePassword: ChangePasswordResponse;
}

const OTPModal: React.FC<OTPModalProps> = ({
  isOpen,
  isLoading,
  onClose,
  handleChangePassword,
  resChangePassword,
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { control, handleSubmit, reset, setValue, watch } = useOTPForm();
  const [isOpenNewPasswordModal, setIsOpenNewPasswordModal] = useState(false);
  const [verified, setVerified] = useState<any>({});

  useEffect(() => {
    if (isOpen) {
      reset();
      setValue("otp", Array(6).fill(""));
    }
  }, [isOpen]);

  const onSubmitOTPVerifyForm = async (data: { otp: string[] }) => {
    try {
      const otpCode = data.otp.join("");
      const verify = {
        type: "verify-email",
        userID: resChangePassword.id,
        refID: resChangePassword.refID,
        code: otpCode,
        groupIam2ID: null,
      };
      const response = await changePasswordVerify(verify);
      setVerified(response);

      if (response) {
        notification.success({
          message:
            "OTP verification successful. You can now change your password.",
        });
        onClose();
        setIsOpenNewPasswordModal(true);
      }
    } catch (error) {
      notification.error({
        message: "Failed to verify OTP",
        description: "Please try again or request a new code",
      });
    }
  };
  const getFormValues = () => {
    const values = watch("otp") || Array(6).fill("");
    return values;
  };

  const handleCloseNewPasswordModal = () => {
    setIsOpenNewPasswordModal(false);
  };

  return (
    <React.Fragment>
      <Modal
        title="Change Password"
        open={isOpen}
        onCancel={onClose}
        okText="Next"
        onOk={handleSubmit(onSubmitOTPVerifyForm)}
        okButtonProps={{ disabled: getFormValues().join("").length !== 6 }}
        className="otp-modal"
        centered
      >
        <div className="w-full flex flex-col gap-8 my-4">
          <div>
            <span className="text-gray-600 ">
              Check your email or phone number a 6-digit code was sent. Enter it
              within 10 minutes
            </span>
          </div>

          <div className="text-sm">
            <span className="font-medium">RefID : </span>
            <span className="text-gray-600">{resChangePassword.refID}</span>
          </div>

          <div className="flex justify-center gap-2">
            <Controller
              control={control}
              name="otp"
              render={({ field }) => (
                <>
                  {Array(6)
                    .fill(null)
                    .map((_, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        value={field.value[index] || ""}
                        onChange={(e) => {
                          const newValue = [...field.value];
                          newValue[index] = e.target.value;
                          field.onChange(newValue);
                          if (e.target.value && index < 5) {
                            inputRefs.current[index + 1]?.focus();
                          }
                        }}
                        onKeyDown={(e) => {
                          if (
                            e.key === "Backspace" &&
                            !field.value[index] &&
                            index > 0
                          ) {
                            inputRefs.current[index - 1]?.focus();
                          }
                        }}
                        className="w-12 h-12 text-center border rounded-lg shadow-md focus:border-blue-500 focus:outline-none text-lg"
                        maxLength={1}
                      />
                    ))}
                </>
              )}
            />
          </div>
          <div className="text-sm flex flex-col gap-1">
            <span
              className="text-blue-500 hover:text-blue-400 cursor-pointer"
              onClick={handleChangePassword}
            >
              Resend code{" "}
              {isLoading && (
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              )}
            </span>
            <span className="text-gray-600">
              Didn't get it? Check your spam folder or resend the code.
            </span>
          </div>
        </div>
      </Modal>

      <NewPasswordModal
        verified={verified}
        isOpen={isOpenNewPasswordModal}
        onClose={handleCloseNewPasswordModal}
      />
    </React.Fragment>
  );
};

export default OTPModal;
