import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export interface INewPasswordForm {
  password: string;
  confirmPassword: string;
}

const schema: yup.ObjectSchema<INewPasswordForm> = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[0-9]/, "Password must contain at least 1 number")
    .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
    .matches(
      /[^A-Za-z0-9]/,
      "Password must contain at least 1 special character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

export const useNewPasswordForm = () => {
  return useForm<INewPasswordForm>({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema),
    mode: "onChange",
  });
};
