import { CommentOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import React, { useState } from "react";
import style from "./AgentsChat.module.scss";
import { IAgentList } from "./AgentsDashboard";

interface IAgentChatProps {
  onClose: () => void;
  isOpen: boolean;
  agent?: IAgentList;
}

const AgentChatModal: React.FC<IAgentChatProps> = ({
  onClose,
  isOpen,
  agent,
}) => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState<
    Array<{ text: string; sender: "user" | "agent" }>
  >([]);
  const [loading, setLoading] = useState(false);

  const handleSendMessage = async () => {
    if (message.trim()) {
      try {
        setChatHistory((prev) => [...prev, { text: message, sender: "user" }]);
        setLoading(true);

        let response = await callChatAssistant({
          query: message,
          session_key: "6caa04e0-22dc-49bb-8a5b-b3d5d8f8f4a9",
          stream: true,
        });

        setTimeout(() => {
          setChatHistory((prev) => [
            ...prev,
            {
              text: response,
              sender: "agent",
            },
          ]);
          setLoading(false);
        }, 1000);

        setMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
        setChatHistory((prev) => [
          ...prev,
          {
            text: "Sorry, something went wrong. Please try again.",
            sender: "agent",
          },
        ]);
        setLoading(false);
      }
    }
  };

  const callChatAssistant = async (message: {
    query: string;
    session_key: string;
    stream: boolean;
  }) => {
    try {
      const response = await axios.post(
        `https://ml.oneweb.tech/api/v1/prediction/oneweb-chat-assistant`,
        message
      );

      const jsonObjects = response.data
        .split("\n")
        .filter(
          (line: any) =>
            line.trim().startsWith("data: ") && line.trim() !== "data: [DONE]"
        )
        .map((line: any) => {
          const jsonStr = line.replace("data: ", "");
          return JSON.parse(jsonStr);
        });

      let totalText = "";
      jsonObjects.forEach((obj: any) => {
        const text = obj.choices[0].text;
        totalText += text;
      });

      return totalText.replace(/<question>|<\/question>/g, "");
    } catch (e) {
      console.error("Error fetching agent list:", e);
      throw e;
    }
  };

  return (
    <Modal
      title={
        <>
          <CommentOutlined style={{ marginRight: "8px" }} />
          Chat with {agent?.agentName || "Agent"}
        </>
      }
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={600}
      className={style.chatModal}
    >
      <div className={style.chatContainer}>
        <div className={style.chatHistory}>
          {chatHistory.map((chat, index) => (
            <>
              <div className={style.senderName}>
                {chat.sender === "user" ? "Me" : agent?.agentName}
              </div>
              <div
                key={index}
                className={`${style.chatMessage} ${style.agentMessage}`}
              >
                {chat.text}
              </div>
            </>
          ))}

          {loading && (
            <>
              <div className={style.senderName}>{agent?.agentName}</div>
              <div className={`${style.chatMessage} ${style.agentMessage}`}>
                <span className={style.loadingText}>
                  <div className={style.loader}></div>
                </span>
              </div>
            </>
          )}
        </div>

        <div className={style.chatInput}>
          <TextArea
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            autoSize
            disabled={loading}
            style={{border: "none"}}
          />
          <Button
            onClick={handleSendMessage}
            disabled={loading}
            style={{
              cursor: loading ? "not-allowed" : "pointer",
              height: "100%",
              width: 40
            }}
            // icon={<SendOutlined className={style.sendIcon} />}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 1792 1792"
                className={style.sendIcon}
              >
                <path
                  fill="currentColor"
                  d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45q-14 8-31 8q-11 0-24-5l-453-185l-242 295q-18 23-49 23q-13 0-22-4q-19-7-30.5-23.5T640 1728v-349l864-1059l-1069 925l-395-162q-37-14-40-55q-2-40 32-59L1696 9q15-9 32-9q20 0 36 11"
                />
              </svg>
            }
          ></Button>
        </div>
      </div>
    </Modal>
  );
};

export default AgentChatModal;
