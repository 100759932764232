import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export interface IProfileForm {
  phoneNumber: string | null;
  imageProfileURL: string | null;
  phoneCountry: string;
}

const schema: yup.ObjectSchema<IProfileForm> = yup.object().shape({
  phoneNumber: yup.string().nullable().default(null),
  imageProfileURL: yup.string().nullable().default(null),
  phoneCountry: yup.string().default(null),
});

export const useProfileForm = () => {
  return useForm<IProfileForm>({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
};
