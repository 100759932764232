import React, { useEffect, useState } from "react";
import OTPModal from "../components/OTPModal";
import { Button, Input, notification, Skeleton } from "antd";
import { changePassword, getUserCenterData } from "src/page/kn/util.service";

export interface ChangePasswordResponse {
  id: string;
  email?: string;
  otp?: string;
  refID: string;
  isIAM2: boolean;
}

const ChangePassword: React.FC = () => {
  const [profile, setProfile] = useState<any>({});
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [resChangePassword, setResChangePassword] =
    useState<ChangePasswordResponse>({
      id: "",
      email: "",
      otp: "",
      refID: "",
      isIAM2: false,
    });
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    setLoading(true);
    try {
      const response = await getUserCenterData();
      if (!response) return;
      setProfile(response);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    try {
      const response = (await changePassword(true)) as ChangePasswordResponse;
      if (!response?.refID) return;

      setResChangePassword(response);
      setIsOTPModalOpen(true);
    } catch (error) {
      notification.error({
        message: "Failed to initiate password change. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderSkeleton = () => {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center gap-12">
        <Skeleton.Input active size="small" style={{ width: 150 }} />
        <Skeleton.Input active size="small" style={{ width: 300 }} />

        <div className="flex flex-col gap-2 w-full md:w-1/3">
          <Skeleton.Input active size="small" style={{ width: 100 }} />
          <Skeleton.Input active size="large" block />
          <Skeleton.Input active size="large" block />
        </div>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        renderSkeleton()
      ) : (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="w-full md:w-1/3 flex flex-col gap-12">
            <div className="flex flex-col gap-2">
              <label className="text-3xl">Reset Your Password</label>
              <span className="text-sm text-gray-600">
                Click the button and we'll send you a link to reset your
                password.
              </span>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-[#121927] text-sm font-medium">Email</span>
              <Input size="large" value={profile.email} disabled />
            </div>

            <Button
              type="primary"
              className="w-full bg-[#1861B4]"
              disabled={!profile.isValidEmail}
              onClick={handleChangePassword}
              loading={isLoading}
            >
              Send Reset Email
            </Button>
          </div>
        </div>
      )}

      <OTPModal
        isOpen={isOTPModalOpen}
        isLoading={isLoading}
        onClose={() => setIsOTPModalOpen(false)}
        resChangePassword={resChangePassword}
        handleChangePassword={handleChangePassword}
      />
    </>
  );
};

export default ChangePassword;
