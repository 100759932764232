import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Category from "../page/example/Profile";

import AppComponent from "src/page/app-component/AppComponent";
import BillingPage from "src/page/billing/BillingPage";
import DashboardZeroContent from "src/page/component/DashboardZeroContent";
import DataConnection from "src/page/component/database-connection/DataConnectionContainer";
import { Dashboard } from "src/page/dashboard/Dashboard";
import DataReference from "src/page/data-reference/DataReference";
import Test from "src/page/example/Test";
import Generate from "src/page/generate/Generate";
import History from "src/page/generate/history/History";
import InvitedList from "src/page/generate/invitation/InvitedList";
import LoginMain from "src/page/generate/login/LoginMain";
import Specifications from "src/page/generate/specification/Specifications";
import GojsTest from "src/page/gojstest/GojsTest";
import LoadDashboardPage from "src/page/menu/LoadDashboardPage";
import { pathRoutes } from "src/page/props/util.props";
import Story from "src/page/story/Story";
import EditStory from "src/page/story/story-edit/EditStory";
import EditStoryEntityGenPageContainer from "src/page/story/story-edit/entity/genpage/EditStoryEntityGenPageContainer";
import { PaymentsWrapper } from "src/page/subscription/PaymentsWrapper";
import Subscription from "src/page/subscription/SubscriptionPage";
import WorkFlowPage from "src/page/workflow/WorkFlowPage";
import UIFirstPage from "src/shared/loading/UIFirstPage";
import "../App.css";
import Topics from "../page/example/Topics";
import DashboardPage from "../page/menu/DashboardPage";
import Products from "../page/products/Products";
import PrivateRoute from "./PrivateRoute";
import AppPage from "src/page/generate/app/AppPage";
import ErrorPage from "src/page/error-page/ErrorPage";
import ContractSale from "src/page/subscription/ContractSale";
// import WorkFlowPage from "src/page/workflow/WorkFlowPage";
import PaymentsPage from "src/page/subscription/PaymentsPage";
// import { PaymentsWrapper } from "src/page/subscription/PaymentsWrapper";
import InviteModal from "src/page/generate/invitation/InviteModal";
import AgentsDashboard from "src/page/agents-dashboard/AgentsDashboard";
import KnowledgeBase from "src/page/kn/KnowledgeBase";
import UsageTracking from "src/page/usage/UsageTracking";
import SupportTocket from "src/page/support-ticket/SupportTicket";
import AppSpecifications from "src/page/generate/specification/AppSpecifications";
import CloudTablesPage from "src/page/cloud_tables/CloudTables";
import SettingScreen from "src/page/setting-page/SettingScreen";
import HelperScreen from "src/page/helpe-page/HelperScreen";

function MainRoutes() {
  return (
    //@ts-ignore
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {/* <React.Suspense fallback={<UILoading />}> */}

      <Routes>
        <Route path="/" element={<Test />} />

        <Route path={pathRoutes.Login} element={<LoginMain />} />
        <Route path={"/"} element={<UIFirstPage />} />
        <Route path={pathRoutes.Root} element={<UIFirstPage />} />

        {/* Protected Routes */}
        {/* <Route path='/' element={<PrivateRoute />}> */}
        {/* <Route path={pathRoutes.Generate} element={<Generate />} /> */}
        {/* <Route path={`${pathRoutes.Generate}/${pathRoutes.Specifications}`} element={<Specifications />} /> */}

        <Route path="/" element={<PrivateRoute />}>
          <Route path={`${pathRoutes.Generate}`} element={<Generate />} />
          <Route path={`/pmtx-dashboard`} element={<Dashboard />} />
          <Route path={`/coming-soon`} element={<ErrorPage />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Specifications}`}
            element={<Specifications />}
          />
          <Route
            path={`${pathRoutes.Generate}/apps/${pathRoutes.Specifications}`}
            element={<AppSpecifications />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.History}`}
            element={<History />}
          />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.KnowledgeBase}`}
            element={<KnowledgeBase />}
          />
          <Route
            path={
              `${pathRoutes.Generate}/${pathRoutes.KnowledgeBase}` + "/id/:id"
            }
            element={<KnowledgeBase />}
          />
          <Route
            path={
              `${pathRoutes.Generate}/${pathRoutes.KnowledgeBase}` +
              "/id/:id/folder/"
            }
            element={<KnowledgeBase />}
          />
          <Route
            path={
              `${pathRoutes.Generate}/${pathRoutes.KnowledgeBase}` +
              "/id/:id/folder/shared-with-me/:dir"
            }
            element={<KnowledgeBase isShared={true} />}
          />
          <Route
            path={
              `${pathRoutes.Generate}/${pathRoutes.KnowledgeBase}` +
              "/id/:id/folder/:dir"
            }
            element={<KnowledgeBase />}
          />
          <Route
            path={
              `${pathRoutes.Generate}/${pathRoutes.KnowledgeBase}` +
              "/id/:id/tab/:tab"
            }
            element={<KnowledgeBase />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Subscription}`}
            element={<Subscription />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.ContractSales}`}
            element={<ContractSale />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Payment}`}
            element={<PaymentsWrapper />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Workflow}`}
            element={<WorkFlowPage />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.InviteList}`}
            element={<InvitedList />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Billing}`}
            element={<BillingPage />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.AgentsDashboard}`}
            element={<AgentsDashboard />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Usage}`}
            element={<UsageTracking />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.supportTicket}`}
            element={<SupportTocket />}
          />
          <Route path={`${pathRoutes.Generate}/apps`} element={<AppPage />} />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.CloudTables}`}
            element={<CloudTablesPage />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Setting}`}
            element={<SettingScreen />}
          />
          <Route
            path={`${pathRoutes.Generate}/${pathRoutes.Helper}`}
            element={<HelperScreen />}
          />
        </Route>

        {/* <Route path="/stlight" element={<LighterTest/>} /> */}

        <Route path="/testgojs" element={<GojsTest />} />
        <Route path={pathRoutes.Dashboard} element={<LoadDashboardPage />} />
        <Route path={pathRoutes.MainDashboard} element={<DashboardPage />}>
          <Route index element={<DataConnection />} />
          <Route path="profile" element={<Category />} />
          <Route path="topics" element={<Topics />} />
          <Route path="products" element={<Products />} />
          <Route path="story" element={<Story />} />
          <Route path="database-connection" element={<DataConnection />} />
          <Route path="app-component" element={<AppComponent />} />
          <Route path="story/:id" element={<EditStory />} />
          <Route
            path="story/:id/genpage"
            element={<EditStoryEntityGenPageContainer />}
          />
          <Route path="" element={<DashboardZeroContent />} />
          <Route path="data-reference" element={<DataReference />} />
        </Route>
        {/* <Route
          path={`${pathRoutes.AgentsDashboard}`}
          element={<AgentsDashboard />}
        /> */}
      </Routes>

      {/* </React.Suspense> */}
    </BrowserRouter>
  );
}

export default MainRoutes;
