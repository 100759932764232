import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
import useFetch, { CachePolicies } from "use-http";
import { Col, Row } from "antd";
import styles from "./BoxDetailFile.module.scss";
import { FileContext, FileContextProps } from "../File.context";
import LinkIconPrimary from "src/page/kn/filePage/file-icon/link-icon-primary.png";
import FolderIcon from "src/assets/png/newFolderIcon40.png";
import connection_file from "src/assets/png/link.png";
import UserGroup from "./UserGroup";
import { getClassNameByType } from "../File/UtilFileCard";
import SvgClock from "../type/icon/SvgClock";
import UIFileType from "../type/UIFileType";
// import { useAppSelector } from "src/hooks";
import { checkEmpty } from "src/services/util.service";
import { addCommas, bytesToSizeUtil, getactivitity, getDateFormat, getFileDir, getNameOfUserNotFriend, getUrl, getUserDetail, KN_FILE_SERVICE_URL_MAIN } from "../../util.service";
import ButtonCenterCLose from "../ButtonCenterClose";

const limitAc = 10;
const BoxDetailFile = React.forwardRef((props: any, ref: any) => {
  const { t } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  let text = t("Files");
  const navigate = useNavigate();
  const [urlFile, setUrlFile] = useState(null);
  const [imageOwnerURL, setImageOwnerURL] = useState(null);
  const [mainUserOwner, setMainUserOwner]: any = useState({});
  const [whoHasAccesses, setWhoHasAccesses] = useState([]);
  const [file, setFile]: any = useState(null);
  const [activities, setActivities] = useState([]);
  const [offsetAc, setOffsetAc] = useState(0);
  const [size, setSize] = useState(0);
  const [isShared, setIsShared] = useState(false);
  // const buttonText = props.joined ? "Chat" : "join";


  const checkGetUrl = async () => {
    let url: any =
      // process.env.REACT_APP_FILE_SERVER +
      KN_FILE_SERVICE_URL_MAIN +
      "/downloadFile?fileId=" +
      fileContext.fileSelected?.file_app_id +
      "&size=200";
    url = await getUrl(
      url,
      fileContext.fileSelected?.file_app_id,
      200,
      fileContext.fileSelected?.file_type
    );
    setUrlFile(url);
  };

  useEffect(() => {
    if (
      fileContext.showDetail === true &&
      !checkEmpty(fileContext.fileSelected)
    ) {
      checkGetUrl();
      setIsShared(props.isShared)
      getSetMainUser();
      getAndSetWhoHasAccess();
      setFile(fileContext.fileSelected);
      getAndSetActivity();
      setSizeFile();
    }
  }, [fileContext.fileSelected, fileContext.showDetail]);
// }, [fileContext.fileSelected, fileContext.showDetail, myProfile.orgId]);

  const getWhoHasAccess = useFetch(
    process.env.REACT_APP_FILE_SERVER + "/getWhoHasAccess",
    {
      // path: APIEnpoint.myApps,
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  const getAndSetWhoHasAccess = async () => {
    await getWhoHasAccess.get(
      `?fileId=${fileContext.fileSelected?.file_app_id}&userId=${fileContext.userId
      }&offset=${0}&limitOfset=${5}`
    );
    setWhoHasAccesses(Object.assign([], getWhoHasAccess.response?.data?.data));
  };

  const getSetMainUser = async () => {
    let userData: any = await getUserDetail(
      fileContext.fileSelected.owner_user_id,""
    );
    let userDataTemp: any = userData;
    userDataTemp.img = userData.imageProfileURL;
    console.log("userDataTemp : ", userDataTemp);
    setMainUserOwner(userDataTemp);
  };

  const getAndSetActivity = async () => {
    let activityData: any = await getactivitity(
      fileContext.fileSelected.file_app_id,
      fileContext.userId,
      offsetAc,
      limitAc
    );
    console.log("activityData.data : ", activityData.data);
    setActivities(Object.assign([], activityData.data));
    // checkCanLoadMore(activityData.data.length, activityData.total);
  };

  const getLastModified = () => {
    try {
      let activitiesT = activities;
      let lastAc: any = activitiesT[0];
      let name = getNameOfUserNotFriend(lastAc.userData);
      if (checkEmpty(name)) {
        name = getNameOfUserNotFriend(mainUserOwner)
      }
      return name
    } catch (e) {
      return "";
    }
  };

  const setSizeFile = async () => {
    if (fileContext.fileSelected?.type_ === "DIRECTORY") {
      let res: any = await getFileDir(fileContext.fileSelected?.file_app_id);
      setSize(res.data);
    } else {
      setSize(fileContext.fileSelected?.size_);
    }
  };

  const getLastModifiedDate = () => {
    try {
      let activitiesT = activities;
      let lastAc: any = activitiesT[0];
      return getDateFormat(lastAc.create_date, t);
    } catch (e) {
      return "";
    }
  };
  const getLastOpened = () => {
    try {
      let activitiesT = activities;
      let lastAc: any = activitiesT[0];
      let name = getNameOfUserNotFriend(lastAc.userData);
      if (checkEmpty(name)) {
        name = getNameOfUserNotFriend(mainUserOwner)
      }
      return name;
    } catch (e) {
      return "";
    }
  };

  const gotoWhoHasAccess = () => {
    fileContext.setShowDetail(false);
    // fileContext.setShowShareFileModal(true);
    fileContext.setShowShareLinkModal(true);
  };

  const getModuleName = () => {
    let module_name = fileContext.fileSelected?.module_name;
    if (checkEmpty(module_name)) {
      module_name = t("Not specified")
    }
    return module_name
  }

  const getDepartmentName = () => {
    let department_name = fileContext.fileSelected?.department_name;
    if (checkEmpty(department_name)) {
      department_name = t("Not specified")
    }
    return department_name
  }

  return (
    <div className={styles.boxdetail}>
      <div style={{}}>
        <Row justify="start" className={styles.headerBoxDetail}>
          <Col span={24}>
            <div style={{ display: "flex" }}>
              <div>{t("Detail")}</div>

              <div
                className={styles.closeBtn}
                style={
                  {
                    // backgroundImage: `url(${closeIcon})`,
                    // backgroundSize: "20px 20px",
                  }
                }
                onClick={() => {
                  fileContext.setShowDetail(false);
                }}
              >
                <ButtonCenterCLose></ButtonCenterCLose>
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="start" className={styles.headerBoxDetail}>
          <Col span={22}>
            <div style={{ display: "flex" }}>
              <div>
                {fileContext.fileSelected.type_ == "FILE" && (
                  <div
                    className={[
                      getClassNameByType(
                        fileContext.fileSelected?.file_type,
                        false,
                        false
                      ),
                      styles.classBoxOveride,
                    ].join(" ")}
                  >
                    <div className={[styles.verticalCenter].join(" ")}>
                      {!checkEmpty(fileContext.fileSelected) && (
                        <UIFileType
                          file={fileContext.fileSelected}
                          urlImage={urlFile}
                          boxdetail={true}
                        ></UIFileType>
                      )}
                    </div>
                  </div>
                )}
                {fileContext.fileSelected.type_ === "DIRECTORY" && (
                  <div>
                    <div
                      style={{
                        backgroundImage: `url(${FolderIcon})`,
                        backgroundSize: "cover",
                        width: 40,
                        height: 35,
                      }}
                    ></div>
                  </div>
                )}
                {fileContext.fileSelected.type_ === "LINK" && (
                  <div>
                    <div
                      style={{
                        backgroundImage: `url(${LinkIconPrimary})`,
                        backgroundSize: "cover",
                        width: 40,
                        height: 40,
                      }}
                    ></div>
                  </div>
                )}
              </div>
              <div>
                <div
                  className={[
                    styles.textOverflowListFileText,
                    styles.boxIconText,
                  ].join(" ")}
                  style={{ alignContent: "left", fontSize: "0.9rem", width: "279px" }}
                >
                  <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    {fileContext.fileSelected?.file_name}
                  </div>
                </div>
                <div
                  className={[styles.boxIconText].join(" ")}
                  style={{
                    alignContent: "left",
                    fontSize: "0.7rem",
                    color: "#AFAFAF",
                  }}
                >
                  {bytesToSizeUtil(size)}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          justify="start"
          className={[styles.rowDetail, styles.lineBoxTopBottom].join(" ")}
        >
          <Col span={24}>
            <div style={{ display: "flex" }}>
              <div
                key={"mainUserOwner_detail_" + mainUserOwner.id}
                style={{
                  color: "#000000",
                  alignSelf: "center",
                  width: "44px",
                }}
              >
                <UserGroup row={mainUserOwner}></UserGroup>
              </div>
              <div className={styles.line}></div>
              {!getWhoHasAccess.loading &&
                whoHasAccesses?.map((row: any, index: number) => {
                  if (index > 2) return <></>;
                  return (
                    <div
                      key={"whoHasAccess_" + row.id_}
                      style={{
                        color: "#000000",
                        alignSelf: "center",
                        width: "50px",
                      }}
                    >
                      <UserGroup row={row}></UserGroup>
                    </div>
                  );
                })}
              {
                fileContext.tab !== "sharedWithMe" && !isShared &&
                <div
                  style={{
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                >
                  {/* {checkEmpty(fileContext.groupId) && ( */}
                  <div className={styles.linkShare} onClick={gotoWhoHasAccess}>
                    <div
                      className={styles.linkShareBackground}
                      style={{
                        backgroundImage: `url(${connection_file})`,
                      }}
                    ></div>
                  </div>
                  {/* )} */}
                </div>
              }

            </div>
          </Col>
        </Row>
        <div style={{ fontSize: "0.9rem", marginTop: "30px" }}>
          <Row justify="start" className={styles.rowDetail}>
            <Col span={8} className={styles.textHead}>
              {t("Type")}
            </Col>
            <Col span={16}>{file?.file_type}</Col>
          </Row>
          <Row justify="start" className={styles.rowDetail}>
            <Col span={8} className={styles.textHead}>
              {t("Size")}
            </Col>
            <Col span={16}>
              {size ? bytesToSizeUtil(size) + " (" + addCommas(size) + " bytes)" : "0"}
            </Col>
          </Row>
          {/* <Row justify="start" className={styles.rowDetail}>
          <Col span={12} className={styles.textHead}>
            {t("Storage used")}
          </Col>
          <Col span={12}></Col>
        </Row> */}
          <Row justify="start" className={styles.rowDetail}>
            <Col span={8} className={styles.textHead}>
              {t("Owner")}
            </Col>
            <Col span={16}>{getNameOfUserNotFriend(mainUserOwner)}</Col>
          </Row>
          <Row justify="start" className={styles.rowDetail}>
            <Col span={8} className={styles.textHead}>
              {t("Modified")}
            </Col>
            <Col span={16}>
              <div className={styles.valueDetail}>
                <div>{getLastModifiedDate() + " " + t("by")}</div>
                <div
                  className={styles.headerDetail}
                  style={{ display: "flex", width: "100%", paddingTop: "8px" }}
                >
                  <div className={styles.textOverText}>
                    <div>{getLastModified()}</div>
                  </div>
                  <div>
                    <SvgClock width="14px" height="14px"></SvgClock>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row justify="start" className={styles.rowDetail}>
            <Col span={8} className={styles.textHead}>
              {t("Opened")}
            </Col>
            <Col span={16}>
              <div className={styles.valueDetail}>
                <div>{getLastModifiedDate() + " " + t("by") + " "}</div>

                <div className={styles.textOverText}>
                  <div>{getLastOpened()}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row justify="start" className={styles.rowDetail}>
            <Col span={8} className={styles.textHead}>
              {t("Created")}
            </Col>
            <Col span={16}>{getDateFormat(file?.create_date_real ? file?.create_date_real : file?.create_date, t)}</Col>
          </Row>
          <Row justify="start" className={styles.rowDetail}>
            <Col span={8} className={styles.textHead}>
              {t("Module")}
            </Col>
            <Col span={16}>
              <div className={styles.valueDetail}>
                <div className={styles.textOverText}>
                  <div>{getModuleName()}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row justify="start" className={styles.rowDetail}>
            <Col span={8} className={styles.textHead}>
              {t("Department")}
            </Col>
            <Col span={16}>
              <div className={styles.valueDetail}>
                <div className={styles.textOverText}>
                  <div>{getDepartmentName()}</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
});

export default BoxDetailFile;
