import { SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  CheckboxProps,
  Dropdown,
  Input,
  Layout,
  Menu,
  Tabs,
  Tooltip,
} from "antd";
//@ts-ignore
import _, { uniqBy } from "lodash";
import useFetch, { CachePolicies } from "use-http";

import backIcon from "src/assets/png/comunityIcon/back.png";
import emptyTrash from "src/assets/png/trash.png";
import TrashFileContainer from "./Container/trashFile/TrashFileContainer";
import ManageversionFileContainer from "./Container/manageversionFile/ManageversionFileContainer";
import UIUploadIcon from "./component/UIUploadIcon";
import UIModalUploadLink  from './component/UIModalUploadLink'
import KNLinkContainer from "./Container/links/KNLinkContainer";
import UIModalCreateFolder from "./component/UIModalCreateFolder";
import UIModalUpload from "./component/UIModalUpload";
import UIModalRename from "./component/UIModalRename";
import UIModalMove from "./component/UIModalMove";
import UIModalShare from "./component/UIModalShare";
import UIModalDownload from "./component/UIModalDownload";
import UIModalConfrimDelete from "./component/UIModalConfrimDelete";
import { tabName, tabNameText } from "./properies.file";
import UIModalConfrimDeleteForever from "./component/UIModalConfrimDeleteForever";
import UIModalConfrimDeleteRestore from "./component/UIModalConfrimRestore";
import BoxDetailFile from "./component/BoxDetailFile";
import UIModalShareFile from "./component/UIModalShareFile";
import MediasContainer from "./Container/mediasFile/MediasContainer";
import LinksContainer from "./Container/linksFile/LinksContainer";
import UIShareLinkPopover from "./component/UIShareLinkPopover";
import UIModalConfrimEmptyTrash from "./component/UIModalConfrimEmptyTrash";
import UICoFileMenu from "./UICoFileMenu";

import MyFileContainer from "./Container/myFile/MyFileContainer";
import { useNavigate } from "react-router-dom";
import { FileContext, FileContextProps } from "./File.context";
import * as Props from "../../props/util.props";
import styles from "./UICoFileContainer.module.scss";

import ActivityLogContainer from "./Container/activityLogFile/ActivityLogFileContainer";
import { parseJwt } from "src/services/util.service";
import {
  bytesToSize,
  bytesToSize_Split,
  calFreeSize_Split,
  calPercent,
  checkEmpty,
  constKey,
  getAboutLimitMain,
  getCurrentFolderMain,
  getCurrentSharedWithCallService,
  getModuleNames,
  getToken,
  getUserDataMeto,
  mainState,
  mainTab,
  pathRoutes,
  reverseArr,
} from "../util.service";
import CenterPageLayout from "./CenterPageLayout";
import { AppContext } from "src/page/context/App.context";
const { Content } = Layout;
const { TabPane } = Tabs;

interface UICoFileContainerProps {
  id: string | undefined;
  dirId: string | undefined;
  isShared?: boolean;
  tab: string | undefined;
  maintab: () => string | null;
  isCommunity: boolean;
  viewdir?: string;
}
export default function UICoFileContainer({
  id,
  dirId,
  isShared = false,
  tab,
  maintab,
  isCommunity,
  viewdir = "",
}: UICoFileContainerProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const myProfile: any = getUserDataMeto()

  const appContext: any = useContext(AppContext);
  const fileContext = useContext<FileContextProps>(FileContext);

  const [userId, setUserId] = useState(parseJwt(getToken()).userID);
  const [groupId, setGroupId]: any = useState(id);

  const [showModal, setShowModal]: any = useState(true);
  const [activeKeyMainTab, setActiveKeyMainTab]: any = useState(maintab());
  const [offsetFile, setOffsetFile]: any = useState(0);
  const [isLoading, setIsLoading]: any = useState(false);
  const [initialPath, setInitialPath] = useState<any>([]);

  const [isAdminRoleFile, setIsAdminRoleFile] = useState<boolean>(false);
  const [page, setPage] = useState("files");

  useEffect(() => {
    fileContext.setGroupId(id);
  }, [id]);



  useEffect(() => {
    fileContext.setUserId(myProfile?.id);
  }, []);

  useEffect(() => {
    fileContext.setParentFolderId(dirId);
  }, [dirId]);

  useEffect(() => {
    fileContext.setViewDir(viewdir);
  }, [viewdir]);

  useEffect(() => {
    fileContext.setIsComunity(isCommunity);
  }, []);

  useEffect(() => {
    fileContext.setTab(tab);
    console.log("><:: tab", tab);
  }, [tab]);

  useEffect(() => {
    console.log("fileContext.groupId : ", fileContext.groupId);

    let idsTemp = fileContext.fileAbouts;
    console.log("idsTemp", idsTemp);
    for (let a = 0; a < idsTemp.length; a++) {
      try {
        idsTemp[a]?.cancel();
        // }
      } catch (e) {}
    }
  }, []);

  const checkSetTab = () => {
    if (checkEmpty(tab) || tabName.myFile === tab) {
      fileContext.setTab("");
    } else if (tab === tabName.trash) {
      fileContext.setTab(tabName.trash);
    } else if (tab === tabName.sharedWithMe) {
      fileContext.setTab(tabName.sharedWithMe);
    } else if (tab === tabName.activitiesLog) {
      fileContext.setTab(tabName.activitiesLog);
    } else if (tab === tabName.allactivitiesLog) {
      fileContext.setTab(tabName.allactivitiesLog);
    }
    //#region olded code
    // else if (fileContext.manageversion === tabName.manageversion) {
    //   fileContext.setTab(tabName.sharedWithMe);
    // }
    ////#endregion
  };
  const getTabText = (tab: string) => {
    if (checkEmpty(tab) || tabName.myFile === tab) {
      return tabNameText.myFile;
    } else if (tab === tabName.trash) {
      return t(tabNameText.trash);
    } else if (tab === tabName.sharedWithMe) {
      return t(tabNameText.sharedWithMe);
    } else if (tab === tabName.mediaimage) {
      return t(tabNameText.mediaimage);
    } else if (tab === tabName.mediavideo) {
      return t(tabNameText.mediavideo);
    } else if (tab === tabName.other) {
      return t(tabNameText.other);
    } else if (tab === tabName.unknow) {
      return t(tabNameText.unknow);
    } else if (tab === tabName.doc) {
      return t(tabNameText.doc);
    } else if (tab === tabName.search) {
      return t(tabNameText.search);
    } else if (tab === tabName.group) {
      return t(tabNameText.group);
    } else if (tab === tabName.activitiesLog) {
      return t(tabNameText.activitiesLog);
    } else if (tab === tabName.allactivitiesLog) {
      return t(tabNameText.allactivitiesLog);
    } else if (tab === tabName.links) {
      return t(tabNameText.links);
    } else {
      return t(tabNameText.myFile);
    }
  };
  const renderContainerMainTab = (tab: string) => {
    console.log("mainTab.links : ", tab)
    console.log("mainTab.links : ", mainTab.links)
    if (fileContext.groupId === mainState.init) return;
    if (tab === mainTab.medias) {
      return <MediasContainer />;
    } else if (tab === mainTab.links) {
      // return <LinksContainer />;
      // return <LinksContainer />;
      return <KNLinkContainer/>;
    }
  };

  const renderContainer = (tab: string, mainTabT: string) => {
    if (fileContext.groupId === mainState.init || mainTabT !== mainTab.files)
      return;
    if (tab === tabName.myFile) {
      return <MyFileContainer />;
    } else if (tab === tabName.sharedWithMe) {
      return <MyFileContainer isShared={isShared} />;
    } else if (tab === tabName.trash) {
      return <TrashFileContainer />;
    }
    //  else if (tab === tabName.group) {
    //   // return <GroupFileContainer />;
    // }
    else if (tab === tabName.mediavideo) {
      return <MyFileContainer />;
    } else if (tab === tabName.mediaimage) {
      return <MyFileContainer />;
    } else if (tab === tabName.other) {
      return <MyFileContainer />;
    } else if (tab === tabName.unknow) {
      return <MyFileContainer />;
    } else if (tab === tabName.doc) {
      return <MyFileContainer />;
    } else if (tab === tabName.search) {
      return <MyFileContainer />;
    } else if (tab === tabName.activitiesLog) {
      return <ActivityLogContainer />;
    } else if (tab === tabName.allactivitiesLog) {
      return <ActivityLogContainer isAdminRoleFile={isAdminRoleFile} />;
    } else {
      return <MyFileContainer isShared={isShared} />;
    }
  };

  useEffect(() => {
    console.log("isShared UiCofile container ", isShared);
  }, [isShared]);

  const handleClickPath = (recursiveParent: any) => {
    fileContext.setTab("");
    let dirid = "";
    if (!checkEmpty(recursiveParent?.file_app_id))
      dirid = recursiveParent?.file_app_id;
    console.log(" recursiveParent : ", recursiveParent);
    console.log(" dirid : ", dirid);
    let id = fileContext.groupId;
    if (checkEmpty(id)) id = "";
    if (checkEmpty(id)) id = constKey.myId;
    if (fileContext.isComunity) {
      navigate(
        pathRoutes.groupCommunity + "/" + id + "/folder/" + dirid + `&tab=file`
      );
    } else if (isShared && dirid) {
      navigate(
        pathRoutes.File + "/id/" + id + "/folder/shared-with-me/" + dirid
      );
    } else navigate(pathRoutes.File + "/id/" + id + "/folder/" + dirid);
  };

  const handleClickMainPath = () => {
    fileContext.setParentFolderId("");
    fileContext.setTab("");
    let id = fileContext.groupId;
    if (checkEmpty(id)) id = "";
    if (checkEmpty(id)) id = constKey.myId;
    if (fileContext.isComunity) {
      navigate({
        pathname: pathRoutes.groupCommunity + "/" + id,
        search: `?id=${id}&tab=file`,
      });
    } else navigate(pathRoutes.File + "/id/" + id);
  };

  const handleClickSpotsPath = () => {
    console.log("SpotsPath");
  };

  const getRecursiveParents = useMemo(() => {
    if (fileContext.groupId === mainState.init) return [];
    if (checkEmpty(fileContext.currentFolder?.recursiveParents)) return [];
    if (checkEmpty(initialPath)) return [];

    let recursiveParents = reverseArr(
      fileContext.currentFolder?.recursiveParents
    );
    let recursiveCurrentPath = reverseArr(initialPath);
    if (isShared) {
      if (recursiveParents[0]?.file_name === tabNameText.sharedWithMe)
        return recursiveParents;
      else if (recursiveParents?.length > 1) {
        recursiveCurrentPath.splice(2, recursiveParents?.length - 1, {
          file_name: "...",
          file_app_id: "",
        });
        recursiveCurrentPath.push(
          fileContext.currentFolder?.recursiveParents[0]
        );
      }
      recursiveCurrentPath = uniqBy(recursiveCurrentPath, "file_app_id");
      return recursiveCurrentPath;
    } else return uniqBy(recursiveParents, "file_app_id");
  }, [fileContext.currentFolder, initialPath]);

  useEffect(() => {
    checkSetTab();
    fileContext.setFilesSelected([]);
    getModuleNamesFn();
  }, []);

  useEffect(() => {
    checkSetTab();
  }, [fileContext.groupId, fileContext.parentFolderId]);

  useEffect(() => {
    if (fileContext.groupId === mainState.init) return;
    getAboutLimit();
  }, [fileContext.groupId, fileContext.userId, fileContext.syncAboutLimit]);

  useEffect(() => {
    if (fileContext.groupId === mainState.init) return;
    getCurrentFolder();
  }, [fileContext.parentFolderId]);

  useEffect(() => {
    if (fileContext.groupId === mainState.init) return;
    getCurrentSharedWith();
  }, [fileContext.groupId, fileContext.userId]);

  useEffect(() => {
    fileContext.setFilesSelected([]);
    appContext.setShowDownload(false);
    appContext.setProgressLoadFiles(Object.assign([], []));
  }, [fileContext.mainTab]);

  const getCurrentSharedWith = async () => {
    try {
      let data = await getCurrentSharedWithCallService({
        userId: fileContext.userId,
        groupId: fileContext.groupId,
      });
      fileContext.setCurrentSharedWith(data);
      console.log("getCurrentSharedWith : ", data);
    } catch (e) {
      console.error(e);
    }
  };

  const getAboutLimit = async () => {
    try {
      let data = await getAboutLimitMain({
        userId: fileContext.userId,
        groupId: fileContext.groupId,
      });
      fileContext.setAboutLimit(data);
      console.log("getAboutLimit : ", data);
    } catch (e) {
      console.error(e);
    }
  };

  const getModuleNamesFn = async () => {
    // setIsLoading(true);
    try {
      let data: any = await getModuleNames();
      fileContext.setModuleFiles(Object.assign([], data));
      console.log("data   ", data);
    } catch (e) {
      console.error(e);
    }
    // setIsLoading(false);
  };

  const getCurrentFolder = async () => {
    setIsLoading(true);
    try {
      let data: any = await getCurrentFolderMain({
        groupId: fileContext.groupId,
        userId: fileContext.userId,
        file_app_id: fileContext.parentFolderId,
      });

      if (!data) {
        fileContext.setCurrentPath([]);
      }
      fileContext.setCurrentFolder(data);
      console.log("data   ", data);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const getTotalLimit = () => {
    return fileContext.aboutLimit?.limitSize?.replace(" ", "");
  };

  const getTotalLimitSplit = () => {
    let res = calFreeSize_Split(
      fileContext.aboutLimit?.used,
      fileContext.aboutLimit?.limitSize
    );
    return res;
  };

  const getUsedLimitSplit = () => {
    return bytesToSize_Split(fileContext.aboutLimit?.used);
  };

  const getUsedLimit = () => {
    return fileContext.aboutLimit?.used
      ? bytesToSize(fileContext.aboutLimit?.used)?.replace(" ", "")
      : bytesToSize(0)?.replace(" ", "");
  };

  const getAboutLimitForTab = (tab: any) => {
    let res: any = { fileSize: 0, fileCount: 0 };
    res.fileSize = bytesToSize(0)?.replace(" ", "");
    if (
      checkEmpty(fileContext.aboutLimit) ||
      checkEmpty(fileContext.aboutLimit.tabs)
    )
      return res;
    let tabObj: any = fileContext.aboutLimit?.tabs[tab];
    if (checkEmpty(tabObj)) return res;
    res.fileSize = bytesToSize(tabObj.fileSize)?.replace(" ", "");
    res.fileCount = tabObj.fileCount;
    return res;
  };

  const getFileCountforTab = (tab: any) => {
    let fileCount = getAboutLimitForTab(tab).fileCount;
    let fileCountType: any = t("files");
    if (fileCount == 1) fileCountType = t("file");
    let fileCountText = fileCount + " " + fileCountType;
    return fileCountText;
  };

  const getColorLimitProgress = () => {
    let percent = calPercent(fileContext.aboutLimit);
    if (percent >= 100) {
      return "red";
    } else if (percent >= 90) {
      return "#ffc112";
    } else return "#0f4c82";
  };

  const handleBack = () => {
    let current_location = location.pathname;
    console.log("current_location : ", current_location);
    if (current_location !== "/file") {
      navigate(pathRoutes.File);
    } else {
      navigate(pathRoutes.Apps);
    }
  };

  const getClasActive = (tab: any) => {
    if (tab === fileContext.tab) return styles.boxMenuTabActive;
    else return "";
  };

  const getClasActiveShareWithMe = (tab: any) => {
    if (tab === fileContext.tab) return styles.boxMenuTabActive;
    let s = getRecursiveParents;
    if (s.length != 0 && s[0]?.file_name === "Shared with me") {
      return styles.boxMenuTabActive;
    } else return "";
  };

  const getGroupDetail = useFetch(
    process.env.REACT_APP_PLAYME_URL + "/api/v2/groups/",
    {
      // path: APIEnpoint.myApps,
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );
  const getGroupDetailCall = async () => {
    await getGroupDetail.get(fileContext.groupId);
    console.log(
      "getGroupDetail.response?.data : ",
      getGroupDetail.response?.data
    );
    fileContext.setGroupDetail(getGroupDetail.response?.data);
  };

  useEffect(() => {
    if (!checkEmpty(fileContext.groupId)) {
      getGroupDetailCall();
    }
  }, [fileContext.groupId]);

  const getMainTextOfFile = () => {
    if (checkEmpty(fileContext.groupId))
      if (fileContext.tab === tabName.activitiesLog) {
        return t("Activities Log");
      } else if (fileContext.tab === tabName.allactivitiesLog) {
        return t("All Activities Log");
      } else {
        return t("My Files");
      }
    else {
      return fileContext.groupDetail?.name;
    }
  };

  const showPath = () => {
    let res =
      fileContext.tab !== tabName.doc &&
      fileContext.tab !== tabName.other &&
      fileContext.tab !== tabName.unknow &&
      fileContext.tab !== tabName.mediaimage &&
      fileContext.tab !== tabName.mediavideo &&
      fileContext.tab !== tabName.file &&
      fileContext.tab !== tabName.search &&
      fileContext.tab !== tabName.links &&
      fileContext.tab !== tabName.trash;
    return res;
  };

  const clickTabMain = (key: any, e: any) => {
    setActiveKeyMainTab(key);
    fileContext.setTab("");
    let id = fileContext.groupId;
    fileContext.setMainTab(key);

    if (checkEmpty(id)) id = "";
    if (key === "files") {
      setPage("files");
      handleClickPath({});
    } else if (key === "medias") {
      setPage("medias");
      if (checkEmpty(id)) id = constKey.myId;
      if (fileContext.isComunity) {
        navigate({
          pathname: pathRoutes.groupCommunity + "/" + id + "/tab/" + key,
          search: `?id=${id}&tab=file`,
        });
      } else navigate(pathRoutes.File + "/id/" + id + "/tab/" + key);
    } else if (key === "links") {
      setPage("links");
      if (checkEmpty(id)) id = constKey.myId;
      if (fileContext.isComunity) {
        navigate({
          pathname: pathRoutes.groupCommunity + "/" + id + "/tab/" + key,
          search: `?id=${id}&tab=file`,
        });
      } else navigate(pathRoutes.File + "/id/" + id + "/tab/" + key);
    }
  };

  const getItempText = () => {
    let len = fileContext.filesSelected?.length;
    if (len === 1) return t("item");
    else return t("items");
  };

  const cancelMedias = () => {
    fileContext.setFilesSelected(Object.assign([], []));
  };

  const downloadMedias = () => {
    appContext.setShowDownload(true);
    let files = fileContext.filesSelected;
    let filesTemp = [];
    for (let a = 0; a < files.length; a++) {
      if (!check(files[a])) {
        filesTemp.push(files[a]);
      }
    }

    fileContext.setFilesSelected(Object.assign([], []));
    appContext.setFilesToDownload(Object.assign([], filesTemp));
    appContext.setToDownloadFiles(true);
  };

  const check = (file: any) => {
    let files: any = appContext.progressLoadFiles;
    let found = false;
    for (let a = 0; a < files.length; a++) {
      if (file.file_app_id === files[a]?.file?.file_app_id) {
        found = true;
        break;
      }
    }
    return found;
  };

  const getClassScreen = () => {
    return styles.screenPage;
  };

  const getClassMainBox = () => {
    // if (isCommunityFn()) return styles.boxMainFileCommunity;
    return styles.boxMainFile;
  };

  const getClassMainBoxChild = () => {
    // if (isCommunityFn()) return styles.backMainChildCom;
    return styles.backMainChild;
  };

  const getTabsClass = () => {
    // if (isCommunityFn()) return styles.tabsCommunity;
    return styles.tabs;
  };

  const getClassPage = () => {
    if (page === "links") {
      return styles.boxLink;
    }
    return styles.boxFile;
  };

  const getUserData = () => {
    let token = getToken();
    let userdata: any = null;
    if (!checkEmpty(token)) {
      userdata = parseJwt(token);
    }
    return userdata;
  };

  const handlesearchItems = async (event: any) => {
    const value = event.target.value;
    const logsActivity =
      fileContext.tab === tabName.activitiesLog ||
      fileContext.tab === tabName.allactivitiesLog;
    if (!checkEmpty(value)) {
      console.log("value: " + value);
      fileContext.setFileSearchInput(value);
      if (!logsActivity) {
        fileContext.setTab(tabName.search);
      }
    } else {
      fileContext.setFileSearchInput("");
      if (!logsActivity) {
        fileContext.setTab("");
      }
    }
  };

  const onChangeSearchContentBox: CheckboxProps["onChange"] = (e) => {
    fileContext.setIsAdvacedSearch(e.target.checked);
  };

  const handleClickEmptyTrash = () => {
    fileContext.setDisplayConfirmEmptyTrash(true);
  };

  let checkHiddenBtnCreateUpload = useMemo(() => {
    let s = getRecursiveParents;
    let hidden =
      s[0]?.file_name === tabNameText.sharedWithMe &&
      (s.length == 1 || s.length == 2);
    if (hidden) {
      return hidden;
    }
    if (!checkEmpty(fileContext.tab)) {
      hidden = true;
    }
    return hidden;
  }, [fileContext.currentPath, fileContext.tab, getRecursiveParents]);

  useEffect(() => {
    if (
      !fileContext.currentFolder ||
      Object.keys(fileContext.currentFolder).length === 0
    )
      return;
    let index = fileContext?.currentPath?.findIndex(
      (item: any) =>
        item?.file_app_id === fileContext.currentFolder?.file_app_id
    );
    if (index > -1) {
      fileContext.setCurrentPath((prev: any[]) => {
        let a = [...prev.slice(index)];
        return a;
      });
    } else
      fileContext.setCurrentPath((prev: any[]) => [
        {
          file_app_id: fileContext.currentFolder?.file_app_id,
          file_name: fileContext.currentFolder?.file_name,
          parent_folder_id: fileContext.currentFolder?.parent_folder_id,
        },
        ...prev,
      ]);
  }, [fileContext.currentFolder]);

  useEffect(() => {
    if (fileContext.currentPath.length > 0) {
      localStorage.setItem(
        "currentPath",
        JSON.stringify(fileContext.currentPath)
      );
    }
    setInitialPath(fileContext.currentPath);
  }, [fileContext.currentPath]);

  useEffect(() => {
    const currentPathFromStorage = localStorage.getItem("currentPath");
    if (currentPathFromStorage) {
      fileContext.setCurrentPath(JSON.parse(currentPathFromStorage));
      setInitialPath(JSON.parse(currentPathFromStorage));
    }
  }, []);

  const clickModuleFile = (moduleFile: { module_name: string }) => {
    fileContext.setCurrentFolder(null);
    fileContext.setParentFolderId(null);
    if (moduleFile?.module_name == fileContext.moduleFileAc) {
      fileContext.setModuleFileAc(null);
    } else fileContext.setModuleFileAc(moduleFile?.module_name);
  };

  const handleMenuClick = (e: any) => {
    console.log("Clicked menu item:", e.key);
    fileContext.setTab(e.key);
    let id = fileContext.groupId;
    if (checkEmpty(id)) id = "";
    if (checkEmpty(id)) id = constKey.myId;
    navigate(pathRoutes.File + "/id/" + id + "/tab/" + e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={tabName.activitiesLog}>{t("Activities Log")}</Menu.Item>
      {isAdminRoleFile && (
        <Menu.Item key={tabName.allactivitiesLog}>
          {t("All Activities Log")}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <UIModalUploadLink/>
      <UIModalCreateFolder></UIModalCreateFolder>
      <UIModalUpload></UIModalUpload>
      <UIModalRename></UIModalRename>
      <UIModalMove></UIModalMove>
      <UIModalShare></UIModalShare>
      <UIModalDownload></UIModalDownload>
      <UIShareLinkPopover></UIShareLinkPopover>
      <ManageversionFileContainer></ManageversionFileContainer>
      <UIModalConfrimDelete></UIModalConfrimDelete>
      <UIModalConfrimDeleteForever></UIModalConfrimDeleteForever>
      <UIModalConfrimEmptyTrash></UIModalConfrimEmptyTrash>
      <UIModalConfrimDeleteRestore></UIModalConfrimDeleteRestore>
      <UIModalShareFile></UIModalShareFile>
      <CenterPageLayout>
        {/* {!isCommunity && <HeaderSearch></HeaderSearch>} */}
        <Content
          className={styles.FileContent}
          style={{
            width: "auto",
            flexWrap: "nowrap",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                // position: "absolute",
                width: "100%",
              }}
              className={getClassMainBox()}
              >
              <div className={styles.headerContainer}>Knowledge base</div>
              <div className={getClassMainBoxChild()}>
                {/* <div className={styles.backIconMain}>
                  <div
                    className={styles.backIcon}
                    style={{ backgroundImage: `url(${backIcon})` }}
                    onClick={handleBack}
                  />
                </div> */}
                <div className={styles.boxFile}>
                  <Tabs
                    defaultActiveKey={mainTab.files}
                    centered
                    size="large"
                    className={styles.overrideTab}
                    tabBarStyle={{ color: "#36393A" }}
                    onTabClick={clickTabMain}
                    activeKey={activeKeyMainTab}
                  >
                    <TabPane tab={t("Main")} key={mainTab.menu}>
                      <UICoFileMenu
                        setActiveKeyMainTab={(v: any) => {
                          setActiveKeyMainTab(v);
                        }}
                        checkMobile={true}
                      ></UICoFileMenu>
                    </TabPane>

                    <TabPane 
                      // tab={t("Files")} 
                      key={mainTab.files}
                      tab={
                        <span
                          className={
                            activeKeyMainTab === mainTab.files ? "activeTab" : undefined
                          }
                        >
                          {t("Files")}
                        </span>
                      }
                    >
                      <div
                        id="FileFoldersContainer"
                        className={
                          fileContext.isComunity
                            ? styles.boxFileTabCom
                            : styles.boxFileTab
                        }
                      >
                        <>
                          {/* {fileContext.tab !== tabName.activitiesLog &&
                          fileContext.tab !== tabName.allactivitiesLog ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "right",
                              }}
                            >
                              <Dropdown
                                overlay={menu}
                                placement="bottomRight"
                                overlayStyle={{ minWidth: "150px" }}
                              >
                                <SettingOutlined
                                  style={{
                                    fontSize: "20px",
                                    color: "#0F4C82",
                                    cursor: "pointer",
                                  }}
                                />
                              </Dropdown>
                            </div>
                          ) : null} */}

                          <div className={styles.moduleContainer}>
                            {//  [{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"xxxxxxxxxxx"},{module_name:"yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy"}]?.map((moduleFile: any, idx: number) => {
                            fileContext.moduleFiles?.map(
                              (moduleFile: any, idx: number) => {
                                return (
                                  <div
                                    onClick={() => {
                                      clickModuleFile(moduleFile);
                                    }}
                                    className={
                                      fileContext.moduleFileAc ===
                                      moduleFile?.module_name
                                        ? styles.moduleItemActive
                                        : styles.moduleItem
                                    }
                                  >
                                    {moduleFile?.module_name}
                                  </div>
                                );
                              }
                            )}
                          </div>

                          {!isCommunity && (
                            <div style={{ fontSize: "1rem", fontWeight: 500 }}>
                              {getMainTextOfFile()}
                            </div>
                          )}
                          <div>
                            {/* {fileContext.tab !== tabName.activitiesLog &&
                              fileContext.tab !== tabName.allactivitiesLog && (
                                <Checkbox onChange={onChangeSearchContentBox}>
                                  {t("Search Content")}
                                </Checkbox>
                              )} */}
                            <div className={styles.headerGroup}>
                              <div className={styles.searchBox}>
                                <Input
                                  placeholder={t("Search")}
                                  prefix={
                                    <SearchOutlined
                                      style={{ paddingRight: "8px" }}
                                    />
                                  }
                                  maxLength={200}
                                  className={styles.inputSearchBox}
                                  onChange={handlesearchItems}
                                  value={fileContext.fileSearchInput}
                                />
                              </div>
                              {checkHiddenBtnCreateUpload ? null : (
                                <UIUploadIcon
                                  isShared={isShared}
                                  getRecursiveParents={getRecursiveParents}
                                />
                              )}
                            </div>
                          </div>
                        </>

                        <div className={styles.boxPath}>
                          {isLoading
                            ? null
                            : showPath() &&
                              getRecursiveParents?.map(
                                (recursiveParent: any, idx: number) => {
                                  return (
                                    <div style={{ display: "flex" }} key={idx}>
                                      {idx === 0 ? (
                                        <div style={{ display: "flex" }}>
                                          <div
                                            className={styles.pathNotActive}
                                            onClick={() => {
                                              handleClickPath({});
                                            }}
                                          >
                                            {checkEmpty(fileContext.groupId)
                                              ? t("My Files")
                                              : t("Group Files")}
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      <div style={{ display: "flex" }}>
                                        <div className={styles.symbolPath}>
                                          {t(">")}
                                        </div>
                                        <div
                                          className={
                                            idx ===
                                              getRecursiveParents?.length - 1 &&
                                            tabName.manageversion !=
                                              fileContext.tab
                                              ? styles.pathActive
                                              : getRecursiveParents[idx]
                                                    .file_app_id === ""
                                                ? styles.pathSpotsNotActive
                                                : styles.pathNotActive
                                          }
                                          onClick={() => {
                                            getRecursiveParents[idx]
                                              .file_app_id === ""
                                              ? handleClickSpotsPath()
                                              : handleClickPath(
                                                  recursiveParent
                                                );
                                          }}
                                        >
                                          {t(recursiveParent.file_name)}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          {(fileContext.tab === tabName.doc ||
                            fileContext.tab === tabName.other ||
                            fileContext.tab === tabName.unknow ||
                            fileContext.tab === tabName.trash ||
                            fileContext.tab === tabName.mediaimage ||
                            fileContext.tab === tabName.mediavideo ||
                            fileContext.tab === tabName.search ||
                            fileContext.tab === tabName.file ||
                            fileContext.tab === tabName.links ||
                            fileContext.tab === tabName.group) && (
                            <div style={{ display: "flex", width: "95%" }}>
                              <div style={{ display: "flex" }}>
                                <div
                                  className={styles.pathNotActive}
                                  onClick={() => {
                                    handleClickMainPath();
                                  }}
                                >
                                  {checkEmpty(fileContext.groupId)
                                    ? t("My Files")
                                    : t("Group Files")}
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div className={styles.symbolPath}>
                                  {t(">")}
                                </div>
                                <div className={styles.pathActive}>
                                  {getTabText(fileContext.tab)}
                                </div>
                              </div>
                            </div>
                          )}
                          {fileContext.tab === tabName.trash && (
                            <div>
                              <div style={{ flex: "0" }}>
                                <Tooltip
                                  placement="bottom"
                                  title={t("Empty Trash")}
                                  color="#36393A"
                                >
                                  <div
                                    className={styles.emptyTrash}
                                    style={{
                                      backgroundImage: `url(${emptyTrash})`,
                                    }}
                                    onClick={handleClickEmptyTrash}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        </div>

                        <div style={{ marginTop: "8px" }}>
                          {renderContainer(
                            fileContext.tab,
                            fileContext.mainTab
                          )}
                        </div>
                      </div>
                    </TabPane>

                    {/* <TabPane 
                      // tab={t("Media")} 
                      key={mainTab.medias}
                      tab={
                        <span
                          className={
                            activeKeyMainTab === mainTab.medias ? "activeTab" : undefined
                          }
                        >
                          {t("Media")}
                        </span>
                      }
                    >
                      <div
                        id="FileMediasContainer"
                        className={
                          fileContext.isComunity
                            ? styles.boxFileTabComMadia
                            : styles.boxFileTabMedia
                        }
                      >
                        <div className={styles.moduleContainer}>
                          {fileContext.moduleFiles?.map(
                            (moduleFile: any, idx: number) => {
                              return (
                                <div
                                  onClick={() => {
                                    clickModuleFile(moduleFile);
                                  }}
                                  className={
                                    fileContext.moduleFileAc ===
                                    moduleFile?.module_name
                                      ? styles.moduleItemActive
                                      : styles.moduleItem
                                  }
                                >
                                  {moduleFile?.module_name}
                                </div>
                              );
                            }
                          )}
                        </div>

                        {renderContainerMainTab(fileContext.mainTab)}
                      </div>
                    </TabPane> */}
                     <TabPane tab={t("Links")} key={mainTab.links}>
                        <div
                          // className={
                          //   fileContext.isComunity
                          //     ? styles.boxFileTabComLink
                          //     : styles.boxFileTab
                          // }
                        >
                          {renderContainerMainTab(fileContext.linkTab)}
                        </div>
                    </TabPane>
                    {fileContext.isComunity && (
                      <TabPane tab={t("Links")} key={mainTab.links}>
                        <div
                          className={
                            fileContext.isComunity
                              ? styles.boxFileTabComLink
                              : styles.boxFileTab
                          }
                        >
                          {renderContainerMainTab(fileContext.mainTab)}
                        </div>
                      </TabPane>
                    )}
                  </Tabs>
                </div>

                <div className={styles.menuFileWeb}>
                  <div className={getTabsClass()}>
                    <UICoFileMenu
                      setActiveKeyMainTab={(v: any) => {
                        setActiveKeyMainTab(v);
                        setPage("files");
                      }}
                      checkMobile={false}
                    ></UICoFileMenu>
                  </div>
                </div>
                {fileContext.showDetail && (
                  <BoxDetailFile isShared={isShared}></BoxDetailFile>
                )}
              </div>
            </div>
          </div>
          {fileContext.filesSelected?.length != 0 && (
            <div
              className={
                appContext.collapedMenu && window.innerWidth > 600
                  ? styles.boxMenuBottomMenuActive
                  : styles.boxMenuBottom
              }
            >
              <div className={styles.leftcss}>
                {fileContext.filesSelected?.length +
                  " " +
                  getItempText() +
                  " " +
                  t("selected")}
              </div>
              <div className={styles.rightcss} style={{ display: "flex" }}>
                <div className={styles.itemMenu} onClick={downloadMedias}>
                  {t("Download")}
                </div>
                <div className={styles.itemMenu} onClick={cancelMedias}>
                  {t("Cancel")}
                </div>
              </div>
            </div>
          )}
        </Content>
      </CenterPageLayout>
    </>
  );
}
