import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { PagingWithSearch } from "src/model/query/paging_with_search";
import { TokenUsageTracking } from "src/model/usage-tracking";
import { getAuthorizationPmtx } from "src/services/util.service";
import { USER_AI_TOKEN_USED } from "src/utils/endpoint/pmtx.endpoint";

export interface ICreateToken {
  input_tokens: number
  output_tokens: number
  total_tokens: number
  model: string
}

export interface IUserTokenUsing {
  id: string
  app_id_ai: string
  app_id_app_space: string
  user_id: string
  guest_user_id: string
  user_stories_total_tokens: number
  functional_total_tokens: number
  screen_spec_total_tokens: number
  html_total_tokens: number
  total_tokens_usage: number
  updated_at: string
  created_at: string
}



// สร้าง action สำหรับ Create Token using by user
export const handlerCreateAiTokenByProjectID = createAsyncThunk<
  IUserTokenUsing,
  { key: string; projectID: string; data: ICreateToken }
>(
  "aiToken/handlerCreateAiTokenByProjectID",
  async ({ key, projectID, data }) => {
    const response = await axios.post(
      `${USER_AI_TOKEN_USED}/${key}/${projectID}`,
      data,
      {
        headers: {
          Authorization: getAuthorizationPmtx(),
          GuestUserID: localStorage.getItem("guest_user_id"),
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  }
);


// สร้าง action สำหรับ Get Token using daily by user
export const fetchAiTokenUsageDailyByUserID = createAsyncThunk<
  TokenUsageTracking<IUserTokenUsing>,
  PagingWithSearch
>("aiToken/fetchAiTokenUsageDailyByUserID", async (params) => {
  const response = await axios.get(
    `${USER_AI_TOKEN_USED}?offset=${params.offset}&limit=${params.limit}&search=${params.search}`,
    {
      headers: {
        Authorization: getAuthorizationPmtx(),
        GuestUserID: localStorage.getItem("guest_user_id"),
      },
    }
  );

  return response.data;
});
