import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppsIcon from '@mui/icons-material/Apps';
import StorageIcon from '@mui/icons-material/Storage';
import TokenIcon from '@mui/icons-material/Token';
import { Stack } from '@mui/material';
import { Skeleton } from 'antd';
import 'chart.js/auto';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PagingWithSearch } from 'src/model/query/paging_with_search';
import { fetchActiveAgentsByUserID } from 'src/store/actions/agent/agetAction';
import { fetchAiTokenUsageDailyByUserID } from 'src/store/actions/ai-token/aiTokenActions';
import { fetchNumberOfAppsByUserID } from 'src/store/actions/apps/appAction';
import { fetchUserPlans } from 'src/store/actions/payment/planAction';
import { agentSelector } from 'src/store/slices/agents/agentSlice';
import { aiTokenSelector } from 'src/store/slices/ai-token/aiTokenSlice';
import { appSelector } from 'src/store/slices/apps/appSlice';
import { plansSelector } from 'src/store/slices/payments/plansSlice';
import { useAppDispatch } from 'src/store/store';

const UsageTrackingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loading: aiTokenLoading, aiTokenUsage } = useSelector(aiTokenSelector);
  const { loading: agentLoading, activeAgents } = useSelector(agentSelector);
  const { loading: appLoading, numOfApp } = useSelector(appSelector);
  const { loading: planLoading, currPlan } = useSelector(plansSelector);
  const [lastDays, setLastDays] = useState(7);

  useEffect(() => {
    dispatch(fetchUserPlans());
    // dispatch(fetchLatestAiTokenUsageDailyByUserID());
    dispatch(fetchActiveAgentsByUserID());
    dispatch(fetchNumberOfAppsByUserID());
  }, [dispatch]);

  useEffect(() => {
    let query: PagingWithSearch = {
      offset: 0,
      limit: 30,
      search: "",
    }
    dispatch(fetchAiTokenUsageDailyByUserID(query));
  }, [dispatch]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: number | string) => value.toLocaleString(),
        },
        grid: { display: false },
      },
      x: {
        grid: { display: false },
      },
    },
    plugins: {
      legend: { display: false },
    },
  }

  const barChartData = {
    labels: Array.from({ length: lastDays }, (_, i) =>
      dayjs(new Date())
        .subtract(lastDays - 1 - i, 'day')
        .format('DD MMM')
    ),
    datasets: [{
      label: 'Token Usage',
      data: Array.from({ length: lastDays }, (_, i) => {
        const currentDate = dayjs(new Date())
          .subtract(lastDays - 1 - i, 'day')
          .format('YYYY-MM-DD');
        const dailyData = aiTokenUsage?.token_dailys?.data?.find(
          item => dayjs(item.updated_at).format('YYYY-MM-DD') === currentDate
        );
        return dailyData?.total_tokens_usage || 0;
      }),
      backgroundColor: '#1C87F2',
      borderRadius: 8,
    }]
  }

  const renderSkeleton = (
    <div className="space-y-8 p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {[
          'Loading Active Agents Usage...',
          'Loading Number Of Apps Usage...',
          'Loading Tokens In Usage...',
          'Loading Tokens Out Usage...'
        ].map((label, i) => (
          <div key={i} className="card p-6">
            <Skeleton.Input active block />
            <div className="mt-2 text-gray-400 text-sm">{label}</div>
            <Skeleton.Input active block style={{ marginTop: 12 }} />
          </div>
        ))}
      </div>
      <div className="card p-6">
        <div className="text-gray-400 text-sm">Loading Token Usage Analytics...</div>
        <Skeleton.Input active block style={{ width: 200 }} />
        <div className="h-[400px] mt-6">
          <Skeleton.Input active block style={{ height: '100%' }} />
        </div>
      </div>
    </div>
  );

  if (aiTokenLoading || agentLoading || appLoading || planLoading) return renderSkeleton;

  return (
    <div className="dashboard-container scrollbar">
      <div className="bg-white border-b border-gray-200">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <h1 className="text-xl font-semibold text-gray-900">Usage Analytics</h1>
          </div>
        </div>
      </div>

      <div className="dashboard-content space-y-6 scrollbar">
        <div>
          <div className="grid grid-cols-12 gap-4">
            {/* Left Column */}
            <div className="col-span-12 lg:col-span-8">
              {/* Token Usage Chart */}
              <div className="card">
                <div className="flex flex-col h-full">
                  <div className="flex justify-between items-center p-4 border-b border-gray-100">
                    <div>
                      <h3 className="text-lg font-semibold text-gray-800">Token Usage</h3>
                      <p className="text-sm text-gray-500 mt-1">Daily token consumption analysis</p>
                    </div>
                    <select
                      className="px-4 py-2 bg-white border border-gray-200 rounded-lg text-sm font-medium hover:border-blue-500 transition-colors"
                      onChange={(e) => {
                        setLastDays(Number(e.target.value))

                        let query: PagingWithSearch = {
                          offset: 0,
                          limit: Number(e.target.value),
                          search: "",
                        }
                        dispatch(fetchAiTokenUsageDailyByUserID(query));
                      }}
                      value={lastDays}
                    >
                      <option value={7}>Last 7 days</option>
                      <option value={30}>Last 30 days</option>
                    </select>
                  </div>
                  <div className="p-6">
                    <div className="h-[300px]"> {/* Increased height for better visualization */}
                      <Bar
                        data={barChartData}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-span-12 lg:col-span-4 space-y-4">
              <div className="card p-4">
                <Stack spacing={1}>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-600">Active Agents</span>
                    <AccountCircleIcon className="text-blue-500 w-4 h-4" />
                  </div>
                  <span className="text-lg font-bold">{activeAgents?.active_agent_usage ?? 0}</span>
                  <span className="text-xs text-gray-500">of {currPlan?.metadata['ai_agent_workflows']}</span>
                </Stack>
              </div>

              <div className="card p-4">
                <Stack spacing={1}>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-600">Total Applications</span>
                    <AppsIcon className="text-blue-500 w-4 h-4" />
                  </div>
                  <span className="text-lg font-bold">{numOfApp?.number_of_apps_usage ?? 0}</span>
                  <span className="text-xs text-gray-500">of {currPlan?.metadata['number_of_apps']}</span>
                </Stack>
              </div>

              <div className="card p-4">
                <Stack spacing={1}>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-600">Tokens In</span>
                    <TokenIcon className="text-blue-500 w-4 h-4" />
                  </div>
                  <span className="text-lg font-bold">{aiTokenUsage?.full_token_usage ?? 50000}</span>
                  <span className="text-xs text-gray-500">tokens</span>
                </Stack>
              </div>
              <div className="card p-4">
                <Stack spacing={1}>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-600">Tokens Out</span>
                    <StorageIcon className="text-blue-500 w-4 h-4" />
                  </div>
                  <span className="text-lg font-bold">{(Number(aiTokenUsage?.total_tokens_used) ?? 0).toLocaleString()}</span>
                  <span className="text-xs text-gray-500">tokens</span>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageTrackingPage;
