import { Input, Modal, notification } from "antd";
import React, { useState } from "react";
import { useNewPasswordForm } from "../forms/NewPasswordForm";
import { Controller } from "react-hook-form";
import { getPasswordPolicy, newPassword } from "src/page/kn/util.service";

interface NewPasswordModalProps {
  verified: any;
  isOpen: boolean;
  onClose: () => void;
}

const NewPasswordModal: React.FC<NewPasswordModalProps> = ({
  verified,
  isOpen,
  onClose,
}) => {
  const { control, handleSubmit } = useNewPasswordForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitForm = async (value: any) => {
    setIsLoading(true);
    try {
      // First check password policy
      //   const policyCheck = await getPasswordPolicy();
      const data = {
        password: value.password,
        confirmPassword: value.confirmPassword,
        isForgotPassword: true,
      };
      const auth = `${verified.tokenType} ${verified.token}`;
      const response = await newPassword(data, auth);

      if (response) {
        notification.success({
          message: "Password changed successfully",
        });
        onClose();
      }
    } catch (error) {
      notification.error({
        message: "Failed to change password",
        description: "Please try again",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Change Password"
      open={isOpen}
      onCancel={onClose}
      okText="Next"
      onOk={handleSubmit(onSubmitForm)}
      okButtonProps={{
        loading: isLoading,
      }}
      centered
    >
      <div className="w-full flex flex-col gap-6 py-4">
        <span className="text-gray-600">
          Password must be at least 8 characters with at least 1 number, 1
          uppercase letter and 1 lowercase letter and 1 special character
        </span>
        <div className="flex flex-col gap-2">
          <span className="font-medium">
            New password <span className="text-red-500">*</span>
          </span>
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Input.Password
                  {...field}
                  placeholder="New Password"
                  status={error ? "error" : ""}
                  onChange={(e) => field.onChange(e.target.value)}
                />
                {error && (
                  <span className="text-red-500 text-xs">{error.message}</span>
                )}
              </>
            )}
          />
        </div>
        <div className="flex flex-col gap-2">
          <span className="font-medium">
            Confirm your new password <span className="text-red-500">*</span>
          </span>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Input.Password
                  {...field}
                  placeholder="Confirm New Password"
                  status={error ? "error" : ""}
                  onChange={(e) => field.onChange(e.target.value)}
                />
                {error && (
                  <span className="text-red-500 text-xs">{error.message}</span>
                )}
              </>
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewPasswordModal;
