import React, { useState } from "react";
import ProfileSetting from "./screens/ProfileSetting";
import { Drawer } from "antd";
import { IconButton } from "@mui/material";
import { Bolt } from "lucide-react";
import ChangePassword from "./screens/ChangePassword";

const sidebarItems = [
  { label: "Profile", component: <ProfileSetting /> },
  { label: "Change Password", component: <ChangePassword /> },
];

const SettingScreen: React.FC = () => {
  const [activeTab, setActiveTab] = useState(sidebarItems[0].label);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const SidebarContent = () => (
    <>
      <div className="flex flex-col gap-2">
        <label className="text-lg font-bold">Settings</label>
        <label className="text-sm text-gray-500">
          Manage your personal information and privacy
        </label>
      </div>
      <hr className="my-4" />
      {sidebarItems.map((item, key) => (
        <div
          key={key}
          className={`p-2 cursor-pointer rounded-md mb-2 truncate ${
            activeTab === item.label
              ? "bg-[#1861B4] text-white"
              : "hover:bg-gray-200"
          }`}
          onClick={() => {
            setActiveTab(item.label);
            setDrawerVisible(false);
          }}
        >
          {item.label}
        </div>
      ))}
    </>
  );

  return (
    <>
      <div className="grid grid-cols-12 gap-2 w-full">
        {/* Desktop Sidebar */}
        <div className="hidden sm:block col-span-12 sm:col-span-2 bg-gray-100 p-4 rounded-lg">
          <SidebarContent />
        </div>
        <div className="dashboard-container scrollbar col-span-12 sm:col-span-10">
          {/* Mobile & IPad Menu Button */}
          <div className="flex justify-end sm:hidden p-4">
            <IconButton onClick={() => setDrawerVisible(true)}>
              <Bolt />
            </IconButton>
          </div>

          {/* Mobile & IPad Drawer */}
          <Drawer
            title="Settings Menu"
            placement="right"
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            className="sm:hidden"
          >
            <SidebarContent />
          </Drawer>

          {/* Main Content */}
          <div className="w-full h-full p-8">
            {sidebarItems.find((item) => item.label === activeTab)?.component}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingScreen;
