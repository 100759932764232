import axios from "axios";
import { getAuthorization } from "src/services/util.service";
import { BASE_URL_PMTX } from "src/utils/endpoint/pmtx.endpoint";

export async function fetchScreenHelp() {
  let url: any = BASE_URL_PMTX + `/get-screen-help`;

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
