import React, { useContext, useState } from "react";
import styles from "../specification/Specifications.module.scss";
import WarningIcon from "src/assets/svg/warning-icon";
import MessageIcon from "src/assets/svg/messageIcon";
import { Drawer, IconButton } from "@mui/material";
import { CloseCircleFilled } from "@ant-design/icons";
import { GenerateContext } from "src/page/context/Generate.context";
import { ChevronDown, CirclePlus, FileText } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHistoryFiles,
  selectHistoryItemClickHandler,
  selectSidebarTabActive,
  setSidebarTabActive,
} from "src/store/slices/history/historySlice";
import { useNavigate } from "react-router";
import { pathRoutes } from "src/page/props/util.props";

interface HeaderContentComponentProps {
  title?: string;
  icon?: React.ReactNode;
  rightContent?: React.ReactNode;
  isDocument?: boolean;
  onNewThreadClick?: () => void;
}

const HeaderContentComponent: React.FC<HeaderContentComponentProps> = ({
  title,
  icon,
  rightContent,
  isDocument,
  onNewThreadClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuidApp } = useContext(GenerateContext);
  const sidebarTabActive = useSelector(selectSidebarTabActive);
  const historyFiles = useSelector(selectHistoryFiles);
  const handleHistoryItemClick = useSelector(selectHistoryItemClickHandler);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpenDrawer = () => {
    setMenuOpen(true);
  };

  const handleCloseDrawer = () => {
    setMenuOpen(false);
  };

  const handleSidebarTabClick = (tab: "document" | "history") => {
    dispatch(setSidebarTabActive(tab));
    handleCloseDrawer();
  };

  const handleClickHistoryItem = (item: any) => {
    handleHistoryItemClick(item);
  };

  const renderSidebarMobileSide = () => {
    return (
      <>
        <IconButton
          color="inherit"
          onClick={handleOpenDrawer}
          edge="end"
          sx={[
            {
              mr: 2,
            },
            menuOpen && { display: "none" },
          ]}
        >
          <ChevronDown />
        </IconButton>

        <Drawer anchor="top" open={menuOpen} onClose={handleCloseDrawer}>
          <div className="w-full h-screen flex flex-col bg-white overflow-hidden">
            <div className="flex justify-end mb-2">
              <IconButton
                color="inherit"
                onClick={handleCloseDrawer}
                edge="start"
              >
                <CloseCircleFilled />
              </IconButton>
            </div>

            <div className="flex flex-col space-y-2 px-2">
              {isDocument && (
                <>
                  <div>
                    <span className={styles.historyText}>New</span>
                    <div
                      className="flex items-center space-x-2 text-[#8D8D8D] hover:bg-gray-100 rounded-lg p-2 cursor-pointer"
                      onClick={onNewThreadClick}
                    >
                      <CirclePlus size={18} />
                      <span className={styles.fileName}>New Threads</span>
                    </div>
                  </div>
                  <div>
                    <span className={styles.historyText}>Document</span>
                    <div
                      className={
                        sidebarTabActive === "document"
                          ? styles.historyItemActive
                          : styles.historyItem
                      }
                      onClick={() => handleSidebarTabClick("document")}
                    >
                      <FileText size={18} />
                      <span className={styles.fileName}>Document</span>
                    </div>
                  </div>
                </>
              )}
              <div>
                <span className={styles.historyText}>History</span>
                <div className="h-[calc(100vh-40vh)] overflow-y-auto scrollbar">
                  {historyFiles.map((file, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        handleClickHistoryItem(file);
                        handleCloseDrawer();
                      }}
                      className={
                        uuidApp === file.app_id &&
                        sidebarTabActive === "history"
                          ? styles.historyItemActive
                          : styles.historyItem
                      }
                    >
                      <div className="grid grid-cols-2 gap-2 w-full">
                        <div className="flex items-center space-x-2">
                          <div className="w-[10%] flex items-center justify-center">
                            {file?.is_generating_item ? (
                              <WarningIcon width={24} height={24} />
                            ) : (
                              <MessageIcon />
                            )}
                          </div>
                          <span className={styles.fileName}>
                            {file.app_name}
                          </span>
                        </div>
                        <span className={styles.fileName}>{file.app_id}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </>
    );
  };

  return (
    <div
      className="flex flex-row justify-between items-center pb-4"
      style={{ borderBottom: "1px solid #E5E5E5" }}
    >
      <div className={styles.headerText}>
        {icon}
        <span className={styles.strongText}>{title}</span>
        <div className="block md:hidden">{renderSidebarMobileSide()}</div>
      </div>

      {/* For other components to be called in the future */}
      {/* <div className=" hidden md:flex justify-end ">{rightContent}</div> */}

      {sidebarTabActive === "document" ? (
        <div className=" flex justify-end ">{rightContent}</div>
      ) : (
        //sidebarTabActive === "history" | ""
        <div className="w-[75%] hidden md:block ">{rightContent}</div>
      )}
    </div>
  );
};

export default HeaderContentComponent;
