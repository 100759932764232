import { UserOutlined } from "@ant-design/icons";
import { Avatar, Badge } from "antd";
import React from "react";

interface IAvatarComponentProps {
  isMobile?: boolean;
  isNavExpanded?: boolean;
  user: any;
}

export const AvatarComponent: React.FC<IAvatarComponentProps> = ({
  isMobile,
  isNavExpanded,
  user,
}) => {
  const name = user && user.firstname ? user.firstname : "Guest";
  return (
    <div className="p-4 flex flex-row items-center">
      {isMobile ? (
        <>
          <Badge dot offset={[-4, 28]} color="#418863">
            <Avatar
              size={32}
              shape="square"
              src={user ? user.imageProfileURL : ""}
              icon={!user && <UserOutlined />}
            />
          </Badge>
          <span className="ml-3 text-white">{name}</span>
        </>
      ) : (
        <>
          <Badge dot offset={[-4, 28]} color="#418863">
            <Avatar
              size={32}
              shape="square"
              src={user ? user.imageProfileURL : ""}
              icon={!user && <UserOutlined />}
            />
          </Badge>
          {isNavExpanded && <span className="ml-3">{name}</span>}
        </>
      )}
    </div>
  );
};
