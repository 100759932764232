// @ts-nocheck
import { Button, notification, Popover, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import styles from "../generate/Generate.module.scss";
import { useNavigate } from "react-router-dom";
import LogoPromptX from "src/assets/png/PromptX_white.png";
import ThreeStarIcon from "src/assets/svg/threeStarIcon";
import * as Auth from "src/services/auth.service";
import { v4 as uuidv4 } from "uuid";
import { pathRoutes } from "../props/util.props";

import { Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { handlerCreateAiTokenByProjectID } from "src/store/actions/ai-token/aiTokenActions";
import {
  aiTokenSelector,
  setCloseTokenDialog,
} from "src/store/slices/ai-token/aiTokenSlice";
import { useAppDispatch } from "src/store/store";
import { GenerateContext } from "../context/Generate.context";
import { SpeechToText } from "./components/SpeechToText";
import ModalWarning from "./modal/modalWarning";
import DialogAiTokenExcessive from "./specification/user-story/components/DialogAiTokenExcessive";
import { fetchUserPlans } from "src/store/actions/payment/planAction";
import { Workflow } from "lucide-react";
import InviteModal from "./invitation/InviteModal";
import axios from "axios";
import { getAuthorizationPmtx, getToken } from "src/services/util.service";
import {
  getTokenParam,
  getAuthToken,
} from "src/services/specifications.service";
import { GET_WORK_FLOW_USER_ENDPOINT } from "src/utils/endpoint/pmtx.endpoint";
import { getUserCenterData } from "../kn/util.service";

type GenerateProps = {};

// Custom Hook: useGenerate
export const useGenerate = () => {
  const navigate = useNavigate();
  const { setMessageData } = useContext(GenerateContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null | number>(null);

  const auth = localStorage.getItem(Auth.AuthKeys.openID);

  const generateData = async (url: string, data: any) => {
    setLoading(true);
    setError(null);

    let token: any;

    if (auth) {
      token = JSON.parse(auth);
    }

    try {
      // ส่งข้อมูลไปยัง API โดยใช้ fetch
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.access_token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        setError(response.status);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setMessageData(response);
    } catch (err: any) {
      setError(err);
      console.log(err);

      notification.error({
        message: "Error",
        description: "There was an error generating data. Please try again.",
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, generateData, setLoading };
};

const userType = {
  USER: "user",
  GUEST: "guest",
};

function Generate({ }: GenerateProps) {
  const auth = localStorage.getItem(Auth.AuthKeys.openID);
  const keyUser = localStorage.getItem(Auth.AuthKeys._key_user);

  const navigate = useNavigate();
  const [valueText, setValueText] = useState("");
  const { messageData, projectName, setProjectName, uuidApp, setUuidApp } =
    useContext(GenerateContext);
  const { loading, error, generateData, setLoading } = useGenerate();
  const [errorMessage, setErrorMessage] = useState<string | null | number>(
    null
  );
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [isInviteModal, setIsInviteModal] = useState(false);

  const [openID, setOpenID] = useState<string | null>(null);
  const [typeUser, setTypeUser] = useState<string | null>(null);
  const [user, setUser] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const fullPlaceholder = "Describe what you want to build now.";
  const dispatch = useAppDispatch();
  const { currUserTokenUsingCheck, showTokenDialog } =
    useSelector(aiTokenSelector);

  // Add these checks in useEffect when component mounts
  useEffect(() => {
    const openIDFromStorage = localStorage.getItem(Auth.AuthKeys.openID);
    const userTypeFromStorage = localStorage.getItem(Auth.AuthKeys._key_user);

    if (!openIDFromStorage) {
      // No login detected, handle as guest
      let guestUserId = localStorage.getItem("guest_user_id");

      if (!guestUserId) {
        // Generate new guest ID if none exists
        guestUserId = `guest_${uuidv4()}`;
        localStorage.setItem("guest_user_id", guestUserId);
      }

      // Set as guest user
      localStorage.setItem(Auth.AuthKeys._key_user, userType.GUEST);
      setTypeUser(userType.GUEST);
    } else {
      // Normal logged in user
      setOpenID(openIDFromStorage);
      setTypeUser(userTypeFromStorage);
      callGetUserCenterData();
    }
  }, []);

  const callGetUserCenterData = async () => {
    let user = await getUserCenterData();
    // console.log("user", user);
    localStorage.setItem("meto", JSON.stringify(user));
  };

  useEffect(() => {
    // Primary storage in localStorage
    const backupGuestId = () => {
      const guestId =
        localStorage.getItem("guest_user_id") || `guest_${uuidv4()}`;

      // Store in both storages for redundancy
      localStorage.setItem("guest_user_id", guestId);
      sessionStorage.setItem("guest_user_id_backup", guestId);
    };

    // Monitor and restore from either storage
    const handleStorageChange = () => {
      const localGuestId = localStorage.getItem("guest_user_id");
      const sessionGuestId = sessionStorage.getItem("guest_user_id_backup");

      if (!localGuestId && sessionGuestId) {
        localStorage.setItem("guest_user_id", sessionGuestId);
      } else if (localGuestId && !sessionGuestId) {
        sessionStorage.setItem("guest_user_id_backup", localGuestId);
      } else if (!localGuestId && !sessionGuestId) {
        backupGuestId();
      }
    };

    // Initialize and set up listeners
    backupGuestId();
    window.addEventListener("storage", handleStorageChange);
    document.addEventListener("visibilitychange", handleStorageChange);

    // Check periodically
    const intervalId = setInterval(handleStorageChange, 1000);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      document.removeEventListener("visibilitychange", handleStorageChange);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchUserPlans());
  }, [dispatch]);

  useEffect(() => {
    clearLocalStorageOnload();

    const openIDFromStorage = localStorage.getItem(Auth.AuthKeys.openID);
    const userTypeFromStorage = localStorage.getItem(Auth.AuthKeys._key_user);
    const userFromStorage = localStorage.getItem(Auth.AuthKeys.user);

    setOpenID(openIDFromStorage);
    setTypeUser(userTypeFromStorage);
    setUser(userFromStorage);
    setProjectName("");

    const uuid = uuidv4();
    setUuidApp(uuid);

    let index = 0;
    const typingInterval = setInterval(() => {
      if (index < fullPlaceholder.length) {
        const charToAdd = fullPlaceholder[index];
        setPlaceholder((prev) => prev + charToAdd);
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // ปรับเวลาได้ตามต้องการ

    return () => clearTimeout(timer);
  }, []);

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement> | string) {
    const newValue = typeof e === "string" ? e : e.target.value;
    setValueText(newValue);
    console.log("setValueText", newValue);
  }

  useEffect(() => {
    console.log("messageData >>>>", messageData);
  }, [messageData]);

  const mutation = useMutation({
    mutationFn: Auth.login,
    onSuccess: (data) => {
      console.log("User created successfully:", data);
      // You can handle additional logic here, like updating cache or state
    },
    onError: (error) => {
      console.error("Error creating user:", error);
    },
  });

  function clearLocalStorageOnload() {
    //หากมาจาก guest login จะไม่ทำการ clearLocalStorage
    let isGuestLogin = localStorage.getItem("isGuestLogin");
    if (!isGuestLogin) {
      localStorage.removeItem("projectName");
      localStorage.removeItem("promptId");
      localStorage.removeItem("appMessages");
    }
  }

  async function onClickGenerate() {
    console.log("valueText", valueText);
    console.log("uuidApp ", uuidApp);

    localStorage.setItem("promptId", JSON.stringify(uuidApp));
    setLoading(true);

    if (!currUserTokenUsingCheck?.fullTokenUsage) {
      try {
        if (!user || !openID || !typeUser || typeUser === userType.GUEST) {
          let username = window["env"]["REACT_APP_USERNAME"];

          let password = window["env"]["REACT_APP_PASSWORD"];

          const { data }: any = await mutation.mutateAsync({
            username,
            password,
          });
          if (data && data.access_token) {
            localStorage.setItem(Auth.AuthKeys.openID, JSON.stringify(data));
            localStorage.setItem(Auth.AuthKeys._key_user, userType.GUEST);
          }
        }
      } catch (error) {
        console.error("Login failed:", error);
      } finally {
        setLoading(false);
      }

      try {
        let ping = `${window["env"]["REACT_APP_PING_AI"]}`;
        await generateData(
          `${window["env"]["REACT_APP_GENERATE_URL"]}/create/user_stories/${uuidApp}`,
          {
            requirement: valueText,
            n_pingpong: ping,
            token: getAuthToken() || "",
            wf_token: localStorage.getItem("wf_token") || "",
          }
        );
      } catch (error) {
        console.error("Error during data generation:", error);
      }
    }
    setLoading(false);
  }

  async function getWorkflowUser() {
    try {
      const response = await axios.get(GET_WORK_FLOW_USER_ENDPOINT, {
        headers: {
          Authorization: getAuthorizationPmtx(),
        },
      });
      return response.data || {}; // Return empty object if null
    } catch (error) {
      console.log("Error fetching workflow user:", error);
      return {}; // Return empty object on error
    }
  }

  async function getWorkflowToken(email: string, password: string) {
    const response = await axios.post(
      `${window["env"]["WORKFLOW_URL"]}/api/v1/authentication/sign-in`,
      {
        email: email,
        password: atob(password),
      }
    );
    return response.data;
  }

  useEffect(() => {
    getWorkflowUser().then((data) => {
      if (data && data.email) {
        // Add null check
        getWorkflowToken(data.email, data.password).then((data) => {
          if (data) {
            localStorage.setItem("wf_project_id", data.projectId);
            localStorage.setItem("wf_token", data.token);
          }
        });
      }
    });
  }, []);

  async function onClickGenerateWorkflow() {
    const projectId = uuidApp;
    localStorage.setItem("promptId", JSON.stringify(projectId));
    setLoading(true);

    if (!currUserTokenUsingCheck?.fullTokenUsage) {
      try {
        // Handle guest user authentication
        if (!user || !openID || !typeUser || typeUser === userType.GUEST) {
          const username = window["env"]["REACT_APP_USERNAME"];
          const password = window["env"]["REACT_APP_PASSWORD"];

          const { data }: any = await mutation.mutateAsync({
            username,
            password,
          });

          if (data && data.access_token) {
            localStorage.setItem(Auth.AuthKeys.openID, JSON.stringify(data));
            localStorage.setItem(Auth.AuthKeys._key_user, userType.GUEST);
          }
        }

        // Temporary AI API endpoint for testing
        const TEMP_AI_API = `${window["env"]["REACT_APP_GENERATE_URL"]}/create/user_stories/${uuidApp}`;

        // Get IAM token from authenticated user
        const iamToken = JSON.parse(
          localStorage.getItem(Auth.AuthKeys.openID) || "{}"
        ).access_token;

        await generateData(TEMP_AI_API, {
          requirement: valueText,
          projectId: projectId,
          iamToken: iamToken,
          n_pingpong: window["env"]["REACT_APP_PING_AI"],
          token: getAuthToken() || "",
          wf_token: localStorage.getItem("wf_token") || "",
        }).then(() => {
          dispatch(handlerCreateAiTokenByProjectID(projectId));
        });
      } catch (error) {
        console.error("Workflow generation failed:", error);
        setErrorMessage("Failed to generate workflow");
      }
    }
    setLoading(false);
  }

  function ClickLogin() {
    console.log("ClickLogin");
    return navigate(pathRoutes.Login);
  }

  // Modify ClickLogOut function
  function ClickLogOut() {
    const itemsToRemove = [
      Auth.AuthKeys.openID,
      Auth.AuthKeys._key_user,
      Auth.AuthKeys.user,
    ];

    // Remove specific items but keep guest_user_id
    itemsToRemove.forEach((key) => localStorage.removeItem(key));

    setOpenID(null);
    setTypeUser(null);
    setUser(null);

    handleCloseModal();
  }

  // Modify handleClickLogOut function
  const handleClickLogOut = () => {
    const itemsToRemove = [
      Auth.AuthKeys.openID,
      Auth.AuthKeys._key_user,
      Auth.AuthKeys.user,
    ];

    // Remove specific items but keep guest_user_id
    itemsToRemove.forEach((key) => localStorage.removeItem(key));

    setIsModalVisible(true);
    navigate(pathRoutes.Generate);
    window.location.reload();
  };

  const handleCloseModal = () => {
    // ปิด modal
    setIsModalVisible(false);
  };

  const handleCloseInviteModal = () => {
    setIsInviteModal(false);
  };

  const handleInviteModal = () => {
    setIsInviteModal(true);
  };

  useEffect(() => {
    if (error) {
      console.log("Error detected ", error);
    } else if (!loading && !error && messageData) {
      navigate(`/${pathRoutes.Generate}/${pathRoutes.Specifications}`, {
        state: { valueText },
      });
    }
  }, [error, loading, messageData, navigate, valueText]);

  const [linkPrivacy, setLinkPrivacy] = React.useState("");
  const [showLinkPrivacy, setShowLinkPrivacy] = React.useState(false);
  useEffect(() => {
    let protocol = window.location.protocol;
    let hostname = window.location.hostname;
    // let link = `${protocol}//${hostname}/center-web/privacy`;
    //@ts-ignore
    let link = window["env"]["REACT_APP_PRIVACY_URL"];
    setLinkPrivacy(link);

    let showLink = window["env"]["REACT_APP_SHOW_LINK_PRIVACY"];
    if (showLink == "Y") {
      setShowLinkPrivacy(true);
    }
  }, []);

  function ClickSignup() {
    console.log("ClickSignup");
    let link = window["env"]["REACT_APP_CENTERWEB_URL"];
    window.open(link + "/referral?refer_id=none", "_parent");
  }

  return (
    <>
      {!typeUser || typeUser === userType.GUEST ? (
        <div className={styles.accountHeader} style={{ display: "flex" }}>
          <div onClick={ClickSignup}>
            {" "}
            <Button type="default">Sign up</Button>
          </div>
          <div onClick={ClickLogin} style={{ marginLeft: "10px" }}>
            {" "}
            <Button type="primary">Sign in</Button>
          </div>
        </div>
      ) : (
        <></>
        // <div className={styles.accountHeader}>
        //   <Popover
        //     content={
        //       <div className={styles.userMenu}>
        //         <div className={styles.menuItems}>
        //           <div
        //             className={styles.menuItem}
        //             onClick={() =>
        //               navigate(
        //                 `${pathRoutes.Generate}/${pathRoutes.Subscription}`
        //               )
        //             }
        //           >
        //             <span>Subscription</span>
        //           </div>

        //           <div
        //             className={styles.menuItem}
        //             onClick={() => handleInviteModal()}
        //           >
        //             <span>Invite user</span>
        //           </div>

        //           <div
        //             className={styles.menuItem}
        //             onClick={() => handleClickLogOut()}
        //           >
        //             <span>Logout</span>
        //           </div>
        //         </div>
        //       </div>
        //     }
        //     trigger="hover"
        //     placement="bottomRight"
        //   >
        //     <div className={styles.welcomeButton}>Welcome</div>
        //   </Popover>
        // </div>
      )}

      {isModalVisible && (
        <ModalWarning
          onClose={handleCloseModal}
          open={isModalVisible}
          onLogOut={ClickLogOut}
        />
      )}

      {isInviteModal && (
        <InviteModal onClose={handleCloseInviteModal} isOpen={isInviteModal} />
      )}
      <div className={styles.generateContainer}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.imgLogo}>
              <img
                src={LogoPromptX}
                alt={LogoPromptX}
                className={styles.logo}
              />
            </div>
            <div
              style={{ display: "contents" }}
              className={`${styles.label} ${isVisible ? styles.visible : ""}`}
            >
              Describe the app you want to build
              <div>
                <strong>Today.</strong>
              </div>
            </div>
          </div>

          {loading && <Spin />}
          <div
            id="inputButtonContainer"
            className={styles.inputButtonContainer}
          >
            <div className="relative">
              <TextArea
                rows={8}
                placeholder={placeholder || ""}
                className={styles.textArea}
                value={valueText}
                onChange={handleChange}
              />
              <SpeechToText onChange={handleChange} />
            </div>
            <div className="w-full flex flex-col sm:flex-row justify-end items-center space-x-1 space-y-1">
              <Button
                id="generateButton"
                type="primary"
                size="middle"
                icon={<ThreeStarIcon />}
                className={`${styles.generateButton} w-full sm:w-auto`}
                onClick={onClickGenerate}
                disabled={loading || !valueText}
              >
                <span className={styles.buttonLabel}>Generate</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          right: "5px",
          bottom: "5px",
        }}
        className={styles.privacy}
      >
        <a
          href={linkPrivacy}
          target="_blank"
        // style={{
        //   color: "#f1f1f1 !important",
        // }}
        >
          {showLinkPrivacy ? linkPrivacy : "Privacy Policy"}
        </a>
      </div>
      <DialogAiTokenExcessive
        open={showTokenDialog}
        onClose={() => dispatch(setCloseTokenDialog())}
      />
    </>
  );
}
export default Generate;
