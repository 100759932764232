import {
  Home,
  History,
  Workflow,
  CreditCard,
  Settings,
  LayoutDashboard,
  Bot,
  Database,
  HelpCircle,
  MessageSquare,
  LogOut,
  BookOpen,
  Contact,
} from "lucide-react";
import React from "react";
import AppAddIcon from "src/assets/svg/app-add";
// import MarketPlaceIcon from "src/assets/svg/marketplace";
import UsageIcon from "src/assets/svg/usage";
import { pathRoutes } from "src/page/props/util.props";

export const menuItems = [
  {
    group: "Overview",
    items: [
      {
        icon: <Home size={20} />,
        label: "Home",
        id: "home",
        path: `${pathRoutes.Generate}`,
      },
      {
        icon: <LayoutDashboard size={20} />,
        label: "Dashboard",
        id: "dashboard",
        path: "/pmtx-dashboard",
      },
      {
        icon: <History size={20} />,
        label: "History",
        id: "history",
        path: `${pathRoutes.Generate}/${pathRoutes.Specifications}`,
      },
    ],
  },
  {
    group: "Workspace",
    items: [
      {
        icon: <Workflow size={20} />,
        label: "Automation",
        id: "workflow",
        path: `${pathRoutes.Generate}/${pathRoutes.Workflow}`,
      },
      {
        icon: <AppAddIcon />,
        label: "App",
        id: "app",
        path: `${pathRoutes.Generate}/apps`,
      },
      {
        icon: <Bot size={20} />,
        label: "Agents",
        id: "agents",
        path: `${pathRoutes.Generate}/${pathRoutes.AgentsDashboard}`,
      },
      {
        icon: <Database size={20} />,
        label: "Cloud Tables",
        id: "database",
        path: "/coming-soon",
        // path: `${pathRoutes.Generate}/${pathRoutes.CloudTables}`,
      },
      {
        icon: <BookOpen size={20} />,
        label: "Knowledge base",
        id: "knowledgeBase",
        // path: "/coming-soon",
        path: `${pathRoutes.Generate}/${pathRoutes.KnowledgeBase}`,
      },
    ],
  },
  {
    group: "Management",
    items: [
      {
        icon: <CreditCard size={20} />,
        label: "Billing",
        id: "billing",
        path: `${pathRoutes.Generate}/${pathRoutes.Billing}`,
      },
      {
        icon: <UsageIcon size={20} />,
        label: "Usage",
        id: "usage",
        path: `${pathRoutes.Generate}/${pathRoutes.Usage}`,
      },
      {
        icon: <Settings size={20} />,
        label: "Setting",
        id: "setting",
        path: `${pathRoutes.Generate}/${pathRoutes.Setting}`,
      },
    ],
  },
  {
    group: "Support",
    items: [
      {
        icon: <HelpCircle size={20} />,
        label: "Help",
        id: "help",
        path: `${pathRoutes.Generate}/${pathRoutes.Helper}`,
      },
      {
        icon: <MessageSquare size={20} />,
        label: "Support Ticket",
        id: "Support Ticket",
        path: `${pathRoutes.Generate}/${pathRoutes.supportTicket}`,
        // path: "/coming-soon",
      },
      {
        icon: <Contact size={20} />,
        label: "Invited List",
        id: "Invited List",
        path: `${pathRoutes.Generate}/${pathRoutes.InviteList}`,
      },
    ],
  },
  {
    group: "",
    items: [
      {
        icon: <LogOut size={20} />,
        label: "Logout",
        id: "logout",
        path: "/logout",
      },
    ],
  },
];
