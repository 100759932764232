import axios from "axios";
import { notification } from "antd";
import * as Auth from "src/services/auth.service";

// กำหนดประเภทสำหรับ window.env
interface EnvConfig {
  REACT_APP_ZERO_URL: string;
  REACT_APP_GENERATE_URL: string;
}

declare global {
  interface Window {
    env: EnvConfig;
  }
}

/**
 * ดึง access token จาก localStorage
 */
export const getAuthToken = (): string | null => {
  const auth = localStorage.getItem(Auth.AuthKeys.openID);
  return auth ? JSON.parse(auth)?.access_token : null;
};

/**
 * จัดการข้อผิดพลาดและแสดง notification
 */
const handleError = (error: any): void => {
  notification.error({
    message: "Error",
    description: error.message || "An error occurred",
    placement: "topRight",
  });
  throw error;
};

/**
 * เรียก API แบบ GET
 */
export const fetchApiGet = async (
  url: string,
  queryParams?: Record<string, any>
): Promise<any> => {
  try {
    const token = getAuthToken();

    if (queryParams) {
      const params = new URLSearchParams(queryParams).toString();
      url += `?${params}`;
    }

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

/**
 * เรียก API แบบ POST
 */
export const fetchApiPost = async (url: string, body?: any): Promise<any> => {
  try {
    const token = getAuthToken();

    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const fetchApiDelete = async (url: string, body?: any): Promise<any> => {
  try {
    const token = getAuthToken();

    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: body,
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// ฟังก์ชันอื่น ๆ สามารถสร้างและ export เพิ่มเติมได้ เช่น createAppSpace, addAppSpaceWithReturn, etc.
export const createAppSpace = async (appSpaceData: any): Promise<any> => {
  const url = `${window.env.REACT_APP_ZERO_URL}/pmtx-api/v1/dataAppspace`;
  return fetchApiPost(url, appSpaceData);
};



export const getTokenParam = () => {
  let token = getAuthToken();
  let param = ``
  if(token){
    let wf_token = localStorage.getItem("wf_token");
    param = `?token=${token}&wf_token=${wf_token}`
  }
  return param;
}