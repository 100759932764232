import React, { useContext, useEffect, useState } from "react";
import ComponentPreviewIframe from "src/components/zero/zero-story/iframe/ComponentPreviewIframe";
import { fetchUserByEmail, userSelector } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store/store";
import { fetchUserByEmailSvc } from "src/utils/service.util";
import { AppContext } from "../context/App.context";

// @ts-ignore
let password = window["env"]["WORKFLOW_DEFAULT_PASSWORD"];
// @ts-ignore
let workflow_url = window["env"]["WORKFLOW_URL"];

let MARGIN_LEFT = "0px";
const CloudTablesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const appContext: any = useContext(AppContext);
  const userReducer = useSelector(userSelector);

  const [url, setUrl] = useState("");

  useEffect(() => {
    setDataInit();
  }, []);

  const setDataInit = async () => {
    try {
      let user:any = await fetchUserByEmailSvc();
      console.log("user", user);
      // var user = userReducer.user;
      if (user !== undefined) {
        let _user = stringtoBase64(`${user!.email}`);

        let _password = user?.password;
        let urlTemp = `${workflow_url}/sign-in?u=${_user}&p=${_password}`;
        console.log("urlTemp:", urlTemp);
        setUrl(urlTemp);
      }
    } catch (e) {
      console.log(e);
      appContext.setTokenExpire(true);
    }
  };

  function stringtoBase64(str: string) {
    const utf8String = new TextEncoder().encode(str);
    const base64 = btoa(String.fromCharCode(...utf8String));
    return base64;
  }

  return (
    <div
      className="workflow-container"
      style={{
        width: `calc(100% - ${MARGIN_LEFT})`,
        marginLeft: `${MARGIN_LEFT}`,
        zIndex: 0
      }}
    >
      <ComponentPreviewIframe url={url} />
    </div>
  );
};

export default CloudTablesPage;
