import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthorizationPmtx } from "src/services/util.service";
import { PlanResponse } from "src/types/PlanProduct.type";
import { BASE_URL_PMTX } from "src/utils/endpoint/pmtx.endpoint";

export const fetchPlanProducts = createAsyncThunk<PlanResponse>(
    "products/fetchProducts",
    async () => {
        const response = await axios.get(BASE_URL_PMTX + "/products", {
            headers: {
                Authorization: getAuthorizationPmtx(),
            },
        });
        return response.data;
    }
);

export const fetchUserPlans = createAsyncThunk(
    "plansSelector/fetchUserPlans",
    async () => {
        const response = await axios.get(BASE_URL_PMTX + "/user-plans-selector", {
            headers: {
                Authorization: getAuthorizationPmtx(),
            },
        });
        return response.data.response;
    }
);


export const createUserFreePlans = createAsyncThunk(
    "plansSelector/createUserFreePlans",
    async () => {
        const response = await axios.post(
            BASE_URL_PMTX + "/user-plans-selector",
            {
                "productId": "prod_free",
                "subscriptionId": "sub_free"
            },
            {
                headers: {
                    Authorization: getAuthorizationPmtx(),
                },
            });
        return response.data;
    });