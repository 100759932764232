import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  fetchAiTokenUsageDailyByUserID,
  handlerCreateAiTokenByProjectID,
  IUserTokenUsing,
} from "src/store/actions/ai-token/aiTokenActions";
import { TokenUsageTracking } from "src/model/usage-tracking";

type TokenState = {
  aiTokenId?: string;
  aiTokenUsage?: TokenUsageTracking<IUserTokenUsing>;
  currTokenUsing?: IUserTokenUsing;
  showTokenDialog: boolean;
  loading: boolean;
  error: boolean;
  errMessage?: string;
};

const initialValues: TokenState = {
  showTokenDialog: false,
  loading: false,
  error: false,
  // aiTokenUsage: undefined,
};

const aiTokenSlice = createSlice({
  name: "aiToken",
  initialState: initialValues,
  reducers: {
    setShowTokenDialog: (state) => {
      state.showTokenDialog = true;
    },
    setCloseTokenDialog: (state) => {
      state.showTokenDialog = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handlerCreateAiTokenByProjectID.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      handlerCreateAiTokenByProjectID.fulfilled,
      (state, action) => {
        state.loading = false;
        state.currTokenUsing = action.payload;
        state.aiTokenId = action.payload.id;
      }
    );
    builder.addCase(
      handlerCreateAiTokenByProjectID.rejected,
      (state, action) => {
        state.loading = false;
        state.error = true;
        state.errMessage = action.error.message;
      }
    );

    builder.addCase(fetchAiTokenUsageDailyByUserID.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchAiTokenUsageDailyByUserID.fulfilled, (state, action) => {
      state.loading = false;
      state.aiTokenUsage = action.payload;
    });
    builder.addCase(fetchAiTokenUsageDailyByUserID.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errMessage = action.error.message;
    });
  },
});

export const { setShowTokenDialog, setCloseTokenDialog } = aiTokenSlice.actions;
export const aiTokenSelector = (store: RootState) => store.aiTokenReducer;
export default aiTokenSlice.reducer;
