import React, { useState } from "react";
import { mainState } from "../util.service";

export const FileContext = React.createContext<any>({});

export interface FileContextProps {
  groupId: any;
  setGroupId: (value: any) => void;
  userId: any;
  setUserId: (value: any) => void;
  expandUpload: boolean;
  setExpandUpload: (value: boolean) => void;
  createFolderName: string;
  setCreateFolderName: (value: string) => void;
  linkUpload: string;
  setLinkUpload: (value: string) => void;
  linkName: string;
  setLinkName: (value: string) => void;
  linkDetail: string;
  setLinkDetail: (value: string) => void;
  linkTab: string;
  setLinkTab: (value: string) => void;
  encryptFolder: boolean;
  setEncryptFolder: (value: boolean) => void;
  controlVersion: boolean;
  setControlVersion: (value: boolean) => void;
  parentFolderId: any;
  setParentFolderId: (value: any) => void;
  showCreateFolderModal: boolean;
  setShowCreateFolderModal: (value: boolean) => void;
  tag: any;
  setTag: (value: any) => void;
  tab: any;
  setTab: (value: any) => void;
  dataErrorCreate: string;
  setDataErrorCreate: (value: string) => void;
  isErrorCreate: boolean;
  setErrorCreate: (value: boolean) => void;
  loadingCreate: boolean;
  setLoadingCreate: (value: boolean) => void;
  files: any[];
  setFiles: (value: any[]) => void;
  folders: any[];
  setFolders: (value: any[]) => void;
  fileFolders: any[];
  setFileFolders: (value: any[]) => void;
  fileSearchInput: string;
  setFileSearchInput: (value: string) => void;
  creatingFolder: boolean;
  setCreatingFolder: (value: boolean) => void;
  isCheckIn: boolean;
  setCheckIn: (value: boolean) => void;
  aboutLimit: any;
  setAboutLimit: (value: any) => void;
  fileToUpload: any[];
  setFileToUpload: (value: any[]) => void;
  checkingFile: boolean;
  setCheckingFile: (value: boolean) => void;
  fileSelected: any;
  setFileSelected: (value: any) => void;
  currentFolder: any;
  setCurrentFolder: (value: any) => void;
  showModalUpload: boolean;
  setShowModalUpload: (value: boolean) => void;
  showModalFolderUpload: boolean;
  setShowModalFolderUpload: (value: boolean) => void;
  uploading: boolean;
  setUploading: (value: boolean) => void;
  fnShowModal: boolean;
  setFnShowModal: (value: boolean) => void;
  fnTitle: string;
  setFnTitle: (value: string) => void;
  fnMessage: string;
  setFnMessage: (value: string) => void;
  fnDone: any;
  setFnDone: (value: any) => void;
  fnCancel: any;
  setFnCancel: (value: any) => void;
  fnLoading: boolean;
  setFnLoading: (value: boolean) => void;
  uploadItemToCancel: any;
  setUploadItemToCancel: (value: any) => void;
  currentShowMenuId: string | null;
  setCurrentShowMenuId: (value: string | null) => void;
  viewDir: string;
  setViewDir: (value: string) => void;
  showRenameModal: boolean;
  setShowRenameModal: (value: boolean) => void;
  showMoveModal: boolean;
  setShowMoveModal: (value: boolean) => void;
  syncAboutLimit: number;
  setSyncAboutLimit: (value: number) => void;
  showShareModal: boolean;
  setShowShareModal: (value: boolean) => void;
  showShareLinkModal: boolean;
  setShowShareLinkModal: (value: boolean) => void;
  currentShowMenuClick: any;
  setCurrentShowMenuClick: (value: any) => void;
  recentFiles: any[];
  setRecentFiles: React.Dispatch<React.SetStateAction<any[]>>
  currentPath: any[];
  setCurrentPath: React.Dispatch<React.SetStateAction<any[]>>;
  displayConfirmDelete: boolean;
  setDisplayConfirmDelete: (value: boolean) => void;
  displayConfirmDeleteRestore: boolean;
  setDisplayConfirmDeleteRestore: (value: boolean) => void;
  displayConfirmDeleteForever: boolean;
  setDisplayConfirmDeleteForever: (value: boolean) => void;
  isSuccessDeleteForever: boolean;
  setIsSuccessDeleteForever: (value: boolean) => void;
  currentSharedWith: any;
  setCurrentSharedWith: (value: any) => void;
  hasMore: boolean;
  setHasMore: (value: boolean) => void;
  showRecentFiles: boolean;
  setShowRecentFiles: (value: boolean) => void;
  loadMore: any;
  setLoadMore: (value: any) => void;
  groupDetail: any;
  setGroupDetail: (value: any) => void;
  showDetail: any;
  setShowDetail: (value: any) => void;
  showShareFileModal: boolean;
  setShowShareFileModal: (value: boolean) => void;
  totalFileVersion: number;
  setTotalFileVersion: (value: number) => void;
  uploadErrorMessage: any;
  setUploadErrorMessage: (value: any) => void;
  uploadErrorDescription: any;
  setUploadErrorDescription: (value: any) => void;
  mainTab: string;
  setMainTab: (value: string) => void;
  filesSelected: any[];
  setFilesSelected: (value: any[]) => void;
  fileAbouts: any[];
  setFileAbouts: (value: any[]) => void;
  isComunity: boolean;
  setIsComunity: (value: boolean) => void;
  clickFromGroupTab: boolean;
  setClickFromGroupTab: (value: boolean) => void;
  visibleManageVersion: boolean;
  setVisibleManageVersion: (value: boolean) => void;
  countFileVersion: any[];
  setCountFileVersion: (value: any[]) => void;
  displayConfirmEmptyTrash: boolean;
  setDisplayConfirmEmptyTrash: (value: boolean) => void;
  moduleFiles: any[];
  setModuleFiles: (value: any[]) => void;
  moduleFileAc: any;
  setModuleFileAc: (value: any) => void;
  isAdvacedSearch: boolean;
  setIsAdvacedSearch: (value: boolean) => void;
  isShowModalUploadLink: boolean;
  setIsShowModalUploadLink: (value: boolean) => void;
  uploadingLink: boolean;
  setUploadingLink: (value: boolean) => void;
  isDeleteLink: boolean;
  setIsDeleteLink: (value: boolean) => void;
}

export const FileProviderContext = (props: any) => {

  const [groupId, setGroupId] = useState<any>(mainState.init);
  const [userId, setUserId] = useState<any>(null);
  const [expandUpload, setExpandUpload] = useState<boolean>(false);
  const [createFolderName, setCreateFolderName] = useState<string>("");
  const [encryptFolder, setEncryptFolder] = useState<boolean>(false);
  const [controlVersion, setControlVersion] = useState<boolean>(false);
  const [parentFolderId, setParentFolderId] = useState<any>(null);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState<boolean>(false);
  const [tag, setTag] = useState<any>(null);
  const [tab, setTab] = useState<any>(null);
  const [dataErrorCreate, setDataErrorCreate] = useState<string>("");
  const [isErrorCreate, setErrorCreate] = useState<boolean>(false);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [folders, setFolders] = useState<any>([]);
  const [fileFolders, setFileFolders] = useState<any[]>([]);
  const [creatingFolder, setCreatingFolder] = useState<boolean>(false);
  const [isCheckIn, setCheckIn] = useState<boolean>(false);
  const [aboutLimit, setAboutLimit] = useState({});
  const [fileToUpload, setFileToUpload] = useState<any>([]);
  const [checkingFile, setCheckingFile] = useState<boolean>(false);
  const [fileSelected, setFileSelected] = useState<any>(null);
  const [currentFolder, setCurrentFolder] = useState<any>({});
  const [showModalUpload, setShowModalUpload] = useState<boolean>(false);
  const [showModalFolderUpload, setShowModalFolderUpload] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [fnShowModal, setFnShowModal] = useState<boolean>(false);
  const [fnTitle, setFnTitle] = useState<string>("");
  const [fnMessage, setFnMessage] = useState<string>("");
  const [fnDone, setFnDone] = useState<any>(null);
  const [fnCancel, setFnCancel] = useState<any>(null);
  const [fnLoading, setFnLoading] = useState<any>(false);
  const [uploadItemToCancel, setUploadItemToCancel] = useState<any>(null);
  const [currentShowMenuId, setCurrentShowMenuId] = useState<string | null>("");
  const [viewDir, setViewDir] = useState<string>("");
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showMoveModal, setShowMoveModal] = useState<boolean>(false);
  const [syncAboutLimit, setSyncAboutLimit] = useState<number>(0);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);
  const [currentShowMenuClick, setCurrentShowMenuClick] = useState<any>(null);
  const [recentFiles, setRecentFiles] = useState<any[]>([]);
  const [currentPath, setCurrentPath] = useState<any[]>([]);
  const [displayConfirmDelete, setDisplayConfirmDelete] = useState<boolean>(false);
  const [displayConfirmDeleteRestore, setDisplayConfirmDeleteRestore] = useState<boolean>(false);
  const [displayConfirmDeleteForever, setDisplayConfirmDeleteForever] = useState<boolean>(false);
  const [isSuccessDeleteForever, setIsSuccessDeleteForever] = useState<boolean>(false);
  const [currentSharedWith, setCurrentSharedWith] = useState<any>(null)
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [showRecentFiles, setShowRecentFiles] = useState<boolean>(true);

  const [loadMore, setLoadMore] = useState<any>(false);
  const [groupDetail, setGroupDetail] = useState<any>(null);
  const [showDetail, setShowDetail] = useState<any>(false);
  const [showShareFileModal, setShowShareFileModal] = useState<boolean>(false);
  const [totalFileVersion, setTotalFileVersion] = useState<number>(0);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<any>(null);
  const [uploadErrorDescription, setUploadErrorDescription] = useState<any>(null);
  const [mainTab, setMainTab] = useState<string>("files");
  const [filesSelected, setFilesSelected] = useState<any>([]);
  const [fileAbouts, setFileAbouts] = useState<any>([]);
  const [isComunity, setIsComunity] = useState<boolean>(false);
  const [clickFromGroupTab, setClickFromGroupTab] = useState<boolean>(false);
  const [fileSearchInput, setFileSearchInput] = useState<string>("");
  const [visibleManageVersion, setVisibleManageVersion] = useState<boolean>(false);
  const [countFileVersion, setCountFileVersion] = useState<any>([])
  const [displayConfirmEmptyTrash, setDisplayConfirmEmptyTrash] = useState<boolean>(false)
  const [moduleFiles, setModuleFiles] = useState<any>([])
  const [moduleFileAc, setModuleFileAc] = useState<any>(null)
  const [isAdvacedSearch, setIsAdvacedSearch] = useState<boolean>(false)
  const [isShowModalUploadLink, setIsShowModalUploadLink] = useState<boolean>(false)
  const [uploadingLink, setUploadingLink] = useState<boolean>(false)
  const [isDeleteLink, setIsDeleteLink] = useState<boolean>(false)
  const [linkName, setLinkName] = useState<string>("");
  const [linkDetail, setLinkDetail] = useState<string>("");
  const [linkUpload, setLinkUpload] = useState<string>("");
  const [linkTab, setLinkTab] = useState<string>("links");
  const state: FileContextProps = {
    showRecentFiles: showRecentFiles,
    setShowRecentFiles: setShowRecentFiles,
    groupId: groupId,
    setGroupId: setGroupId,
    fileSearchInput: fileSearchInput,
    setFileSearchInput: setFileSearchInput,
    clickFromGroupTab: clickFromGroupTab,
    setClickFromGroupTab: setClickFromGroupTab,
    isComunity: isComunity,
    setIsComunity: setIsComunity,
    fileAbouts: fileAbouts,
    setFileAbouts: setFileAbouts,
    userId: userId,
    setUserId: setUserId,
    expandUpload: expandUpload,
    setExpandUpload: setExpandUpload,
    createFolderName: createFolderName,
    setCreateFolderName: setCreateFolderName,
    encryptFolder: encryptFolder,
    setEncryptFolder: setEncryptFolder,
    controlVersion: controlVersion,
    showCreateFolderModal: showCreateFolderModal,
    setShowCreateFolderModal: setShowCreateFolderModal,
    setControlVersion: setControlVersion,
    tag: tag,
    setTag: setTag,
    tab: tab,
    setTab: setTab,
    parentFolderId: parentFolderId,
    setParentFolderId: setParentFolderId,
    dataErrorCreate: dataErrorCreate,
    setDataErrorCreate: setDataErrorCreate,
    isErrorCreate: isErrorCreate,
    setErrorCreate: setErrorCreate,
    files: files,
    setFiles: setFiles,
    folders: folders,
    setFolders: setFolders,
    creatingFolder: creatingFolder,
    setCreatingFolder: setCreatingFolder,
    uploadingLink: uploadingLink,
    setUploadingLink: setUploadingLink,
    isCheckIn: isCheckIn,
    setCheckIn: setCheckIn,
    aboutLimit: aboutLimit,
    setAboutLimit: setAboutLimit,
    fileToUpload: fileToUpload,
    setFileToUpload: setFileToUpload,
    checkingFile: checkingFile,
    setCheckingFile: setCheckingFile,
    fileSelected: fileSelected,
    setFileSelected: setFileSelected,
    currentFolder: currentFolder,
    setCurrentFolder: setCurrentFolder,
    currentPath: currentPath,
    setCurrentPath: setCurrentPath,
    showModalUpload: showModalUpload,
    setShowModalUpload: setShowModalUpload,
    showModalFolderUpload: showModalFolderUpload,
    setShowModalFolderUpload: setShowModalFolderUpload,
    loadingCreate: loadingCreate,
    setLoadingCreate: setLoadingCreate,
    uploading: uploading,
    setUploading: setUploading,
    fnShowModal: fnShowModal,
    setFnShowModal: setFnShowModal,
    fnTitle: fnTitle,
    setFnTitle: setFnTitle,
    fnMessage: fnMessage,
    setFnMessage: setFnMessage,
    fnDone: fnDone,
    setFnDone: setFnDone,
    fnCancel: fnCancel,
    setFnCancel: setFnCancel,
    fnLoading: fnLoading,
    setFnLoading: setFnLoading,
    uploadItemToCancel: uploadItemToCancel,
    setUploadItemToCancel: setUploadItemToCancel,

    currentShowMenuId: currentShowMenuId,
    setCurrentShowMenuId: setCurrentShowMenuId,
    fileFolders: fileFolders,
    setFileFolders: setFileFolders,
    viewDir: viewDir,
    setViewDir: setViewDir,
    showRenameModal: showRenameModal,
    setShowRenameModal: setShowRenameModal,
    showMoveModal: showMoveModal,
    setShowMoveModal: setShowMoveModal,
    syncAboutLimit: syncAboutLimit,
    setSyncAboutLimit: setSyncAboutLimit,
    showShareModal: showShareModal,
    showShareLinkModal: showShareLinkModal,
    setShowShareModal: setShowShareModal,
    setShowShareLinkModal: setShowShareLinkModal,
    currentShowMenuClick: currentShowMenuClick,
    setCurrentShowMenuClick: setCurrentShowMenuClick,
    recentFiles: recentFiles,
    setRecentFiles: setRecentFiles,
    displayConfirmDelete: displayConfirmDelete,
    setDisplayConfirmDelete: setDisplayConfirmDelete,

    displayConfirmDeleteRestore: displayConfirmDeleteRestore,
    setDisplayConfirmDeleteRestore: setDisplayConfirmDeleteRestore,
    displayConfirmDeleteForever: displayConfirmDeleteForever,
    setDisplayConfirmDeleteForever: setDisplayConfirmDeleteForever,
    isSuccessDeleteForever: isSuccessDeleteForever,
    setIsSuccessDeleteForever: setIsSuccessDeleteForever,
    currentSharedWith: currentSharedWith,
    setCurrentSharedWith: setCurrentSharedWith,

    hasMore: hasMore,
    setHasMore: setHasMore,
    loadMore: loadMore,
    setLoadMore: setLoadMore,
    groupDetail: groupDetail,
    setGroupDetail: setGroupDetail,
    showDetail: showDetail,
    setShowDetail: setShowDetail,
    showShareFileModal: showShareFileModal,
    setShowShareFileModal: setShowShareFileModal,
    setTotalFileVersion: setTotalFileVersion,
    totalFileVersion: totalFileVersion,

    uploadErrorMessage: uploadErrorMessage,
    setUploadErrorMessage: setUploadErrorMessage,
    uploadErrorDescription: uploadErrorDescription,
    setUploadErrorDescription: setUploadErrorDescription,
    mainTab: mainTab,
    setMainTab: setMainTab,
    filesSelected: filesSelected,
    setFilesSelected: setFilesSelected,
    visibleManageVersion: visibleManageVersion,
    setVisibleManageVersion: setVisibleManageVersion,
    countFileVersion: countFileVersion,
    setCountFileVersion: setCountFileVersion,
    displayConfirmEmptyTrash: displayConfirmEmptyTrash,
    setDisplayConfirmEmptyTrash: setDisplayConfirmEmptyTrash,
    moduleFiles: moduleFiles,
    setModuleFiles: setModuleFiles,
    moduleFileAc: moduleFileAc,
    setModuleFileAc: setModuleFileAc,

    isAdvacedSearch: isAdvacedSearch,
    setIsAdvacedSearch: setIsAdvacedSearch,
    isShowModalUploadLink: isShowModalUploadLink,
    setIsShowModalUploadLink: setIsShowModalUploadLink,
    linkName: linkName,
    setLinkName: setLinkName,
    linkUpload: linkUpload,
    setLinkUpload: setLinkUpload,
    linkDetail: linkDetail,
    setLinkDetail: setLinkDetail,
    linkTab: linkTab,
    setLinkTab: setLinkTab,
    isDeleteLink: isDeleteLink,
    setIsDeleteLink: setIsDeleteLink,
  };
  return (
    <FileContext.Provider value={state}>
      {props.children}
    </FileContext.Provider>
  );
}
//@ts-ignore
FileProviderContext.Consumer = FileContext.Consumer;
