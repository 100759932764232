import { Button, Card, Modal, Space, Typography } from "antd";
import { motion } from "framer-motion";
import { AlertCircleIcon, BarChartIcon, HistoryIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LogoPromptX from "src/assets/png/PromptX_blue.png";
import { pathRoutes } from "src/page/props/util.props";
import {
  plansSelector,
} from "src/store/slices/payments/plansSlice";
import styles from "./Overview.module.scss";

import { Layout } from "antd";
import { mapPlanItem } from "src/page/subscription/SubscriptionPage";
import { processCancleSubscription } from "src/store/actions/payment/paymentActions";
import {
  billingSelector,
  setActiveTab,
} from "src/store/slices/payments/billingSlice";
import { useAppDispatch } from "src/store/store";
import { fetchUserPlans } from "src/store/actions/payment/planAction";
const { Content } = Layout;

const { Text } = Typography;

function Overview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currPlan, userSelectPlan } = useSelector(plansSelector);
  const { activeTab } = useSelector(billingSelector);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const listMenu = [
    // {
    //   key: "payment",
    //   icon: <CreditCardIcon className="w-8 h-8 text-blue-500" />,
    //   title: "Payment Method",
    //   description: "Manage your payment methods",
    //   color: "from-blue-50 to-blue-100",
    // },
    {
      key: "usage",
      icon: <BarChartIcon className="w-8 h-8 text-orange-500" />,
      title: "Usage Limits",
      description: "Monitor usage thresholds",
      color: "from-orange-50 to-orange-100",
    },
    {
      key: "history",
      icon: <HistoryIcon className="w-8 h-8 text-purple-500" />,
      title: "Billing History",
      description: "View past transactions",
      color: "from-purple-50 to-purple-100",
    },
    // {
    //   key: "pricing",
    //   icon: <DollarSignIcon className="w-8 h-8 text-indigo-500" />,
    //   title: "Pricing",
    //   description: "View pricing plans",
    //   color: "from-indigo-50 to-indigo-100"
    // }
  ];

  // Add this handler function
  const handleCancelPlan = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmCancel = async () => {
    const subID = userSelectPlan?.subscription.subscription_id;

    if (subID) {
      await dispatch(processCancleSubscription(subID));
      // Fetch updated plan data to trigger rerender
      dispatch(fetchUserPlans());
    }
    setIsConfirmModalOpen(false);
  };

  useEffect(() => {
    dispatch(fetchUserPlans());
  }, [dispatch]);

  return (
    <Layout style={{ height: "100%", overflow: "hidden" }}>
      <Modal
        title="Cancel Plan Confirmation"
        open={isConfirmModalOpen}
        onOk={handleConfirmCancel}
        onCancel={() => setIsConfirmModalOpen(false)}
        okText="Yes, Cancel Plan"
        cancelText="No, Keep Plan"
        okButtonProps={{ danger: true }}
        centered
      >
        <p>
          Are you sure you want to cancel your current plan? This action cannot
          be undone.
        </p>
      </Modal>
      <Content style={{ overflow: "auto", height: "100%" }}>
        <motion.div
          className="flex-1 px-6 py-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            // overflowY: "auto",
            // scrollBehavior: "smooth",
            // height: "calc(100vh - 160px)",
            backgroundColor: "#ffffff",
            paddingBottom: "100px",
          }}
        >
          <Card className={`mb-6`}>
            <Space
              direction="vertical"
              size="large"
              className={styles.contentWrapper}
            >
              <div>
                <Space align="center" className={styles.balanceRow}>
                  <Text className={styles.balanceLabel}>Current Plan </Text>
                  <AlertCircleIcon size={16} className={styles.infoIcon} />
                </Space>
                <Text className={styles.balanceAmount}>
                  {currPlan?.description}
                </Text>
              </div>
              <Space className={styles.buttonGroup}>
                <Button
                  type="primary"
                  className={`${styles.primaryButton} bg-blue-500 hover:bg-blue-600 text-white font-medium px-6 py-2 rounded-lg transition-all duration-200 flex items-center gap-2`}
                  // onClick={() => navigate(`${pathRoutes.Generate}/${pathRoutes.Subscription}`)}
                  onClick={() => dispatch(setActiveTab("subscription"))}
                >
                  Upgrade Plan
                </Button>
                {currPlan?.key !== "FREE" && (
                  <Button
                    onClick={handleCancelPlan}
                    className={`${styles.secondaryButton} border-2 border-gray-300 hover:border-gray-400 text-gray-700 font-medium px-6 py-2 rounded-lg transition-all duration-200 flex items-center gap-2`}
                  >
                    Cancel plan
                  </Button>
                )}
              </Space>

              {currPlan?.metadata && (
                <div className="mt-8">
                  <div className="flex items-center gap-3 mb-8">
                    <div className="flex items-center justify-center w-8 h-8 rounded-xl bg-purple-500">
                      <img
                        src={LogoPromptX}
                        alt={LogoPromptX}
                        className={styles.logoPromptX}
                      />
                    </div>
                    <h2 className="text-2xl font-bold">
                      Features & Capabilities
                    </h2>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {Object.entries(currPlan.metadata).map(([key, value]) => (
                      <div
                        key={key}
                        className="relative overflow-hidden bg-gradient-to-br from-white to-gray-50 rounded-xl p-6 border border-gray-100 hover:border-blue-200 transition-all duration-300 group"
                      >
                        <div className="flex flex-col space-y-3">
                          <span className="text-sm font-medium text-gray-500 group-hover:text-blue-600 transition-colors">
                            {mapPlanItem.get(key)}
                          </span>
                          <span className="text-2xl font-bold text-gray-900">
                            {value}
                          </span>
                        </div>
                        <div className="absolute top-0 right-0 w-16 h-16 bg-gradient-to-br from-blue-500/10 to-indigo-500/10 rounded-bl-3xl" />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Space>
          </Card>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {listMenu.map((item, index) => (
              <Card
                key={index}
                className="hover:shadow-lg transition-all duration-300 cursor-pointer group"
                onClick={() => {
                  if (item.key === "usage") {
                    navigate(`${pathRoutes.Generate}/${pathRoutes.Usage}`);
                  } else {
                    dispatch(setActiveTab(item.key));
                  }
                }}
              >
                <div
                  className={`p-6 rounded-xl bg-gradient-to-br ${item.color}`}
                >
                  <div className="flex items-start gap-4">
                    <div className="p-3 bg-white rounded-lg shadow-sm group-hover:scale-110 transition-transform">
                      {item.icon}
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold mb-2">
                        {item.title}
                      </h3>
                      <p className="text-gray-600">{item.description}</p>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </motion.div>
      </Content>
    </Layout>
  );
}

export default Overview;
