import { Circle } from "@mui/icons-material";
import AppsIcon from "@mui/icons-material/Apps";
import TokenIcon from "@mui/icons-material/Token";
import { IconButton, Stack } from "@mui/material";
import { Skeleton, Switch } from "antd";
import "chart.js/auto";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import AccountCircleIcon from "src/assets/svg/account-circle";
import PlusCircleIcon from "src/assets/svg/plus-circle";
import { PagingWithSearch } from "src/model/query/paging_with_search";
import { fetchActiveAgentsByUserID } from "src/store/actions/agent/agetAction";
import {
  fetchAiTokenUsageDailyByUserID,
} from "src/store/actions/ai-token/aiTokenActions";
import { fetchNumberOfAppsByUserID } from "src/store/actions/apps/appAction";
import { agentSelector } from "src/store/slices/agents/agentSlice";
import { aiTokenSelector } from "src/store/slices/ai-token/aiTokenSlice";
import { appSelector } from "src/store/slices/apps/appSlice";
import {
  fetchUserPlans,
} from "src/store/actions/payment/planAction";
import { useAppDispatch } from "src/store/store";
import { pathRoutes } from "../props/util.props";
import "./Dashboard.css";
import DonutChart from "./components/DonutChart";
import { plansSelector } from "src/store/slices/payments/plansSlice";

const STATUS_COLORS = {
  active: "#0CC92C",
  waiting: "#FFAB2B",
  idle: "#6b7280",
};

const agents = [
  {
    name: "Sales Agent",
    type: "AgentX",
    status: "active",
    lastInteraction: "2 mins ago",
    isActive: true,
  },
  {
    name: "Data Processing",
    type: "AutomationX",
    status: "waiting",
    lastInteraction: "15 mins ago",
    isActive: false,
  },
  {
    name: "Customer Support",
    type: "AppX",
    status: "idle",
    lastInteraction: "1 hour ago",
    isActive: false,
  },
];

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading: aiTokenLoading, aiTokenUsage } =
    useSelector(aiTokenSelector);
  const { loading: agentLoading, activeAgents } = useSelector(agentSelector);
  const { loading: appLoading, numOfApp } = useSelector(appSelector);
  const { loading: planLoading, currPlan } = useSelector(plansSelector);
  const [isActive, setIsActive] = useState(
    agents.map((agent) => agent.isActive)
  );
  const [lastDays, setLastDays] = useState(7);

  const ProductCardList = [
    {
      title: "AppX",
      subTitle: "Build AI-powered applications",
      nameBtn: "Create App",
      navigateTo: () => navigate("/generate"),
    },
    {
      title: "AutomationX",
      subTitle: "Automate your workflows",
      nameBtn: "Create Automation",
      navigateTo: () =>
        navigate(`/${pathRoutes.Generate}/${pathRoutes.Workflow}`),
    },
    {
      title: "AgentX",
      subTitle: "Deploy AI agents",
      nameBtn: "Create Agent",
      navigateTo: () =>
        navigate(`${pathRoutes.Generate}/${pathRoutes.AgentsDashboard}`),
    },
  ];

  const donutChartData = {
    labels: ["Storage Used", "Storage Remaining"],
    datasets: [
      {
        label: "Storage Usage",
        data: [7, 5],
        backgroundColor: ["#E9E9E9", "#1C87F2"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    dispatch(fetchUserPlans());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchActiveAgentsByUserID());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNumberOfAppsByUserID());
  }, [dispatch]);

  useEffect(() => {
    let query: PagingWithSearch = {
      offset: 0,
      limit: lastDays,
      search: "",
    };
    dispatch(fetchAiTokenUsageDailyByUserID(query));
  }, [dispatch]);

  const handleActive = (index: number) => {
    setIsActive((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const onCreateApp = () => {
    navigate("/generate");
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: number | string) => value.toLocaleString(),
        },
        grid: { display: false },
      },
      x: {
        grid: { display: false },
      },
    },
    plugins: {
      legend: { display: false },
    },
  };

  const barChartData = {
    labels: Array.from({ length: lastDays }, (_, i) =>
      dayjs(new Date())
        .subtract(lastDays - 1 - i, "day")
        .format("DD MMM")
    ),
    datasets: [
      {
        label: "Token Usage",
        data: Array.from({ length: lastDays }, (_, i) => {
          const currentDate = dayjs(new Date())
            .subtract(lastDays - 1 - i, "day")
            .format("YYYY-MM-DD");
          const dailyData = aiTokenUsage?.token_dailys?.data?.find(
            (item) =>
              dayjs(item.created_at).format("YYYY-MM-DD") === currentDate
          );
          return dailyData?.total_tokens_usage || 0;
        }),
        backgroundColor: "#1C87F2",
        borderRadius: 8,
      },
    ],
  };

  const renderSkeleton = (
    <div className="space-y-8 p-6">
      {/* Create New Section Skeleton */}
      <div>
        <h3 className="section-title">Loading Create New...</h3>
        <div className="cards-grid">
          {[1, 2, 3].map((_, i) => (
            <div key={i} className="card">
              <Skeleton.Input active block />
              <Skeleton.Input
                active
                block
                style={{ width: "60%", marginTop: 8 }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Active Agents Section Skeleton */}
      <div>
        <h3 className="section-title">Loading Active Agents...</h3>
        <div className="cards-grid">
          {[1, 2, 3].map((_, i) => (
            <div key={i} className="agent-card">
              <Skeleton.Input active block />
              <div className="mt-4">
                <Skeleton.Input active block style={{ width: "40%" }} />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Total Balance Section Skeleton */}
      <div>
        <h3 className="section-title">Loading Total Balance...</h3>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 lg:col-span-7 card p-6">
            <Skeleton.Input active block />
            <div className="h-[300px] mt-4">
              <Skeleton.Input active block style={{ height: "100%" }} />
            </div>
          </div>
          <div className="col-span-12 lg:col-span-5 grid gap-6">
            <div className="card p-6">
              <Skeleton.Input active block />
              <div className="mt-4 h-[200px]">
                <Skeleton.Input active block style={{ height: "100%" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (aiTokenLoading || agentLoading || appLoading || planLoading) {
    return renderSkeleton;
  }

  return (
    <div className="dashboard-container scrollbar">
      <div className="bg-white border-b border-gray-200">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <h1 className="text-xl font-semibold text-gray-900">
              Welcome to Unified AI Platform
            </h1>
            <div className="flex items-center space-x-3">
              <span className="text-sm font-medium text-gray-600 hidden sm:block">
                New Project
              </span>
              <IconButton
                onClick={onCreateApp}
                className="transition-transform hover:scale-105"
              >
                <PlusCircleIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-content space-y-8 scrollbar">
        <div>
          <h3 className="section-title">Create New</h3>
          <div className="cards-grid">
            {ProductCardList.map((item, key) => (
              <div key={key} className="card">
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  height="100%"
                >
                  <Stack spacing={2}>
                    <span className="agent-name">{item.title}</span>
                    <span className="card-subtitle">{item.subTitle}</span>
                  </Stack>
                  <Stack height="100%" alignItems="flex-start">
                    <IconButton onClick={item.navigateTo}>
                      <PlusCircleIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="section-title">Active Agents</h3>
          <div className="cards-grid">
            {agents.map((agent, key) => (
              <div key={key} className="agent-card space-y-6">
                <div className="agent-status">
                  <Circle
                    sx={{
                      color:
                        STATUS_COLORS[
                          agent.status as keyof typeof STATUS_COLORS
                        ],
                    }}
                  />
                  <Stack flexDirection="row" alignItems="center">
                    <span className="pr-4">
                      {isActive[key] ? "Active" : "Inactive"}
                    </span>
                    <Switch
                      checked={isActive[key]}
                      onChange={() => handleActive(key)}
                    />
                  </Stack>
                </div>
                <div className="agent-info">
                  <Stack spacing={2}>
                    <span className="agent-name">{agent.name}</span>
                    <span className="card-subtitle">{agent.type}</span>
                  </Stack>
                  <span className="last-interaction">
                    {agent.lastInteraction}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="section-title">Total balance</h3>
          <div className="grid grid-cols-12 gap-4">
            {/* Token Usage Chart - Spans 7 columns */}
            <div className="col-span-12 lg:col-span-7 card">
              <div className="flex flex-col h-full">
                {/* Header Section */}
                <div className="flex justify-between items-center p-1 border-b border-gray-100">
                  <h3 className="text-base font-semibold text-gray-800">
                    Token Usage
                  </h3>
                  <select
                    className="px-3 py-1 bg-gray-50 border border-gray-200 rounded-lg text-xs focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => {
                      setLastDays(Number(e.target.value));

                      let query: PagingWithSearch = {
                        offset: 0,
                        limit: Number(e.target.value),
                        search: "",
                      };
                      dispatch(fetchAiTokenUsageDailyByUserID(query));
                    }}
                    value={lastDays}
                  >
                    <option value={7}>Last 7 days</option>
                    <option value={30}>Last 30 days</option>
                  </select>
                </div>

                {/* Chart Section */}
                <div className="flex-1 p-4">
                  <div className="h-[200px]">
                    <Bar data={barChartData} options={options} />
                  </div>
                </div>

                {/* Stats Footer */}
                <div className="card p-4">
                  <Stack spacing={1}>
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-600">Token Usage</span>
                      <TokenIcon className="text-blue-500 w-4 h-4" />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex justify-between">
                        <span className="text-lg font-bold">
                          {currPlan?.metadata["token_limit_per_month"] ?? 0}
                        </span>
                        <span className="text-xs text-gray-500">Token In</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-lg font-bold">
                          {(
                            Number(aiTokenUsage?.total_tokens_used) ?? 0
                          ).toLocaleString()}
                        </span>
                        <span className="text-xs text-gray-500">Token Out</span>
                      </div>
                    </div>
                  </Stack>
                </div>
              </div>
            </div>

            {/* Right Side Stats - Spans 5 columns */}
            <div className="col-span-12 lg:col-span-5 grid gap-4">
              {/* Storage Usage Card */}
              <div className="card p-4">
                <div className="flex justify-between items-center mb-3">
                  <span className="text-base font-semibold">Storage Usage</span>
                  <span className="text-xs text-gray-500">5GB Total</span>
                </div>
                <div className="flex items-center justify-center">
                  <DonutChart data={donutChartData} centerText="1.2GB" />
                </div>
              </div>

              {/* Usage Stats Grid */}
              <div className="grid grid-cols-2 gap-4">
                <div className="card p-4">
                  <Stack spacing={1}>
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-600">
                        Active Agents
                      </span>
                      <AccountCircleIcon className="text-blue-500 w-4 h-4" />
                    </div>
                    <span className="text-lg font-bold">
                      {activeAgents?.active_agent_usage ?? 0}
                    </span>
                    <span className="text-xs text-gray-500">
                      of {currPlan?.metadata["ai_agent_workflows"]}
                    </span>
                  </Stack>
                </div>
                <div className="card p-4">
                  <Stack spacing={1}>
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-600">Apps</span>
                      <AppsIcon className="text-blue-500 w-4 h-4" />
                    </div>
                    <span className="text-lg font-bold">
                      {numOfApp?.number_of_apps_usage ?? 0}
                    </span>
                    <span className="text-xs text-gray-500">
                      of {currPlan?.metadata["number_of_apps"]}
                    </span>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
