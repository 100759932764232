import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { FileContext } from "../../File.context";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import styles from "./KNLinkItem.module.scss";
// import SimpleReactLightbox from "simple-react-lightbox";
//@ts-ignore
// import { SRLWrapper } from "simple-react-lightbox";
import ReactPlayer from "react-player";
import { Modal } from "antd";
import UIFileMenuPopover from "../../component/UIFileMenuPopover";
import { getDateFormat, getPathSpace, getUrlFile, isCommunity, KN_FILE_SERVICE_URL_MAIN, getLinkDetail } from "../../../util.service";
import { checkEmpty, isTrue } from "src/services/util.service";
import { AppContext } from "src/page/context/App.context";
import UIFileType from "../../type/UIFileType"
import link_icon_m  from "src/page/kn/filePage/file-icon/link-icon-primary-m.png"

let file_page = "file_page";
const KNLinkItem = (props: any) => {
  // console.log("solr file card:", props.link.file);
  const { showMenuVersion } = props;
  const fileContext: any = useContext(FileContext);
  const appContext: any = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { t, i18n } = useTranslation();
 
  const data = props.link


//   const checkGetUrlFile = async () => {
//     let url: any =
//       KN_FILE_SERVICE_URL_MAIN +
//       "/downloadFile?fileId=" +
//       props.link.file?.file_app_id +
//       "&size=200";
//     if (props.link.isTrash) {
//       url = url + "&onTrashReqIgnore=true"
//     }
//     url = await getUrlFile(
//       url,
//       props.link.file?.file_app_id,
//       200,
//       props.link.file?.file_type,
//       appContext.filePreviewTemp
//     );
//     setUrl(url);
//   };

//   useEffect(() => {
    // checkGetUrlFile();
//     console.log("props ", props)
//   }, []);

  const handleShowMenu = () => {
    if (props.link.file.file_app_id === fileContext.currentShowMenuId) {
      fileContext.setCurrentShowMenuId(null);
    } else {
      fileContext.setCurrentShowMenuClick(file_page);
      fileContext.setCurrentShowMenuId(props.link.file.file_app_id);
    }
  };
//   useEffect(() => {
//     if (
//       props.link.file.file_app_id === fileContext.currentShowMenuId &&
//       fileContext.currentShowMenuClick === file_page
//     ) {
//       setShowMenu(true);
//     } else {
//       setShowMenu(false);
//     }
//   }, [fileContext.currentShowMenuId]);

//   useEffect(() => {
//     setShowMenu(false);
//     fileContext.setCurrentShowMenuId(null);
//   }, []);

  const getDetailFromLink = async (url: string) => {
    let linkDetailT: any = await getLinkDetail(url);
    console.log("linkDetailT : ", linkDetailT)
    return  linkDetailT?.mainImg 
  }

  interface LinkData {
    file_app_id: string;
    file_name: string;
    file_path: string;
    user_id: string;
    link_download: string;
    file_system_id: string;
    create_date: string;
    update_date: string;
    active_: string;
    type_: string;
    file_type: string;
    full_name_type: string;
    detail: string;  
  }
  

  return (
    <>
        {/* {props.link.links && props.link.links.map((link :any, index: number) => ( */}
            <div
                // key={props.link.link_id}
                className={styles.fileCardBox}
                style={{
                    background: isCommunity() ? "transparent" : "",
                    // height: "70px",
                }}
            >
                <Row justify="start" style={{ width: "100%", alignItems: "center" }}>
                    <Col span={15}>
                        <div style={{ display: "flex" }}>
                        <div
                            style={{
                            marginLeft: !fileContext.isAdvacedSearch ? "25px" : "none",
                            margin: fileContext.isAdvacedSearch ? "auto auto auto 25px" : "none",
                            }}
                        >
                            <div>
                            <div
                                className={[styles.verticalCenter, styles.clickcss].join(" ")}
                            >
                                {/* <UIFileType file={props.link.file_type} urlImage={props.link.link_img}></UIFileType> */}
                                {/* <div
                                    style={{
                                    backgroundImage: `${link_icon_m}`,
                                    backgroundSize: "cover",
                                    width: 43,
                                    height: 43,
                                    }}
                                ></div> */}
                                <img src={link_icon_m} style={{ width: "45", height: "45"}} />
                            </div>
                            </div>
                        </div>
                        <div
                            style={{
                            margin: "auto",
                            marginLeft: "8px",
                            width: "calc(100% - 93px)",
                            }}
                        >
                            <div
                            style={{
                                fontSize: "0.9rem",
                                fontWeight: 600,
                                color: "#3E4050",
                            }}
                            >
                            <div
                                className={styles.textOverflowListFileText}
                                style={{ fontSize: "0.9rem" }}
                            >
                                <div className={styles.linkHead}>
                                    {data.file_name}
                                </div>
                                <div className={styles.link}>
                                    {data.link}
                                </div>
                            </div>
                            {/* {fileContext.isAdvacedSearch && (
                                <div
                                    style={{
                                        fontSize: "0.7rem",
                                        fontWeight: "initial",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "95%",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: props.link.link_img,
                                    }}
                                />
                            )} */}
                            </div>
                        </div>
                        </div>
                    </Col>

                    <Col
                        span={3}
                        style={{
                        fontSize: "0.9rem",
                        }}
                    >
                        <div>
                            <div>{data.file_type}</div>
                        </div>
                    </Col>

                    <Col
                        span={6}
                        style={{
                        fontSize: "0.9rem",
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "200%", alignSelf: "center" }}>
                                <div>{getDateFormat(data.update_date, t)}</div>
                            </div>
                            <div style={{ display: "inline-flex", width: "100%" }}>
                                
                                <div
                                    style={{
                                        width: "100%",
                                        justifyContent: "flex-end",
                                        display: "flex",
                                        position: "relative",
                                    }}
                                >
                                <UIFileMenuPopover
                                    showMenu={showMenu}
                                    handleShowMenu={handleShowMenu}
                                    file={data}
                                    setShowMenu={setShowMenu}
                                    isShared={false}
                                ></UIFileMenuPopover>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* ))} */}

      
    </>
  );
};

export default KNLinkItem;
