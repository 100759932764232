import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export interface IForgotVerifyForm {
  email: string | null;
  phoneCountry: string;
  phoneNumber: string | null;
}

const schema: yup.ObjectSchema<IForgotVerifyForm> = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Please enter a valid email address"
    )
    .default(null),
  phoneCountry: yup.string().default("66"),
  phoneNumber: yup.string().nullable().default(null),
});

export const useForgotVerifyForm = () => {
  return useForm<IForgotVerifyForm>({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
};
