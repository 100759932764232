import React from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { plansSelector } from "src/store/slices/payments/plansSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/store/store";
import { pathRoutes } from "src/page/props/util.props";
import styles from "./DialogAiTokenExcessive.module.scss";
import { fetchPlanProducts } from "src/store/actions/payment/planAction";

interface DialogAiTokenExcessiveProps {
  open: boolean;
  onClose: () => void;
}

const DialogAiTokenExcessive: React.FC<DialogAiTokenExcessiveProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currPlan } = useSelector(plansSelector);
  const keyUser = localStorage.getItem("_key_user");
  const isGuest = keyUser === "guest";

  const handleAction = () => {
    if (isGuest) {
      navigate(pathRoutes.Login);
    } else {
      dispatch(fetchPlanProducts());
      navigate(`${pathRoutes.Generate}/${pathRoutes.Subscription}`);
    }
    onClose();
  };

  return (
    <Dialog 
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className={styles.dialog}
      classes={{ paper: styles.paper }}
    >
      <div className={styles.header}>
        <Typography className={styles.title}>
          {isGuest ? "Login Required" : "Free AI Tokens Depleted"}
        </Typography>
      </div>

      <DialogContent className={styles.content}>
        <Typography className={styles.message}>
          {isGuest ? (
            "Please login or register to access subscription plans and continue using AI features."
          ) : (
            <>
              You have used all your free AI Tokens for your current plan
              <span className={styles.planName}>{currPlan?.name}</span>
              Please upgrade your plan to get additional tokens and continue using AI features.
            </>
          )}
        </Typography>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button onClick={onClose} className={styles.cancelBtn}>
          Cancel
        </Button>
        <Button onClick={handleAction} className={styles.actionBtn}>
          {isGuest ? "Go to Login" : "View Plans & Upgrade"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAiTokenExcessive;
