import React, { useContext, useState} from 'react'
import { Modal, Button, Spin } from "antd"
import { FileContext, FileContextProps } from "../File.context";
import { AppContext } from "src/page/context/App.context";
import styles from "./UIModalUploadLink.module.scss";
import ButtonCenterCLose from "../ButtonCenterClose";
import { useTranslation } from "react-i18next";
import  link_icon  from "src/page/kn/filePage/file-icon/link_icon.png"
import {LoadingOutlined} from "@ant-design/icons";
import { uploadLinkRequest, reflectorFileFolders } from "../../util.service";

function UIModalUploadLink(props: any) {

  const fileContext = useContext<FileContextProps>(FileContext);
  const appContext: any = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  const [link, setLink] = useState<any>([])


  const handleUploadLink = async () => {
    console.log("handleUploadLink()")
    try {
      fileContext.setUploadingLink(true);
      let can = true;
      if ("" === fileContext.linkName.trim()) {
        console.log("link is Empty!");
        can = false;
        fileContext.setDataErrorCreate(t("link is Empty!"));
        fileContext.setErrorCreate(true);
      }
      let link = await uploadLinkRequest(
        fileContext.linkName,
        fileContext.linkUpload,
        fileContext.linkDetail,
        fileContext.groupId,
        fileContext.userId,
        fileContext.parentFolderId
      );
      setFolder(link, "FILE");
    //   console.log("fileContext?.fileFolders : ", fileContext?.fileFolders)
    //   fileContext.setFileFolders(link)
      fileContext.setLinkName("")
      fileContext.setLinkDetail("")
      fileContext.setLinkUpload("")
      fileContext.setIsShowModalUploadLink(false);
      fileContext.setUploadingLink(false);
    } catch (e) {
      console.log(e);
      fileContext.setLinkName("")
      fileContext.setLinkDetail("")
      fileContext.setLinkUpload("")
      fileContext.setUploadingLink(false);
    }
  };

  async function setFolder(res: any, type: string) {
    let { fileFolders, newLenght } = reflectorFileFolders(
      fileContext.fileFolders,
      type,
      res
    );
    fileContext.setFileFolders(fileFolders);
  }

  return (
    <>
        <Modal
            centered
            visible={fileContext.isShowModalUploadLink}
            className={styles.modalOveride}
            onCancel={() => {
                fileContext.setIsShowModalUploadLink(false);
                fileContext.setErrorCreate(false);
            }}
            >
            <div

                className={styles.closeBtn}
            // style={{ backgroundImage: `url(${crossIcon})` }}
            > <ButtonCenterCLose onClick={() => {
                fileContext.setIsShowModalUploadLink(false);
                fileContext.setErrorCreate(false);
            }}></ButtonCenterCLose></div>

            <div className={styles.boxCreate}>
                <div style={{ fontSize: "1rem", fontWeight: "500" }}>
                {t("Upload Link")}
                </div>
                <div style={{ boxSizing:"border-box", flexDirection: "column", gap: "20px", fontSize: "0.9rem", display: "flex", margin: "25px 0" }}>
                    <input
                        style={{ fontSize: "0.9rem" }}
                        disabled={fileContext.uploadingLink}
                        type="text"
                        placeholder={t("Link name")}
                        className={styles.boxCreateInput}
                        value={fileContext.linkName}
                        onChange={(event) => {
                            if (event.target.value.length > 255) {
                                let v = event.target.value
                                fileContext.setDataErrorCreate(
                                t("Allow maximum folder name size value of 255 characters")+"!"
                                );
                                fileContext.setErrorCreate(true);
                                fileContext.setLinkName(v);
                            } else {
                                fileContext.setErrorCreate(false);
                                fileContext.setLinkName(event.target.value);
                            }
                        }}
                    />
                     <input
                        style={{ fontSize: "0.9rem" }}
                        disabled={fileContext.uploadingLink}
                        type="text"
                        placeholder={t("Link")}
                        className={styles.boxCreateInput}
                        value={fileContext.linkUpload}
                        onChange={(event) => {
                            if (event.target.value.length > 255) {
                                let v = event.target.value
                                fileContext.setDataErrorCreate(
                                t("Allow maximum folder name size value of 255 characters")+"!"
                                );
                                fileContext.setErrorCreate(true);
                                fileContext.setLinkUpload(v);
                            } else {
                                fileContext.setErrorCreate(false);
                                fileContext.setLinkUpload(event.target.value);
                            }
                        }}
                    />
                    <input
                        style={{ fontSize: "0.9rem" }}
                        disabled={fileContext.uploadingLink}
                        type="text"
                        placeholder={t("detail")}
                        className={styles.boxCreateInput}
                        onChange={(event) => {
                           fileContext.setLinkDetail(event.target.value);
                        }}
                        value={fileContext.linkDetail}
                    />

                    
                </div>
                {fileContext.isErrorCreate ? (
                    <div className={styles.BErrorMessage}>
                        <div className="error-message">{fileContext.dataErrorCreate}</div>
                    </div>
                    ) : null
                }
                
                {/* <button
                className={styles.bottonBtn}
                style={{ fontSize: "1.2rem", marginTop: "25px" }}
                >
                
                </button> */}
                {/* <CenterButton> */}
                <Button type="primary">
                    <div onClick={handleUploadLink}>
                        {fileContext.uploadingLink ? (
                        <Spin indicator={antIcon} />
                        ) : (
                            t("Upload")
                        )}
                    </div>
                </Button>
            </div>
            </Modal>

    </>
  )
}

export default UIModalUploadLink