
const pictype = ["jpeg", "jpg", "gif", "bmp", "png"];
const monthsFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const supportViewer = {
  images: [
    "jpeg",
    "jpg",
    "jpeg",
    "gif",
    "bmp",
    "360-degree",
    "png",
    "JPG",
    "JPEG",
    "GIF",
    "BMP",
    "360-DEGREE",
    "PNG",
    // "svg",
  ],
  // doc: ["pdf", "csv", "xslx", "docx","doc","xls","pptx","PDF", "CSV", "XSLX", "DOCX","DOC","XLS","PPTX"],
  video: [
    "m4v",
    "avi",
    "mpg",
    "mp4",
    "webm",
    "M4V",
    "AVI",
    "MPG",
    "MP4",
    "WEBM",
    "mov",
  ],
  audio: ["mp3", "m4a", "MP3", "M4A"],
  pdf: ["pdf", "PDF"],
  svg: ["svg","SVG"],
  txt: ["txt"],
  xls: ["xls", "xlsx"],
  doc: ["doc", "docx"],
  ppt: ["ppt", "pptx"],
  // pdf: ["pdf"],
  ai: ["ai"],
  xd: ["xd"],
  bin: ["bin"],
  psd: ["psd"],
  zip: ["zip", "rar"],
  link: ["link"]
};
let timeOut = 0;
let RSA_2048 = "RSA_2048";
export {
  pictype,
  supportViewer,
  timeOut,
  RSA_2048,
  monthsFull,
  months,
};

export const tabName = {
  myFile: "myfile",
  sharedWithMe: "sharedWithMe",
  trash: "trash",
  group: "group",
  img: "img",
  other: "other",
  unknow: "unknow",
  doc: "document",
  media: "media",
  mediavideo: "mediavideo",
  mediaimage: "mediaimage",
  file: "files",
  manageversion: "manageversion",
  search: "search",
  activitiesLog: "activitieslog",
  allactivitiesLog: "allactivitieslog",
  links: 'links'
};
export const tabNameText = {
  myFile: "My File",
  sharedWithMe: "Shared with me",
  trash: "Trash Files",
  group: "Group Files",
  mediavideo: "Video Files",
  mediaimage: "Image Files",
  file: "Files",
  unknow: "Unknown Files",
  other: "Other Files",
  doc: "Document Files",
  search: "Search",
  activitiesLog: "Activities Log",
  allactivitiesLog: "All Activities Log",
  links: "Links"
};