import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Col, Progress, Row } from "antd";


import { FileContext, FileContextProps, FileProviderContext } from "./File.context";
import * as Props from "../../props/util.props";
import { tabName, tabNameText } from "./properies.file";
import ImageFile from "src/assets/png/ImageFile.png";
import DocumentFile from "src/assets/png/DocumentFile.png";
import MediaFile from "src/assets/png/MediaFile.png";
import OtherFile from "src/assets/png/OtherFile.png";
import UnknownFile from "src/assets/png/UnknownFile.png";
import SharedWithMeFile from "src/assets/png/SharedWithMeFile.png";
import GroupFile from "src/assets/png/GroupFile.png";
import TrashFile from "src/assets/png/TrashFile.png";
import FolderIcon from "src/assets/png/newFolderIcon40.png";
import FileIcon from "src/assets/png/FileIcon40.png";

import styles from "./UICoFileMenu.module.scss";
import { bytesToSize, bytesToSize_Split, calFreeSize_Split, calPercent, checkEmpty, constKey, mainState, mainTab, pathRoutes, reverseArr } from "../util.service";
import { AppContext } from "src/page/context/App.context";

interface UICoFileMenuProps {
  setActiveKeyMainTab:(v : any) => void
  checkMobile: boolean
}

export default function UICoFileMenu({ setActiveKeyMainTab, checkMobile } : UICoFileMenuProps) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const appContext: any = useContext(AppContext);
  const fileContext = useContext<FileContextProps>(FileContext);

  const getTotalLimitSplit = () => {
    let res = calFreeSize_Split(
      fileContext.aboutLimit?.used,
      fileContext.aboutLimit?.limitSize
    );
    return res;
  };

  const getUsedLimitSplit = () => {
    return bytesToSize_Split(fileContext.aboutLimit?.used);
  };

  const getUsedLimit = () => {
    return fileContext.aboutLimit?.used
      ? bytesToSize(fileContext.aboutLimit?.used)?.replace(" ", "")
      : bytesToSize(0)?.replace(" ", "");
  };

  const getRecursiveParents = () => {
    if (fileContext.groupId === mainState.init) return [];
    if (checkEmpty(fileContext.currentFolder?.recursiveParents)) {
      return [];
    } else {
      let recursiveParents = reverseArr(
        fileContext.currentFolder?.recursiveParents
      );
      return recursiveParents;
    }
  };

  const handleClickTab = (tab: string) => {
    fileContext.setMainTab(mainTab.files);
    setActiveKeyMainTab(mainTab.files);
    let dirIdText = "";

    let id = fileContext.groupId;
    if (checkEmpty(id)) id = "";
    
    if (checkEmpty(id)) id = constKey.myId;
    if (tab === tabName.sharedWithMe) {
      fileContext.setModuleFileAc(null)
      let dirid = fileContext?.currentSharedWith?.file_app_id;
      if (checkEmpty(dirid)) dirid = "";
      dirIdText = "&dirid=" + dirid;
      fileContext.setParentFolderId(dirid);
      if (fileContext.isComunity) {
        navigate({
          pathname:
            pathRoutes.groupCommunity + "/" + id + "/folder/" + dirid,
          search: `?id=${id}&tab=file`,
        });
      } else
        if (fileContext.currentPath.length > 0) {
          fileContext.setCurrentPath([]);
        }
      navigate(pathRoutes.File + "/id/" + id + "/folder/shared-with-me/" + dirid);
    } else {
      if (fileContext.isComunity) {
        navigate({
          pathname:
            pathRoutes.groupCommunity + "/" + id + "/tab/" + tab,
          search: `?id=${id}&tab=file`,
        });
      } else navigate(pathRoutes.File + "/id/" + id + "/tab/" + tab);
    }
  };

  const getClasActive = (tab: any) => {
    if (tab === fileContext.tab) return styles.boxMenuTabActive;
    else return "";
  };

  const getClasActiveShareWithMe = (tab: any) => {
    if (tab === fileContext.tab) return styles.boxMenuTabActive;
    let s: any = reverseArr(fileContext.currentPath)
    if (s.length != 0 && s[0]?.file_name === "Shared with me") {
      return styles.boxMenuTabActive;
    } else return "";
  };

  const getAboutLimitForTab = (tab: any) => {
    let res: any = { fileSize: 0, fileCount: 0 };
    res.fileSize = bytesToSize(0)?.replace(" ", "");
    if (
      checkEmpty(fileContext.aboutLimit) ||
      checkEmpty(fileContext.aboutLimit.tabs)
    )
      return res;
    let tabObj: any = fileContext.aboutLimit?.tabs[tab];
    if (checkEmpty(tabObj)) return res;

    res.fileSize = bytesToSize(tabObj.fileSize)?.replace(" ", "");
    res.fileCount = tabObj.fileCount;
    return res;
  };

  const getFileCountforTab = (tab: any) => {
    let fileCount = getAboutLimitForTab(tab).fileCount;
    let fileCountType: any = t("files");
    if (fileCount == 1) fileCountType = t("file");
    let fileCountText = fileCount + " " + fileCountType;
    return fileCountText;
  };

  const getColorLimitProgress = () => {
    let percent = calPercent(fileContext.aboutLimit);
    if (percent >= 100) {
      return "red";
    } else if (percent >= 90) {
      return "#ffc112";
    } else return "#0f4c82";
  };

  const splitFileSizeString = (fileSize: string) => {
    const match = fileSize.match(/^([\d.]+)([A-Za-z]+)$/);

    if (match) {
      const numberPart = match[1]; 
      const unitPart = match[2];  
      console.log(numberPart, unitPart);

      return { numberPart, unitPart } 
    }
  }

  return (
    <div className={checkMobile ? styles.boxFileMain : styles.boxFileMenu}>
      <div style={{ padding: '0 10px 15px 10px', borderBottom: '1px solid #E7EDF0' }}>
        <div
          style={{
            width: "100%",
            display: "inline-flex",
            alignItems: "center",
            marginBottom: '15px'
          }}
        >
          <div>
            <div
              style={{
                backgroundImage: `url(${FolderIcon})`,
                backgroundSize: "cover",
                width: 43,
                height: 36,
              }}
            ></div>
          </div>
          <div
            style={{ marginLeft: "22px", fontSize: "1rem", fontWeight: "500" }}
          >
            <div>{t("Files")}</div>
          </div>
        </div>
        <Row
          style={{
            width: "100%",
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Col style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                fontSize: "1rem",
                fontWeight: 500,
                // minWidth: "50px",
                color: "#36393A",
              }}
            >
              {getUsedLimitSplit().size}
            </div>
            <div className={styles.countFileHeaderDes}>
              <Row>
                {getUsedLimitSplit().typeOfSize} {t("Used")}
              </Row>
            </div>
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <div style={{ fontSize: "1rem", fontWeight: 500 }}>
              {getTotalLimitSplit().size}
            </div>
            <div className={styles.countFileHeaderDes}>
              <Row>
                {getTotalLimitSplit().typeOfSize} {t("Free")}
              </Row>
            </div>
          </Col>
        </Row>
        {/* <div style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.boxProgressBar}>
            <Progress
              strokeWidth={7}
              strokeColor={getColorLimitProgress()}
              percent={calPercent(fileContext.aboutLimit)}
              status={"normal"}
            />
          </div>
        </div> */}
      </div>
      {/* <div
        className={[styles.boxMenuTab, getClasActive(tabName.mediaimage)].join(
          " "
        )}
        onClick={() => {
          handleClickTab(tabName.mediaimage);
        }}
        title={t("Image")}
      >
        <div
          style={{
            // backgroundImage: `url(${ImageFile})`,
            backgroundImage: `url(${FileIcon})`,
          }}
          className={styles.iconImg}
        ></div>
        <div className={styles.fileDesc}>
          <div>{t("Image")}</div>
          <div>{getFileCountforTab(tabName.mediaimage)}</div>
        </div>
        <div className={styles.fileSize}>
          {/* {getAboutLimitForTab(tabName.mediaimage).fileSize} */}
          {/* {(() => {
            const fileSize = getAboutLimitForTab(tabName.mediaimage).fileSize;
            const result = splitFileSizeString(fileSize);
            
            if (result) {
              const { numberPart, unitPart } = result;
              return (
                <>
                  <span className={styles.fileNumber} >{numberPart}</span>
                  <span className={styles.fileUnit}>{unitPart}</span>
                </>
              );
            }
            return getAboutLimitForTab(tabName.mediaimage).fileSize // กรณีค่าไม่ถูกต้อง
          })()} */}
        {/* </div> */}
      {/* </div> */}
      <div
        className={[styles.boxMenuTab, getClasActive(tabName.doc)].join(" ")}
        onClick={() => {
          handleClickTab(tabName.doc);
        }}
        title={t("Document")}
      >
        <div
          style={{
            // backgroundImage: `url(${DocumentFile})`,
            backgroundImage: `url(${FileIcon})`,
          }}
          className={styles.iconImg}
        ></div>
        <div className={styles.fileDesc}>
          <div>{t("Document")}</div>
          <div>{getFileCountforTab(tabName.doc)}</div>
        </div>
        <div className={styles.fileSize}>
          {/* {getAboutLimitForTab(tabName.doc).fileSize} */}
          {(() => {
            const fileSize = getAboutLimitForTab(tabName.doc).fileSize;
            const result = splitFileSizeString(fileSize);
            
            if (result) {
              const { numberPart, unitPart } = result;
              return (
                <>
                  <span className={styles.fileNumber} >{numberPart}</span>
                  <span className={styles.fileUnit}>{unitPart}</span>
                </>
              );
            }
            return getAboutLimitForTab(tabName.mediaimage).fileSize // กรณีค่าไม่ถูกต้อง
          })()}
        </div>
      </div>

      {/* <div
        className={[styles.boxMenuTab, getClasActive(tabName.mediavideo)].join(
          " "
        )}
        onClick={() => {
          handleClickTab(tabName.mediavideo);
        }}
        title={t("Video")}
      >
        <div
          style={{
            // backgroundImage: `url(${MediaFile})`,
            backgroundImage: `url(${FileIcon})`,
          }}
          className={styles.iconImg}
        ></div>
        <div className={styles.fileDesc}>
          <div>{t("Video")}</div>
          <div>{getFileCountforTab(tabName.mediavideo)}</div>
        </div>
        <div className={styles.fileSize}>
          {/* {getAboutLimitForTab(tabName.mediavideo).fileSize} */}
          {/* {(() => {
            const fileSize = getAboutLimitForTab(tabName.mediavideo).fileSize;
            const result = splitFileSizeString(fileSize);
            
            if (result) {
              const { numberPart, unitPart } = result;
              return (
                <>
                  <span className={styles.fileNumber} >{numberPart}</span>
                  <span className={styles.fileUnit}>{unitPart}</span>
                </>
              );
            }
            return getAboutLimitForTab(tabName.mediaimage).fileSize // กรณีค่าไม่ถูกต้อง
          })()} */}
        {/* </div>
      </div> */}

      <div
        className={[styles.boxMenuTab, getClasActive(tabName.mediavideo)].join(
          " "
        )}
        onClick={() => {
          handleClickTab(tabName.links);
        }}
        title={t("Links")}
      >
        <div
          style={{
            // backgroundImage: `url(${MediaFile})`,
            backgroundImage: `url(${FileIcon})`,
          }}
          className={styles.iconImg}
        ></div>
        <div className={styles.fileDesc}>
          <div>{t("Links")}</div>
          <div>{getFileCountforTab(tabName.links)}</div>
        </div>
        {/* <div className={styles.fileSize}> */}
          {/* {getAboutLimitForTab(tabName.mediavideo).fileSize} */}
          {/* {(() => {
            const fileSize = getAboutLimitForTab(tabName.mediavideo).fileSize;
            const result = splitFileSizeString(fileSize);
            
            if (result) {
              const { numberPart, unitPart } = result;
              return (
                <>
                  <span className={styles.fileNumber} >{numberPart}</span>
                  <span className={styles.fileUnit}>{unitPart}</span>
                </>
              );
            }
            return getAboutLimitForTab(tabName.mediaimage).fileSize // กรณีค่าไม่ถูกต้อง
          })()}
         </div> */}
      </div>

      <div
        className={[styles.boxMenuTab, getClasActive(tabName.other)].join(" ")}
        onClick={() => {
          handleClickTab(tabName.other);
        }}
        title={t("Other")}
      >
        <div
          style={{
            // backgroundImage: `url(${OtherFile})`,
            backgroundImage: `url(${FileIcon})`,
          }}
          className={styles.iconImg}
        ></div>
        <div className={styles.fileDesc}>
          <div>{t("Other")}</div>
          <div>{getFileCountforTab(tabName.other)}</div>
        </div>
        <div className={styles.fileSize}>
          {/* {getAboutLimitForTab(tabName.other).fileSize} */}
          {(() => {
            const fileSize = getAboutLimitForTab(tabName.other).fileSize;
            const result = splitFileSizeString(fileSize);
            
            if (result) {
              const { numberPart, unitPart } = result;
              return (
                <>
                  <span className={styles.fileNumber} >{numberPart}</span>
                  <span className={styles.fileUnit}>{unitPart}</span>
                </>
              );
            }
            return getAboutLimitForTab(tabName.mediaimage).fileSize // กรณีค่าไม่ถูกต้อง
          })()}
        </div>
      </div>
      <div
        className={[styles.boxMenuTab, getClasActive(tabName.unknow)].join(" ")}
        onClick={() => {
          handleClickTab(tabName.unknow);
        }}
        title={t("Unknown")}
      >
        <div
          style={{
            // backgroundImage: `url(${UnknownFile})`,
            backgroundImage: `url(${FileIcon})`,
          }}
          className={styles.iconImg}
        ></div>
        <div className={styles.fileDesc}>
          <div>{t("Unknown")}</div>
          <div>{getFileCountforTab(tabName.unknow)}</div>
        </div>
        <div className={styles.fileSize}>
          {/* {getAboutLimitForTab(tabName.unknow).fileSize} */}
          {(() => {
            const fileSize = getAboutLimitForTab(tabName.unknow).fileSize;
            const result = splitFileSizeString(fileSize);
            
            if (result) {
              const { numberPart, unitPart } = result;
              return (
                <>
                  <span className={styles.fileNumber} >{numberPart}</span>
                  <span className={styles.fileUnit}>{unitPart}</span>
                </>
              );
            }
            return getAboutLimitForTab(tabName.mediaimage).fileSize // กรณีค่าไม่ถูกต้อง
          })()}
        </div>
      </div>

      {/* <div
        className={[
          styles.boxMenuTab,
          getClasActiveShareWithMe(tabName.sharedWithMe),
        ].join(" ")}
        onClick={() => {
          handleClickTab(tabName.sharedWithMe);
        }}
        title={t("Shared With Me")}
      >
        <div
          style={{
            backgroundImage: `url(${SharedWithMeFile})`,
          }}
          className={styles.iconImg}
        ></div>
        <div className={styles.fileDesc}>
          <div>{t("Shared With Me")}</div>
          <div>{getFileCountforTab(tabName.sharedWithMe)}</div>
        </div>
        <div className={styles.fileSize}>
          {getAboutLimitForTab(tabName.sharedWithMe).fileSize}
        </div>
      </div> */}

     

      <div
        className={[styles.boxMenuTab, getClasActive(tabName.trash)].join(" ")}
        onClick={() => {
          handleClickTab(tabName.trash);
        }}
        title={t("Trash")}
      >
        <div
          style={{
            // backgroundImage: `url(${TrashFile})`,
            backgroundImage: `url(${FileIcon})`,
          }}
          className={styles.iconImg}
        ></div>
        <div className={styles.fileDesc}>
          <div>{t("Trash")}</div>
          <div>{getFileCountforTab(tabName.trash)}</div>
        </div>
        <div className={styles.fileSize}>
          {/* {getAboutLimitForTab(tabName.trash).fileSize} */}
          {(() => {
            const fileSize = getAboutLimitForTab(tabName.trash).fileSize;
            const result = splitFileSizeString(fileSize);
            
            if (result) {
              const { numberPart, unitPart } = result;
              return (
                <>
                  <span className={styles.fileNumber} >{numberPart}</span>
                  <span className={styles.fileUnit}>{unitPart}</span>
                </>
              );
            }
            return getAboutLimitForTab(tabName.mediaimage).fileSize // กรณีค่าไม่ถูกต้อง
          })()}
        </div>
      </div>

      
      
    </div>
  );
}