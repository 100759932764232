import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { TicketFormData, Category } from "src/types/supportTicket"
import axios from "axios"
import {
  GET_CATEGORY_TICKET_ENDPOINT,
} from "src/utils/endpoint/pmtx.endpoint";
import {
  getAuthorizationPmtx
} from "src/services/util.service";

interface ModalCreateTicketProps {
  visible: boolean;
  isEdit: boolean;
  data: any;
  onClose: () => void;
  onSubmit: (data: TicketFormData, dataOld: any) => void;
}

const { TextArea } = Input;
const { Option } = Select;

const ModalCreateTicket: React.FC<ModalCreateTicketProps> = ({
  visible,
  isEdit,
  data,
  onClose,
  onSubmit,
}) => {
  const [form] = Form.useForm<TicketFormData>();

  const [categorys, setCategorys] = useState<Category[]>([])

  useEffect(() => {
    callGetCategory()
    console.log("data : ", data)
  },[])

  useEffect(() => {
    if (data && visible) {
      form.setFieldsValue({
        title: data.title || '',
        description: data.description || '',
        priority: data.priority || 'MEDIUM',
        category_id: data.category_id || undefined,
      });
    }
  }, [data, visible]);


  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values, data);
      form.resetFields();
      onClose();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const callGetCategory = async () => {
    try{
      const response = await axios.get(GET_CATEGORY_TICKET_ENDPOINT, {
        headers: {
          Authorization: getAuthorizationPmtx(),
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200 || response.status === 202) {
        setCategorys(response.data)
      }
    }catch(error: any){
      console.log("error : ", error)
    }
  }

  return (
    <Modal
      title="Create New Ticket"
      open={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{
          width: "unset!important",
          minWidth: "unset!important",
          boxShadow:  "unset!important"
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please enter a title' }]}
        >
          <Input placeholder=""/>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter a description' }]}
          >
          <TextArea
            rows={4}
            placeholder=""
          />
        </Form.Item>

        <Form.Item
          name="priority"
          label="Priority"
          rules={[{ required: true, message: 'Please select priority' }]}
        >
          <Select onChange={(e) => console.log(form)} placeholder="Select priority">
            <Option value="LOW">Low</Option>
            <Option value="MEDIUM">Medium</Option>
            <Option value="HIGH">High</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="category_id"
          label="Category"
          rules={[{ required: true, message: 'Please select category' }]}
        >
          <Select placeholder="Select category">
            {categorys && categorys.map((cat) => (
              <Option key={cat.id} value={cat.id}>
                {cat.category}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item className="mb-0">
          <Button type="primary" htmlType="submit" block>
            {isEdit ? "Update Ticket" : "Create Ticket"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateTicket;