import React, { useState, useEffect, useRef, memo } from "react";
import { ConfigProvider, Modal, Button, Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import CommentMessageBox from "../components/CommentMessageBox";
import SyntaxLighter from "../syntaxLighter/SyntaxLighter";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useMediaQuery } from "react-responsive";
import styles from "./CommentHtmlModal.module.scss";
import type { RadioChangeEvent } from "antd";
import { Radio, Tabs } from "antd";
import ChatMessage from "../../generate/chatBubble/ChatBubble";
import { GENERATE_STEP } from "../../generate/constants/genstep.constant";

type CommentHtmlModalProps = {
  isLoading: boolean;
  isOpen: boolean;
  scrName: string;
  html: string;
  message: any;
  isComment: boolean;
  onComment: (newMessage: any) => void;
  onClose: () => void;
};

const CommentHtmlModal: React.FC<CommentHtmlModalProps> = ({
  isLoading,
  isOpen,
  scrName,
  html,
  message,
  isComment,
  onComment,
  onClose,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [isPreviewActive, setIsPreviewActive] = useState<string>("1");
  const [messagesChat, setMessagesChat] = useState<any>([]);
  const [leftPanelCollapsed, setLeftPanelCollapsed] = useState(false);

  useEffect(() => {
    // console.log("message : ", message)
    if (message.length) {
      // console.log("message.shift() : ", message.shift())
      let data = message;
      setMessagesChat(data);
    }
  }, [message]);

  useEffect(() => {
    // console.log("messagesChat : ", messagesChat)
    // messagesChat.shift()
    // console.log(messagesChat)
  }, [messagesChat]);

  const modalStyles = {
    header: {
      borderRadius: 0,
      paddingInlineStart: 5,
    },
    body: {
      borderRadius: 5,
    },
    mask: {
      backdropFilter: "blur(10px)",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    content: {
      boxShadow: "0 0 30px rgba(0, 0, 0, 0.2)",
      width: isMobile ? "95%" : "100%",
      margin: isMobile ? "10px auto" : "auto",
    },
  };

  const setMessageChat = () => {
    let obj = {
      agent: "Frontend Developer",
      text: "",
      profileImage: "/zero/static/media/Rectangle_2.264df805438968d4f1d7.png",
      chatColor: "rgb(240 240 240)",
    };
  };

  useEffect(() => {
    if (iframeRef.current && html) {
      const iframeDoc = iframeRef.current.contentDocument;
      if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(html);
        iframeDoc.close();
      }
    }
  }, [html]);

  const SyntaxHighlighterComponent = memo(
    ({ html }: { html: string }) => {
      return <SyntaxLighter codeString={html} />;
    },
    (prevProps, nextProps) => prevProps.html === nextProps.html
  );

  const handleModeChange = (e: RadioChangeEvent) => {
    setIsPreviewActive(e.target.value);
  };

  return (
    <ConfigProvider modal={{ styles: modalStyles }}>
      <Modal
        title={
          <div className={styles.modalTitle}>
            <span>Comment screen name {scrName}</span>
            <div className={styles.titleButtons}>
              <Button
                icon={leftPanelCollapsed ? <RightOutlined /> : <LeftOutlined />}
                onClick={() => setLeftPanelCollapsed(!leftPanelCollapsed)}
                type="primary"
                size="small"
              />
            </div>
          </div>
        }
        width={isMobile ? "100%" : "95%"}
        open={isOpen}
        onCancel={onClose}
        footer={null}
        centered={true}
        className="rounded-lg overflow-hidden backdrop-blur-sm bg-black/30"
      >
        <div className={styles.modalContainer}>
          <PanelGroup direction={isMobile ? "vertical" : "horizontal"}>
            <Panel
              defaultSize={isMobile ? 50 : 25}
              minSize={leftPanelCollapsed ? 0 : 25}
              maxSize={leftPanelCollapsed ? 0 : 50}
              className={styles.panelChat}
            >
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                    zIndex: 1050,
                    background: "rgb(65 65 65 / 20%)",
                    backdropFilter: "blur(5px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spin size="large" />
                </div>
              )}
              <div className={styles.chatMainContainer}>
                {messagesChat.length > 0 &&
                  isComment &&
                  messagesChat
                    ?.filter((_: any, index: number) => index !== 0) // กรอง index 0 ออก
                    .map((msg: any, index: number) => (
                      <ChatMessage
                        key={index}
                        agent={msg?.agent}
                        message={msg?.text}
                        setMessage={setMessagesChat}
                        profileImage={msg?.profileImage}
                        chatColor={msg?.chatColor}
                        processing={isLoading}
                        step={GENERATE_STEP.HTML}
                      />
                    ))}
                <div className={styles.commentBoxWrapper}>
                  <div className={styles.commentBoxInner}>
                    <CommentMessageBox
                      isPreviewActive={true}
                      isLoading={isLoading}
                      addNewMessage={onComment}
                    />
                  </div>
                </div>
              </div>
            </Panel>

            <PanelResizeHandle>
              {isMobile ? (
                <div className={styles.resizeHandleMobile}>
                  <div className={styles.resizeHandleBar} />
                </div>
              ) : (
                <div className={styles.resizeHandleDesktop}>
                  <div className="h-full w-full hover:bg-gray-300 transition-colors duration-200" />
                </div>
              )}
            </PanelResizeHandle>

            <Panel
              defaultSize={isMobile ? 50 : 75}
              minSize={leftPanelCollapsed ? 100 : 50}
              maxSize={leftPanelCollapsed ? 100 : isMobile ? 50 : 75}
              className={styles.panelPreview}
            >
              <div className={styles.allTab}>
                <div className={styles.tab}>
                  <Radio.Group
                    onChange={handleModeChange}
                    value={isPreviewActive}
                    style={{ marginBottom: 8 }}
                  >
                    <Radio.Button value="1">Preview</Radio.Button>
                    <Radio.Button value="2">Code</Radio.Button>
                  </Radio.Group>
                </div>
                {isPreviewActive === "2" && (
                  <div className={styles.panelContainer}>
                    {/* <div className={styles.codeContainer}>
                      <div className={`${styles.codeScroll} scrollbar`}>
                        <SyntaxLighter codeString={html} />
                      </div>
                    </div> */}

                    <div className={styles.codeContainer}>
                      <div className={`${styles.codeScroll} scrollbar`}>
                        <SyntaxLighter codeString={html} />
                      </div>
                    </div>
                  </div>
                )}

                {isPreviewActive === "1" && (
                  <div className={styles.previewContainer}>
                    <div className={styles.previewFrame}>
                      <iframe
                        ref={iframeRef}
                        srcDoc={html}
                        className={styles.iframe}
                        title="HTML Preview"
                        sandbox="allow-scripts allow-same-origin"
                      />
                    </div>
                  </div>
                )}
              </div>
            </Panel>
          </PanelGroup>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default CommentHtmlModal;
