import React, { useContext, useEffect } from "react";

import styles from "./FileContainer.module.scss";
import { useTranslation } from "react-i18next";
import { tabName } from "../properies.file";
import { FileContext } from "../File.context";
import { Col, Row } from "antd";
import { checkEmpty, isTrue } from "src/services/util.service";

const FileContainer = (props: any) => {
  const { t, i18n } = useTranslation();
  const headerText = `${props.header}`;
  const fileContext: any = useContext(FileContext);
  const tableHeader = props.tableHeader;

  useEffect(() => {
    console.log("fileContext.tab : ", fileContext.tab);
  }, []);

  return (
    <>
      {tableHeader && (
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 8px 0px 6px",
            }}
          >
            <div className={styles.cardGroupHeader}>{headerText}</div>
            <div
              style={{ fontSize: "1rem", fontWeight: 600, color: "#428DFF" }}
            ></div>
          </div>
          {/* <div
            style={{
              width: "100%",
              display: "inline-flex",
              alignItems: "center",
              marginTop: 22,
            }}
          > */}{" "}
          <Row
            justify="start"
            style={{ fontSize: "1rem", padding: "0px 8px 0px 6px" }}
          >
            <Col
              span={
                (fileContext.tab === !tabName.myFile ||
                  !checkEmpty(fileContext.tab) || isTrue(props.visibleManageVersion)) && fileContext.tab !== tabName.links 
                  ? 10
                  : 15
              }
            >
              <div style={{ textAlign: "left" }} className={styles.groupHeadName}>
                {t("Name")}
                {/* <ArrowUpOutlined style={{ marginLeft: 16 }} /> */}
              </div>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: "left" }}  className={styles.groupHeadName}>{t("Type")}</div>
            </Col>
            {fileContext.tab === !tabName.myFile  ||
              isTrue(props.visibleManageVersion) ||
              ((!checkEmpty(fileContext.tab) && fileContext.tab !== tabName.links) && (
                <Col span={5}>
                  {" "}
                  {(fileContext.tab === tabName.mediavideo ||
                    fileContext.tab === tabName.mediaimage ||
                    fileContext.tab === tabName.other ||
                    fileContext.tab === tabName.doc ||
                    fileContext.tab === tabName.trash ||
                    fileContext.tab === tabName.search ||
                    fileContext.tab === tabName.unknow) && (
                      <div className={styles.groupHeadName} style={{ textAlign: "left" }}>
                        {t("File location")}
                      </div>
                    )}
                  {fileContext.tab === tabName.sharedWithMe &&
                    fileContext.tab !== tabName.manageversion && (
                      <div className={styles.groupHeadName} style={{ textAlign: "left" }}>{t("Owner")}</div>
                    )}
                </Col>
              ))}

            {isTrue(props.visibleManageVersion) && (
              <Col span={5}>
                <div  className={styles.groupHeadName} style={{ textAlign: "left" }}>{t("Version")}</div>
              </Col>
            )}

            <Col span={6}>
              <div className={styles.groupHeadName} style={{ textAlign: "left" }}>{t("Last modified")}</div>
            </Col>
          </Row>
          {/* </div> */}
          <div className={styles.cardGroup}>{props.children}</div>
        </div>
      )}
    </>
  );
};

export default FileContainer;
