import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export interface OTPForm {
  otp: string[];
}

const schema: yup.ObjectSchema<OTPForm> = yup.object().shape({
  otp: yup
    .array()
    .of(
      yup
        .string()
        .required("OTP digit is required")
        .matches(/^[0-9]$/, "Must be a single digit")
    )
    .length(6, "OTP must be exactly 6 digits")
    .default(Array(6).fill("")),
});

export const useOTPForm = () => {
  return useForm<OTPForm>({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
};
