import React, { useEffect, useRef, useState } from "react";
import styles from "../original-prompt/OriginalPrompt.module.scss";

import clipboardCopy from "clipboard-copy";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/javascript/javascript";

import ChatMessage from "../../chatBubble/ChatBubble";
import { GENERATE_STEP } from "../../constants/genstep.constant";

type OriginalPromptProps = {
  messages: any;
  setMessages: any;
};

function OriginalPrompt({ messages, setMessages }: OriginalPromptProps) {
  const editorRef = useRef<any>(null);
  const copyText = () => {
    clipboardCopy(messages);
    // alert('คัดลอกข้อความเรียบร้อยแล้ว!');
  };

  useEffect(() => {
    if (messages) {
      console.log("messages>>> ::.", messages);
    }
  }, [messages]);

  return (
    <div className={styles.chatContainer}>
      {/* {messages?.map((msg: any, index: number) => ( */}
      {messages && typeof messages === "string" && (
        <ChatMessage
          key={0}
          agent={`Me`}
          message={messages}
          // profileImage={msg.profileImage}
          chatColor={"rgb(240 240 240)"}
          step={GENERATE_STEP.ORIGINAL_PROMPT}
        />
      )}
      {/* ))} */}
    </div>

    /* <div className={styles.editorContainer}>
            <button className={styles.copyButton} onClick={copyText}>
                <CopyIcon />Copy
            </button>
            <CodeMirror
                className={styles.codeMirror}
                value={code}
                options={{
                    mode: 'javascript',
                    theme: 'Default',
                    lineNumbers: true,
                    lineWrapping: true,
                }}
                onBeforeChange={(editor, data, value) => {
                    setCode(value);
                }}
                editorDidMount={(editor) => {
                    editorRef.current = editor;
                }}
            />
        </div> */
  );
}
export default OriginalPrompt;