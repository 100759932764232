import React, { useEffect, useState } from 'react'
import styles from './SupportTicket.module.scss'
import { Modal, Button, Table, notification, Spin, Space } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import ModalCreateTicket  from "./ModalCreateTicket"
import { TicketFormData } from "src/types/supportTicket"
import axios from "axios";
import {
  CREATE_TICKET_ENDPOINT,
  GET_SUPPORT_TICKET_ENDPOINT
} from "src/utils/endpoint/pmtx.endpoint";
import {
    getAuthorizationPmtx,
    getToken,
    checkEmpty,
    parseJwt
  } from "src/services/util.service";
import dayjs from "dayjs"
import * as Auth from "src/services/auth.service";


function SupportTicket() {

    const [isOpenModal , setIsOpenModal] = useState<boolean>(false)
    const [tickets , setTickets] = useState<any>([])
    const [ticketSelected , setTicketSelected] = useState<any>([])
    const [total , setTotal] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const pageSize = 10;

    const [modal, contextHolder] = Modal.useModal();


    useEffect(() => {
        const offset = (currentPage - 1) * pageSize;
        handleGetTickets(offset, pageSize);
    }, [currentPage]);

    // const columns = [
    //     ...(tickets
    //         ?.map((col: any) => ({
    //             title: col.column_name,
    //             dataIndex: col.column_name,
    //             key: col.column_name,
    //         })) || []),
    //     {
    //         title: 'ACTIONS', // เพิ่มคอลัมน์สำหรับปุ่ม
    //         key: 'actions',
    //         render: (_: any, record: any) => (
    //             <Space size="middle">
    //                 <FiEdit2 onClick={() => handleRowClick(record)}/>
    //                 <AiOutlineDelete onClick={() => handleDelete(record)}/>
    //             </Space>
    //         ),
    //     },
    // ];

    const userFromStorage: any = localStorage.getItem(Auth.AuthKeys.user);
    const userId = `${JSON.parse(userFromStorage).user_id}`
    

    const mockColumn = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'TITLE',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'PRIORITY',
            dataIndex: 'priority',
            key: 'priority',
        },
        {
            title: 'CREATED',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'CATEGORY',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'ACTIONS', // เพิ่มคอลัมน์สำหรับปุ่ม
            key: 'actions',
            render: (_: any, record: any) => (
                <Space size="middle">
                    {record?.status === 'OPEN' && (
                        <Button type='primary' onClick={() => handleClickEdit(record)}>
                            edit
                        </Button>
                    )}
                    {record?.status !== 'CLOSE' && (
                        <Button type='primary' onClick={() => handleClickClose(record)}>
                            close
                        </Button>
                    )}
                </Space>
            ),
        }
    ]

    const { confirm } = Modal;

    const handleClickClose = (record: any) => {
        confirm({
            title: 'Do you want close ticket?',
            icon: <ExclamationCircleFilled />,
            content: 'click ok to close ticket',
            async onOk() {
                console.log('OK');
                let updatedData = {
                    id: record.id,
                    title: record.title,
                    description: record.description,
                    priority: record.priority,
                    status: 'CLOSE',
                    category_id: record.category_id,
                    updated_by: userId
                }
                console.log(record)
                await callUpdateTicket(updatedData)
                await reloadData()
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleClickCreate = () => {
        setIsOpenModal(true)
    }

    const closeModal = () => {
        setIsOpenModal(false)
        setIsEdit(false)
        setTicketSelected([])
    }

    const reloadData = async () => {
        const offset = (currentPage - 1) * pageSize;
        await handleGetTickets(offset, pageSize);
    }

    const handleCreateTicket = async (form: TicketFormData, data: any) => {
        console.log("TicketFormData : ", form)
        form.created_by = userId
        if(!isEdit){
            form.status = 'OPEN'
            await callCreateTicket(form)
            setLoading(false)
            await reloadData()
        }else{
            console.log("selected ticket", data)
            let updatedData = {
                id: data.id,
                title: form.title,
                description: form.description,
                priority: form.priority,
                status: data.status,
                category_id: form.category_id,
                updated_by: userId
            }

            await callUpdateTicket(updatedData)
            setTicketSelected([])
            await reloadData()
        }
    }

    const handleClickEdit = (record: any) => {
        console.log(record)
        setTicketSelected(record)
        setIsEdit(true)
        setIsOpenModal(true)
    }

    // const handleClickClose = (record: any) => {
    //     console.log(record)
    //     console.log(record.id)
    //     onClose()
    // }

    const handleGetTickets = async(offset: number, limit: number) => {
        let res = await callGetTickets(offset, limit)
        let data = res?.data?.data
        const formattedTickets = data?.map((ticket: any) => ({
            ...ticket,
            created_at: dayjs(ticket.created_at).format("MM/DD/YYYY"),
        }));
        console.log("formattedTickets : ", formattedTickets)

        setTickets(formattedTickets)
        setTotal(res?.data?.total)
        // return formattedTickets
    }

    // API handler
    const callCreateTicket = async(data: TicketFormData) => {
        setLoading(true)
        try {
            const response = await axios.post(CREATE_TICKET_ENDPOINT, data, {
                headers: {
                Authorization: getAuthorizationPmtx(),
                "Content-Type": "application/json",
                },
            });
            if (response.status === 200 || response.status === 202) {
                notification.success({
                message: "Ticket created successfully",
                description: "",
                });
                closeModal();
                setLoading(false)
            }
        }catch(error: any){
            setLoading(false)
            closeModal()
            console.log(error);
            if (error.response?.status === 401) {
                notification.error({
                message: "Authorization Error",
                description: "Please check your login status and try again.",
                });
            } else if (error.response?.status === 400) {
                notification.error({
                message: "Bad Request",
                description: "Invalid data. Please check your inputs and try again.",
                });
            } else if (error.response?.status === 500) {
                notification.error({
                message: "Server Error",
                description:
                    "There was an error on the server. Please try again later.",
                });
            } else {
                notification.error({
                message: "Error",
                description: "Failed to create ticket. Please try again.",
                });
            }

        }
    }

    const callUpdateTicket = async(data: TicketFormData) => {
        console.log(" updated data ", data)
        setLoading(true)
        try {
            const response = await axios.put(CREATE_TICKET_ENDPOINT, data, {
                headers: {
                Authorization: getAuthorizationPmtx(),
                "Content-Type": "application/json",
                },
            });
            if (response.status === 200 || response.status === 202) {
                notification.success({
                message: "Ticket updated successfully",
                description: "",
                });
                closeModal();
                setLoading(false)
            }
        }catch(error: any){
            setLoading(false)
            closeModal()
            console.log(error);
            if (error.response?.status === 401) {
                notification.error({
                message: "Authorization Error",
                description: "Please check your login status and try again.",
                });
            } else if (error.response?.status === 400) {
                notification.error({
                message: "Bad Request",
                description: "Invalid data. Please check your inputs and try again.",
                });
            } else if (error.response?.status === 500) {
                notification.error({
                message: "Server Error",
                description:
                    "There was an error on the server. Please try again later.",
                });
            } else {
                notification.error({
                message: "Error",
                description: "Failed to create ticket. Please try again.",
                });
            }

        }
    }

    const callGetTickets = async(offset: number, limit: number) => {
        const link = `${GET_SUPPORT_TICKET_ENDPOINT}?offset=${offset}&limit=${limit}`
        setLoading(true)
        try {
            const response = await axios.get(link, {
                headers: {
                Authorization: getAuthorizationPmtx(),
                "Content-Type": "application/json",
                },
            });
            if (response.status === 200 || response.status === 202) {
                console.log("response : ", response)
                setLoading(false)
                return response
            }
        }catch(error: any){
            setLoading(false)
            closeModal()
            console.log(error);
            if (error.response?.status === 401) {
                notification.error({
                message: "Authorization Error",
                description: "Please check your login status and try again.",
                });
            } else if (error.response?.status === 400) {
                notification.error({
                message: "Bad Request",
                description: "Invalid data. Please check your inputs and try again.",
                });
            } else if (error.response?.status === 500) {
                notification.error({
                message: "Server Error",
                description:
                    "There was an error on the server. Please try again later.",
                });
            } else {
                notification.error({
                message: "Error",
                description: "Failed to create ticket. Please try again.",
                });
            }

        }
    }
    
   
    return (
        <>
            <div style={{width: '100%'}}>
                <div className={styles.mainHeader}>
                    <div className={styles.headerText}>
                        Support Ticket
                    </div>
                    <div className={styles.headerBtn}>
                        <Button type='primary' onClick={handleClickCreate}>
                            <PlusOutlined style={{ color: '#fff' }}/>
                            Create ticket
                        </Button>
                    </div>
                
                </div>

                <div className={styles.tableData}>
                    <Table 
                        columns={mockColumn}
                        dataSource={tickets} 
                        pagination={{
                            current: currentPage,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page) => setCurrentPage(page),
                        }}
                    />
                </div>
            </div>
            
            <ModalCreateTicket 
                visible={isOpenModal} 
                isEdit={isEdit} 
                data={ticketSelected}
                onClose={closeModal} 
                onSubmit={handleCreateTicket}
            />
            
            {loading && (
                <div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                    zIndex: 1050,
                    background: "rgb(65 65 65 / 30%)",
                    // backdropFilter: "blur(5px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                >
                    <Spin size="large" />
                </div>
            )}

        </>
    )
}

export default SupportTicket